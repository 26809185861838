import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as authSelectors from '../../../state/auth/selectors';
import Api from '../../../state/Api';
import * as appActions from '../../../state/app/actions';
import Button from '@material-ui/core/Button';
import { Box, Avatar, Grid, Divider, FormControl } from "@material-ui/core";
import BlockUi from 'react-block-ui';
import * as contactActions from '../../../state/contacts/actions';
import * as contactsSelectors from '../../../state/contacts/actions';
import { getContactClientInfo } from './../../../state/contacts/selectors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),

            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            // alignItems: 'center'
        },
        '& .MuiFormControlLabel-label': {
            width : '170px'
        }
    },
    content: {
        width: '100%'
        // margin: theme.spacing(1),
    },
}));

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Cliente = (props) => {

    const client = useSelector(getContactClientInfo(props.contact.id));

    console.log(client, '<<<')
    console.log(props.contact, '2<<')

    useEffect(() => {

    }, [props]);

    const dispatch = useDispatch();
    const classes = useStyles();

    const currencies = [
        {
            value: '',
            label: 'Seleccione'
        },
        {
            value: '1',
            label: 'DNI'
        },
        {
            value: '2',
            label: 'CI'
        },
        {
            value: '3',
            label: 'LE'
        },
        {
            value: '4',
            label: 'LC'
        },
        {
            value: '5',
            label: 'PAS'
        },
        {
            value: '6',
            label: 'OTR'
        },
        {
            value: '7',
            label: 'CUIT'
        }
    ];
    return (
        <div className={classes.root}>
            <form noValidate autoComplete="off">
                <input type="hidden" value="prayer" />
                {/* <Avatar style={{borderRadius: 50, width: 150, height: 150, color: 'white', padding: '20', margin: '0 auto' }} src={props.contact.profile_pic} ></Avatar> */}
                {/* <br style={{ height: 20}}></br> */}
                <div>
                    <TextField
                        variant="outlined"
                        label="Nombre"
                        width="200px"
                        size="small"
                        value={client.Nombres}
                        readOnly="true"
                    />
                    <TextField
                        variant="outlined"
                        label="Apellido"
                        width="200px"
                        size="small"
                        value={client.Apellido}
                        readOnly="true"
                    />
                </div>
                <Grid container>
                    <Grid item xs={6}>
                        <TextField
                            id="standard-select-currency"
                            select
                            label="Tipo Docu"
                            variant="outlined"
                            size="small"
                            value={client.Tipo_Docu}
                            readOnly="true"
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="filled-error-helper-text"
                            label="Documento"
                            variant="outlined"
                            size="small"
                            readOnly="true"
                            value={client.Nro_Docu}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={client.Fraudulento}
                                    
                                />
                            }
                            label="Fraudulento"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <GreenCheckbox
                                    checked={client.IdentidadVerificada}
                                    
                                />
                            }
                            label="IdentidadVerificada"
                            labelPlacement="start"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <GreenCheckbox
                                    checked={client.DomicilioVerificado}
                                    
                                />
                            }
                            label="Domicilio Verificado"
                            labelPlacement="start"
                        />
                    </Grid>
                </Grid>
            </form>
        </div>
    )
};

export default React.memo(Cliente);