import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as authActions from '../../state/auth/actions';
import * as authSelectors from '../../state/auth/selectors';
import * as appSelector from './../../state/app/selectors';
import * as appActions from '../../state/app/actions';
import * as contactActions from '../../state/contacts/actions';
import * as contactsSelector from '../../state/contacts/selectors';
import Link from '@material-ui/core/Link';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ModalTerminos from "../shared/modalTerminos";

const useStyles = makeStyles({
  root: {
    minWidth: 150,
    // width: '100%'
  },
});

const ProfileMenu = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const [username, setUsername] = useState('')
  
  useEffect(() => {
    if(me){
      // console.log(me);
      setUsername(me.username);
    }
  }, [me]);    


  const allChats = useSelector(contactsSelector.getContacts());
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(5);
  const estadoSonido = useSelector(appSelector.getEstadoSonido());
  const estadoDarkMode = useSelector(appSelector.getEstadoDarkMode());
  const estadoRefreshContactos = useSelector(appSelector.getEstadoContactosRefresh())
  const [ openModalTerminos , setOpenModalTerminos ] = React.useState(false);
  const [language, setLanguage] = React.useState('spanish')

  const handleClick = () => {

  }

  const handleLogout = () => {

    dispatch(authActions.logout())
  };

  const handleSound = (event, index) => {
   if(estadoSonido){
      dispatch(appActions.disableSound())
    }else if(!estadoSonido){
      dispatch(appActions.enableSound())
    }  
  };

  const handleDarkMode = (event, index) => {
    if (estadoDarkMode) {
      dispatch(appActions.disableDarkMode())
    } else if (!estadoDarkMode) {
      dispatch(appActions.enableDarkMode())
    }
  };
  
  const handleContactoRefresh = (event, index) => {
    if(estadoRefreshContactos){
       dispatch(appActions.disableRefreshContacts())
     }else if(!estadoRefreshContactos){
       dispatch(appActions.enableRefreshContacts())
     }  
   };

  
  const handleFinalizarConversaciones = event => {
    console.log('pasa x handleFinalizarConversaciones')
    //setEnviandoDatos(true)
    let update = {
      status : 10,
      agentRating : 0,
      mood : 0,
      willBuy : 0,
      currentStatus: 0,
      transferAgent : null,
      message : ""
    }

    for(let i = 0 ; i < allChats.length ; i ++) {

      if (allChats[i].status != 0 && allChats[i].agentid == username ){
        dispatch(contactActions.updateContact(allChats[i],username,update, () => { 
          //setEnviandoDatos(false);
          if(selectedIndex == allChats[i].id){
            props.removeActiveContact();
          }
         
          dispatch(contactActions.removeContact(allChats[i]))
        },() => {
          //setEnviandoDatos(false);
          dispatch(appActions.addToast('Ha habido un error al actualizar el contacto','error'))
        }))
      }
    }

    handleLogout()

    
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialogLogout = () => {
    setOpenDialog(false);
    handleLogout()
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFinalizeAndClose = () => {
    console.log('mando finalize chats')
  };

  const handleCloseTerminos = () => {
    setOpenModalTerminos(false)
  }

  const handleOpenTerminos = () => {
    setOpenModalTerminos(true)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center" >
      <Grid item xs={12}>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <Button onClick={handleClick} >{username}</Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <ModalTerminos language={language} open={openModalTerminos} onClose={handleCloseTerminos} />
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Cerrar Sesion"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            ¿ Querés finalizar todas las conversaciones que ya fueron respondidas ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
           
            
            <Button variant="contained" onClick={handleCloseDialogLogout}  color="primary" autoFocus>
              No
            </Button>
            <Button onClick={handleFinalizarConversaciones} color="secondary"  >
              Si
            </Button>
          </DialogActions>
        </Dialog>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.root}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                     
                      <MenuItem onClick={event => handleOpenTerminos()}>
                        <Typography style={{ marginTop : '10px' }} align='center'>                         
                              Política de Privacidad  
                        </Typography>
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                        <Switch label="sonido" size="small" color="primary" onClick={(e) => handleDarkMode()} checked={estadoDarkMode} />
                        </ListItemIcon>
                        <Typography variant="inherit">Modo Oscuro</Typography>
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <Switch label="sonido" size="small" color="primary" onClick={(e) => handleSound()} checked={estadoSonido}/>
                        </ListItemIcon>
                        <Typography variant="inherit">Sonido</Typography>
                      </MenuItem>
                      <MenuItem>
                        <ListItemIcon>
                          <Switch label="contactosRefresh" size="small" color="primary" onClick={(e) => handleContactoRefresh()} checked={estadoRefreshContactos}/>
                        </ListItemIcon>
                        <Typography variant="inherit">Refresh Contactos</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={event => handleOpenDialog()}
                      >
                        <ListItemIcon>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        <Typography variant="inherit">Cerrar Sesión</Typography>
                      </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

export default ProfileMenu