import axios from 'axios';

import { API_URL } from '../constants';

class Api {
  constructor() {
    if (!Api.instance) {
      this.baseURL = API_URL;
      this.req = axios.create({
        baseURL: this.baseURL,
        // withCredentials: true,
        headers: { api: 'v1' },
      });

      Api.instance = this;
    }

    return Api.instance;
  }

  setJWT(jwt) {
    this.req.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  }

  setCookie(cookie) {
    this.req.defaults.headers.common.Cookie = cookie;
  }

  me = () => this.req.get('/me').then(res => res.data);
}

export default new Api();
