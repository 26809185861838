import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from "@material-ui/core/styles";

import clsx from 'clsx';

import MenuIcon from "@material-ui/icons/Menu";

import CampaignStatus from './CampaignStatus';
import ProfileMenu from './ProfileMenu';

import * as appSelector from './../../state/app/selectors';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as authSelectors from '../../state/auth/selectors';
import * as usersSelectors from '../../state/users/selectors';
import * as userActions from '../../state/users/actions';
import * as appActions from '../../state/app/actions';
import * as campaignsSelector from '../../state/channels/selectors';

import drawerWidth from './../../factories/DrawerWidth';
import {AppBar, Toolbar, Badge, Button, Typography, IconButton, FormControlLabel, Switch, Tooltip } from "@material-ui/core";

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import Api from '../../state/Api';
import FacebookPagesModal from './FacebookPagesModal/FacebookPagesModal';
import FacebookIcon from '@material-ui/icons/Facebook';
import LogoutDialog from "./LogoutDialog";


const useStyles = makeStyles(theme => (
  {
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      })
    },
    title : {
      display : 'inline',
      color: '#6fbe22',
      lineHeight  : '60px'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    }, 
    iconMenu : {
      // width : '205px',
      // display : 'block',
      // float : 'left'
    },
    toolbar : {
      display : 'flex',
      justifyContent: 'space-between',
      paddingRight: 0,
      paddingLeft: 20
    },
    campaignStatus : {
      display : 'inline'
    },
    sectionDesktop: {
      display: 'none',
      float: 'right',
        // width : '300px',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      padding: 10,
      marginLeft: 10,
      marginRight: 10,
    }
  }
))

const emails = ['username@gmail.com', 'user02@gmail.com'];

const SimpleDialog = (props) => {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Seleccione Motivo de Pausa</DialogTitle>
      <List>
        {props.motivos.map((motivo,index) => (
          
          <ListItem button onClick={() => handleListItemClick(motivo.id)} key={index}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={motivo.stateName} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};


const Header = ( ) => {
  const dispatch = useDispatch();
  const classes = useStyles(useTheme());
  const menu = useSelector(appSelector.getMenuState());
  const darkMode = useSelector(appSelector.getEstadoDarkMode());
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const campaigns = useSelector(campaignsSelector.getChannels());
  const estadoPausa = useSelector(usersSelectors.getEstadoPausa());
  const [username, setUsername] = useState('')
  const isPaused = useSelector(usersSelectors.getEstadoPausa());
  const [enviandoDatos, setEnviandoDatos] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [motivos, setMotivos] = React.useState([]);
  const [openModalPages, setOpenModalPages] = React.useState(false);
  const [FbPagesList, setFbPagesList] = React.useState([]);
  const [isLoggedFB, setIsLoggedFB] = React.useState(false);
  const [openModalLogout, setOpenModalLogout] = React.useState(false);
  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseModalPages = () => {
    setOpenModalPages(false)
  }

  const handleClose = (value) => {
    setOpen(false);
    
    if(value){
      setEnviandoDatos(true)
      dispatch(userActions.handlePause(me.username, {pause: !isPaused , state: value}, ()=> setEnviandoDatos(false) , () => setEnviandoDatos(false) ))
    }
   
  };

  const handlePause = (e) => {
    if(isPaused){
      setEnviandoDatos(true)
      dispatch(userActions.handlePause(me.username, {pause: !isPaused}, ()=> setEnviandoDatos(false) , () => setEnviandoDatos(false) ))
    }else{
      handleClickOpen()
    }
   
  }



  const handleChangeMenuState = (e) => {
    dispatch(appActions.changeMenuState())
  }

  const filterPages = (pages) => {
    var newArrays = [];
    pages.forEach( page => {
      var encontro = false;
      campaigns.forEach( (campaign) => {
        if(campaign.pageid == page.instagram_business_account.id &&  campaign.appid == page.id ){
            encontro = true;
        } 
      })
      if(encontro == false){newArrays.push(page)}
     
    })

    setFbPagesList(newArrays)

    if(newArrays.length > 0){
      setOpenModalPages(true)
    }
  }
  
  const handleFBLogin = () => {
    if(!isLoggedFB){
      window.FB.login(function(response) {
        if (response.authResponse) {
          setIsLoggedFB(true)
          window.FB.api('/me/accounts?fields=instagram_business_account{name,username},access_token,id,name,username', function(response) {
            console.log(response.data)
            filterPages(response.data)
          });
        } else {
         console.log('User cancelled login or did not fully authorize.');
        }
      }, {scope: 'pages_show_list,public_profile,email'});
    }else{
      setOpenModalLogout(true);
    }
    
  }

  const handleCloseLogout = () => {
    window.FB.logout(function(response) {
      setOpenModalLogout(false)
      setIsLoggedFB(false)
    });
  }

  const handleCloseDialog = () => {
    setOpenModalLogout(false)
  }
 
  useEffect(() => {
    if(me){

      window.FB.getLoginStatus(function(response) {
        if (response.status === 'connected') {
          // the user is logged in and has authenticated your
          // app, and response.authResponse supplies
          // the user's ID, a valid access token, a signed
          // request, and the time the access token 
          // and signed request each expire
          setIsLoggedFB(true)
          var uid = response.authResponse.userID;
          var accessToken = response.authResponse.accessToken;
        } else if (response.status === 'not_authorized') {

          setIsLoggedFB(false)
          // este es el q hay q enganchar
          // the user is logged in to Facebook, 
          // but has not authenticated your app
        } else {

          setIsLoggedFB(false)
          // the user isn't logged in to Facebook.
        }
       });

      setUsername(me.username);
      setEnviandoDatos(true)
      //Inicializo pausa
      dispatch(userActions.initPausa(me.username, ()=> setEnviandoDatos(false) , () => setEnviandoDatos(false)) ).then(
        (pauseState) => { 
          setEnviandoDatos(false)
        } 
      ).catch(()=>setEnviandoDatos(false) )

      //Busco motivos para pausa
      const getStates = async () => {          
          try {
            await Api.req.get(`/agents/states`)
            .then((response) => { 
              setMotivos(response.data.states);
            });
          }
          catch(err){
            dispatch(appActions.addToast('Ha habido un error al borrar el mensaje :' + err,'error'))
          }
      }
      getStates()

    }
  }, [me]);  

  return (
      <AppBar
        color="inherit" 
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: menu.isOpen,
        })}
      style={{ backgroundColor: darkMode ? '#18202c' : '#e4e4e4' }}
      >
        <Toolbar className={classes.toolbar}>
          <LogoutDialog open={openModalLogout} onClose={handleCloseDialog} onCloseLogout={handleCloseLogout} > </LogoutDialog>
          <FacebookPagesModal me={me} paginas={FbPagesList} onClose={handleCloseModalPages} open={openModalPages}></FacebookPagesModal>
          <div className={classes.iconMenu}>
            <IconButton 
              aria-label="open drawer"
              onClick={handleChangeMenuState}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: menu.isOpen,
              })}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h5" className={classes.title} >
              TeVeCompras
            </Typography>
          </div>
          <CampaignStatus  classes={classes} />
          
            
            <div>
              <SimpleDialog motivos={motivos}  open={open} onClose={handleClose} />
            </div>
            <div className={classes.sectionDesktop}>

              <FormControlLabel
                control={<Switch disabled={enviandoDatos} size="small" color="secondary" onClick={(e) => handlePause(e)} checked={isPaused} />}
                label="Pausa"
                style={{ marginRignt: 20 }}
              />

              <ProfileMenu />

            </div>    
            <Button style={{ display : 'none' }} onClick={() => { setOpenModalPages(true) }}>Test Modal</Button>
            <Button style={{ display: 'none' }} variant="contained" color="primary" onClick={handleFBLogin}> <FacebookIcon /> &nbsp; { isLoggedFB ? 'FB Logout' : 'Fb login' } </Button>
        </Toolbar>
      </AppBar> 

)

};

export default Header;