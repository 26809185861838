import { Chart } from "react-google-charts";import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TextField,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Input,InputLabel,Select,MenuItem, Fab, Grid,Button, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Badge, CircularProgress, LinearProgress , Tooltip, CardContent, Typography } from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import ChatIcon from "@material-ui/icons/Forum";
import SMSIcon from "@material-ui/icons/Sms";
import AcceptIcon from "@material-ui/icons/Check";
import RejectIcon from '@material-ui/icons/Delete';
import PhoneIcon from '@material-ui/icons/Phone';
import PauseIcon from '@material-ui/icons/Pause'
import MessengerIcon from "../../Header/MessengerIcon";
import {
  LocalizationProvider,
  DatePicker,
  TimePicker
} from '@material-ui/pickers';
import * as _ from 'lodash';
import {
  curveCatmullRom,
  area,
} from 'd3-shape';
import { scalePoint } from 'd3-scale';

import MomentUtils from '@date-io/moment';
import Api from '../../../state/Api';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import * as contactsActions from '../../../state/contacts/actions';
import * as conversationActions from '../../../state/conversation/actions';
import * as contactsSelector from '../../../state/contacts/selectors';
import * as campaignsSelector from '../../../state/channels/selectors';

import * as appActions from '../../../state/app/actions'; 
import * as authSelectors from '../../../state/auth/selectors';
import moment from 'moment';  

import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginRight: -drawerWidth,
    borderBottom : '1px solid rgba(81, 81, 81, 1)'
  },
  iconwhatsapp: {
    backgroundColor: '#25D366',
  },
  iconfacebook: {
    backgroundColor: '#3f51b5',
  },  
  iconsms: {
    backgroundColor: '#2196f3',
  },
  iconchat: {
    backgroundColor: '#f50057',
  },
  iconchatoffline: {
    backgroundColor: '#757575',
  },
  fabProgress: {
    color: '#ffcc00',
    position: 'absolute',
    top: -5,
    left: -5,
    zIndex: 1,
  },
  formControl: {
    margin: theme.spacing(1.5),
    minWidth: '90%',
  },
  cien: {
    margin: theme.spacing(1.5),
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  counter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
  },  
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize : '18px'
    },
  },
});

const EventosAgentes = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const campaigns = useSelector(campaignsSelector.getChannels());
  //const chatsPublish = props.todas ? allChatsFiltered : allChats;
  const [fechaDesde, setFechaDesde] = useState(moment().utcOffset(0, true).format("YYYY-MM-DD"));
  const [fechaHasta, setFechaHasta] = useState(moment().utcOffset(0, true).format("YYYY-MM-DD"));
  const [selectedCampaign, setSelectedCampaign] = useState(2);
  const [selectedResult, setSelectedResult] = useState('');  
  const [resultados,setResultados] = useState([]);
  const [resultadosDashboard,setResultadosDashboard] = useState([]);
  const [resultadosHora,setResultadosHora] = useState([]);
  const [resultadosQueue,setResultadosQueue] = useState([]);
  const [horarioDesde, setHorarioDesde] = useState(new Date());
  const [horarioHasta, setHorarioHasta] = useState(new Date());
  const [timeShift,setMaxTimeShift] = useState(0);
  const [hayError,setHayError] = useState(false);

  const interpretarError = (error) => {
    if(error == null){
      setHayError(false)
    }else if( error == 'maxTime' || error == 'minTime' ) {
      setHayError(true)
    }
  }

  const visualizeDuration = (time) => {
    return moment.duration(time, "seconds").format("mm:ss", { trim: false });
  }

  const calcularHorario = () => {
    let duration = moment.duration(moment(horarioDesde).endOf('day').diff(horarioDesde));
    let hours = duration.asHours();

    if(hours > timeShift){
      return moment(horarioDesde).add(timeShift,'hours').toDate()
    }else{
      return null;
    }
  }

  useEffect(() => {
    Api.req.get(`/supervisor/reports/cfg`)
        .then((response) => { 

          setMaxTimeShift(response.data.config[0].Report_ActivityRange)
    })
  },[]);
  


  const mapearQuerys = (agents) => {
    let initialdate = fechaDesde;
    let start_time = moment(horarioDesde).format("HH:mm:ss") ;
    let enddate = fechaDesde;
    let end_time = moment(horarioHasta).format("HH:mm:ss");

    let datetimeA = moment(initialdate + " " + start_time);
    let datetimeB = moment(enddate + " " + end_time);

    let arrayQuerys = agents.map((agent) => { 
      let url = `/supervisor/stats/agents/${agent.agentid}/activity?startDate=${moment(datetimeA).utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss")}&endDate=${moment(datetimeB).utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss")}`;
      return Api.req.get(url)
    })

    let agentsNames =  agents.map((agent) => { 
      return agent.agentid
    })

    axios.all(arrayQuerys).then((info) => {


      let resultados = info.map( function(userData,index){
        let valorDevuelve = {
          registros : userData.data.data,
          usuario : agentsNames[index]
        } 
        return valorDevuelve;
      })
      setResultados(resultados)
    })
  }


  const buscarPorFecha = () => {  
    let initialdate = fechaDesde;
    let start_time = moment(horarioDesde).format("HH:mm:ss") ;
    let enddate = fechaDesde;
    let end_time = moment(horarioHasta).format("HH:mm:ss");

    let datetimeA = moment(initialdate + " " + start_time);
    let datetimeB = moment(enddate + " " + end_time);

    
    Api.req.get(`/supervisor/agents?startDate=${moment(datetimeA).utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss")}&endDate=${moment(datetimeB).utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss")}`)
      .then((response) => { 
        if(response.data.total > 0){
          let agents = response.data.agents;
          mapearQuerys(agents)

        }
    })
  } 

  const renderIcon = (source,color) => {

    switch(source) {
      case 'WhatsappIcon':
        return <WhatsappIcon fontSize="small" style={{color: color }}  />
      case 'FacebookIcon':
        return <MessengerIcon  classes={classes} fontSize="small" style={{color: color }} />
      case 'Pause':
        return <PauseIcon fontSize="small" style={{color: color }} />
      case 'facebook-comments':
        return <FacebookIcon fontSize="small"  style={{color: color }} />
      case 'ChatIcon':
        return <ChatIcon  fontSize="small" style={{color: color }} />
      case 'chat-offline':
        return <ChatIcon fontSize="small" style={{color: color }} />
      case 'SMSIcon':
        return <SMSIcon fontSize="small" style={{color: color }} />
      case 'phone':
        return <PhoneIcon fontSize="small" style={{color: color }} />
      default:
        return <PhoneIcon fontSize="small" style={{color: color }} />
    }
  };

  function Eventos(props) {
    const { startDate } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
       
      </React.Fragment>
    );
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root} style={{ borderBottom : '1px solid rgba(81, 81, 81, 1)' }} >
          <TableCell component="th" scope="row" style={{display: 'flex', flexDirection: 'row', alighContent: 'center', alignItems: 'center'}}>
            <Avatar style={{ marginRight: 10}}>{row.usuario.charAt(0)}</Avatar> <Typography variant="h6">{row.usuario}</Typography>
          </TableCell>
          <TableCell align="center">
            {row.registros.map((evento,index) => (
               <Chip
               key={index}
               icon={renderIcon(evento.muiIcon,  '#' + evento.muiBgColor)}
               label={visualizeDuration(evento.elapsed)}
               style={{borderColor: '#'+ evento.muiBgColor , color : '#'+ evento.muiBgColor , margin : '5px' }}
               variant="outlined"
             />
            ))}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  

  return (
    <>
          <Grid container style={{marginRight: 10}}>
                <Grid item xs={3}>
                  <LocalizationProvider className={classes.cien}  dateAdapter={MomentAdapter}>
                  <DatePicker
                        disableToolbar
                        renderInput={(props) => <TextField {...props} helperText="" />}
                        variant="inline"
                        inputFormat="YYYY-MM-DD"
                        margin="normal"
                        size="small"
                        ampm={false}
                        id="date-picker-inline2"
                        className={classes.cien}
                        label="Fecha"
                        fullWidth={true}
                        onError={(reason) => interpretarError(reason) }
                        autoOk={true}
                        value={fechaDesde}
                        onChange={ date => setFechaDesde(moment(date).utcOffset(0, true).format("YYYY-MM-DD")) }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                  </LocalizationProvider>
                </Grid>                      
                <Grid item xs={2} >
                  <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                      <TimePicker
                          disableToolbar
                          renderInput={(props) => <TextField {...props}   helperText=""  />}
                          variant="inline"
                          fullWidth={true}
                          size="small"
                          ampm={false}
                          inputFormat="HH:mm"
                          margin="normal"
                          id="date-picker-inline"
                          className={classes.cien}
                          label="Horario Desde"
                          onError={ (error) => { interpretarError(error) } }
                          autoOk={true}
                          value={horarioDesde}
                          onChange={date => { calcularHorario() ; setHorarioDesde(date) }}
                          KeyboardButtonProps={{
                              'aria-label': 'change date',
                          }}
                      />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={2} >
                  <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                    <TimePicker
                        disableToolbar
                        renderInput={(props) => <TextField {...props}   helperText=""  />}
                        variant="inline"
                        fullWidth={true}
                        size="small"
                        ampm={false}
                        inputFormat="HH:mm"
                        margin="normal"
                        maxTime={calcularHorario()}
                        minTime={horarioDesde}
                        id="date-picker-inline"
                        className={classes.cien}
                        label="Horario Hasta"
                        onError={ (error) => { interpretarError(error) } }
                        autoOk={true}
                        value={horarioHasta}
                        onChange={ date => setHorarioHasta(date) }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </LocalizationProvider>
                </Grid>                                  
                <Grid item xs={3}>
                  <Button onClick={() => { buscarPorFecha(); } } 
                    fullWidth={true}
                    style={{ 'marginTop':'18px' }} 
                    size="small"
                    disabled={hayError || timeShift == 0 }
                    // disabled={!selectedCampaign || !selectedResult}
                    variant="contained" color="primary">
                    Buscar
                  </Button>
                </Grid>
              </Grid>

      <Grid container >
        <Grid item xs={12}>
          <div style={{margin: 10, marginRight: 10, height: '100px', position: 'relative'}}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Agente</TableCell>
                    <TableCell align="center">Eventos</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultados.map((row,index) => (
                    <Row key={index} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default EventosAgentes