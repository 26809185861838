export const getChannels = () => ({ channels: state }) => state.channels;

export const getChannel = campaignId => ({ channels: state }) =>  { 
    
    const index = state.channels.findIndex(campaign => (
        campaignId === campaign.id
    ));

    return index != -1 ? state.channels[index] : {};

}

export const hasMpCampaign = campaignId => ({ channels: state }) => {

    const index = state.channels.findIndex(campaign => (
        campaign.id === 12
    ));

    return index != -1 ? true : false;

}

export const getMpCampaignData = campaignId => ({ channels: state }) => {

    const index = state.channels.findIndex(campaign => (
        campaign.id === 12
    ));

    return index != -1 ? state.channels[index] : false;

}


export const getTextResponseByChannel = campaignId => ({ channels: state }) =>  { 

    const index = state.channels.findIndex(campaign => (
        campaignId === campaign.id
    ));

    return  index != -1 && campaignId && state.channels[index].textResponses ? state.channels[index].textResponses : [];

}


export const getMediaResponseByChannel = campaignId => ({ channels: state }) =>  { 

    const index = state.channels.findIndex(campaign => (
        campaignId === campaign.id
    ));
    
    return index != -1 && campaignId && state.channels[index].mediaResponses ? state.channels[index].mediaResponses : [];

}
