import React from 'react';
import Api from '../../../state/Api';
import * as appActions from '../../../state/app/actions';
import { makeStyles } from '@material-ui/core/styles';
import {useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useEffect } from 'react';
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    tableContainer: {
      height : '100%',
      maxHeight : '300px'
    },
    chip : {
        marginBottom : '10px'
    },
    paperStyle : {
        width : '50%',
        textAlign :'center',
        marginBottom : '10px'
    },
    destacadoNumber : {
       color : '#42a5f5'
    }
  });

const Pedidos = ( props ) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [pedidos, setPedidos] = React.useState([]);

    const columns = [
        { id: 'NroPedido', label: 'NroPedido', minWidth: 100 },
        { id: 'Estado', label: 'Estado', minWidth: 120 },
        { id: 'Producto', label: 'Producto', minWidth: 230 }

     ];
        
    //let contactId = "363E1BB1-08EF-EA11-811D-005056B12F1E"
    let contactId = props.contact.id;
    //props.contact.id
  

    useEffect( ()=>{
        if(!!props.contact.Nro_Docu && !!props.contact.Tipo_Docu){
            Api.req.get(`https://omni.tevecompras.com/prospectos/contact/${contactId}/pedidos`)
            .then((response) => {
                console.log(response.data.data)
                setPedidos(response.data.data)
            })
            .catch(error => {
                dispatch(appActions.addToast('Ha habido un error','error'))
            })
        }else{
            setPedidos([])
        }
    } , [props.contact])

    return (
        <div >
            <TableContainer className={classes.tableContainer}>
                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns.map((column) => (
                            <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            >
                            {column.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { pedidos.map((row,index) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    <TableCell >
                                    <Button 
                                        target="_blank"
                                        component="a"
                                        href={'http://pedidos/' + row.empresa + '/' + row.sucursal + '/pedido/' + row.nroPedido + '/seguimiento' }
                                        variant="contained" color="primary">
                                        { row.nroPedido }
                                    </Button>
                                       
                                    </TableCell>
                                    <TableCell>
                                        { row.estado }
                                    </TableCell>
                                    <TableCell>
                                        { row.producto }
                                        
                                    </TableCell>
                            </TableRow>
                            )
                    }) }
                </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};

export default React.memo(Pedidos);