import React, {useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import * as appSelectors from '../../state/app/selectors';
import * as adminSelectors from '../../state/admin/selectors';
import * as adminActions from '../../state/admin/actions';
import * as authSelectors from '../../state/auth/selectors';
import CampaignList from './CampaignList/CampaignList';
import { Grid } from "@material-ui/core";
import Campaign from './Campaign/Campaign';


const AdminDashboard = ( props ) => {
  const dispatch = useDispatch();
  const classes = props.classes;
  const toasts = useSelector(appSelectors.getToasts());
  const me = useSelector(authSelectors.getMe());
  const campaigns = useSelector(adminSelectors.getCampaigns());
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [users, setUsers] = useState([]);


  const handleItemClick = (campaign) => {
    setSelectedCampaign(campaign);
  }
  useEffect(() => {
    dispatch(adminActions.fetchCampaigns(me.username))
    dispatch(adminActions.fetchUsuarios())
  }, []);

  return (
    <>
        <Grid container>
            <Grid style={{ overflow: 'auto', height: 'calc(100vh - 65px)' }} item xs={4}>
                <CampaignList  selectedCampaign={selectedCampaign} onItemClick ={handleItemClick} campaigns={campaigns} /> 
            </Grid>
            <Grid style={{ overflow: 'auto', height: 'calc(100vh - 65px)' }} item xs={8}>
              {selectedCampaign ? <Campaign  users={users} selectedCampaign={selectedCampaign} /> : '' }
                
            </Grid>
        </Grid>
    </>
  )
}

export default AdminDashboard