import React, { useState, useEffect } from 'react';
import Api from '../../../state/Api';
import { TextField , Dialog, DialogActions, DialogTitle, DialogContent, Button, Box, FormControl, Input, InputLabel } from "@material-ui/core";
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import emitter from '../../../factories/EventEmitter';
import {DropzoneArea} from 'material-ui-dropzone'
import * as appActions from '../../../state/app/actions';
import BlockUi from 'react-block-ui';

const AutoResponsesImageNew = ( props ) => {

  const classes = props.classes;

  const dispatch=useDispatch();
  
  const [titulo, setTitulo] = useState(null);
  const [descripcion, setDescripcion] = useState(null);
  const [tags, setTags] = useState(null);
  const [shortcut, setShortcut] = useState(null);
  const [file, setFile] = useState([]);
  const disabled = !(!!titulo && !!descripcion && !!tags &&file[0]);
  const [enviandoDatos, setEnviandoDatos] = useState('');
  const handleModalClose = () => {
    props.onClose()
  };  

  const handleEmitirRespuesta = (message) => {
    props.onEmitioRespuesta(message)
  }

  const handleImageChange = (value) => {
      console.log(value)
  };

  const handleCreateNew = () => {
    const params = {
      title : titulo,
      message : descripcion,
      tags : tags,
      shortcut : shortcut,
      file : file[0]
    }

    var formData = new FormData();

    Object.keys(params).forEach(key => {
        formData.append(key, params[key]);
    })

    //axios.post('/some-end-point',  formData);
    setEnviandoDatos(true)
    Api.req.post(`/campaigns/${props.campaignId}/templates/media`,formData).then((response)=>{
      let paramsConId = Object.assign({  name : params.title, id : response.data.data[0].id , campaignid : props.campaignId , messagereference : response.data.data[0].messagereference },params)
      props.onNewImage(paramsConId)
      setEnviandoDatos(false)
    }, (error) =>  { 
      dispatch(appActions.addToast('Ha habido un error al subir la imagen : ' + error.response.data.msg ,'error'))
      setEnviandoDatos(false)
    })
  };

  
  const [fileObjects, setFileObjects] = useState([]);

  return (
      <Dialog
      fullWidth={true}
      maxWidth = {'xs'}
      open={props.isOpen} 
      onClose={handleModalClose} aria-labelledby="form-dialog-title">
      <BlockUi tag="div" blocking={enviandoDatos}>
        <DialogTitle id="customized-dialog-title" >
          Crear Nueva Imagen 
        </DialogTitle>
        <DialogContent dividers>
            <Box>
              <FormControl>
                  <InputLabel htmlFor="titulo">Titulo</InputLabel>
                  <Input
                    onChange={(event)=> setTitulo(event.target.value)}
                    id="titulo"
                    fullWidth={true}
                  />
              </FormControl>
            </Box>
            <Box style={{ 'marginTop' : '10px'}}>
                <TextField
                  id="mensaje"
                  label="Descripción"
                  multiline
                  fullWidth
                  rows={4}
                  variant="outlined"
                  onChange={(event) => setDescripcion(event.target.value)}    
                />
            </Box>
            <Box>
              <FormControl>
                    <InputLabel htmlFor="tags">Tags</InputLabel>
                    <Input
                      onChange={(event)=> setTags(event.target.value)}
                      id="tags"
                      fullWidth={true}
                    />
                </FormControl>                     
            </Box>
            <Box>
              <FormControl>
                    <InputLabel htmlFor="tags">Shortcut</InputLabel>
                    <Input
                      onChange={(event)=> setShortcut(event.target.value)}
                      id="tags"
                      fullWidth={true}
                    />
                </FormControl>                     
            </Box>
            <Box style={{marginTop : '10px'}}>
              <DropzoneArea
                acceptedFiles={['image/jpeg, image/png']}
                dropzoneText={"Arrastra una imagen o clickea aqui"}
                getFileAddedMessage={() => `La imagen ha sido agregada`}
                onChange={(files) => { console.log('Files:', files); setFile(files) ; console.log(files) }}
                filesLimit={1}
              />
            </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={handleCreateNew} color="primary">
            Crear
          </Button>
          <Button onClick={handleModalClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
        </BlockUi>
      </Dialog>       
  )
}

export default AutoResponsesImageNew;
