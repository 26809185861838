import styled from 'styled-components';

export const MessageContent = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: ${props => (props.outgoing ? 'row-reverse' : 'row')};
  align-items: ${props => (props.isSecret ? 'center' : 'flex-end')};
  margin: ${props => (props.outgoing ? props.isSecret ? '0 auto' : '0' : '0')};
  font-size: 15px;
  line-height: ${props => (props.outgoing ? '0': '1px')};
  opacity: ${props => (props.sending ? 0.3 : 1)}
  color: '#000'
`;

export const MessageTime = styled.span`
  font-size: 11px;
  color: #999;
`;
