import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

const DeleteDialog = (props) => {

    const handleClose = () => {
        props.onClose();
    };

    const handleAccept = () => {
        props.onAccept();
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
            <DialogTitle id="simple-dialog-title">¿ Confirma borrar la imágen ?</DialogTitle>
            <List>
                <ListItem button onClick={() => handleAccept()}>
                    <ListItemText primary="SI" />
                </ListItem>
                <ListItem button onClick={() => handleClose()} >
                    <ListItemText primary="NO" />
                </ListItem>
            </List>
        </Dialog>
    );
}

DeleteDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};


export default DeleteDialog;