import { FETCH_USUARIOS_REQUEST ,FETCH_USUARIOS_FAILURE,FETCH_USUARIOS_SUCCESS,FETCH_CAMPAIGNS_SUCCESS, FETCH_CAMPAIGNS_REQUEST,
  UPDATE_CAMPAIGN_CONFIG_FAILURE,UPDATE_CAMPAIGN_CONFIG_SUCCESS,UPDATE_CAMPAIGN_CONFIG_REQUEST, 
  FETCH_CAMPAIGNS_FAILURE, UPDATE_CAMPAIGN_REQUEST, UPDATE_CAMPAIGN_FAILURE, UPDATE_CAMPAIGN_SUCCESS } from './constants';
import { produce } from 'immer';

const INITIAL_STATE = {
  loading: { isLoadingCampaigns : false , isLoadingUsuarios : false, isUpdatingCampaigns : false },
  campaigns: [],
  usuarios : []
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {

    case FETCH_CAMPAIGNS_REQUEST:
      return produce(state, (draftState) => {
        draftState.loading.isLoadingCampaigns = true;
      });

    case FETCH_CAMPAIGNS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading.isLoadingCampaigns = false;
        if(action.payload.total > 0 ){ draftState.campaigns = action.payload.campaigns } 
      });

    case FETCH_CAMPAIGNS_FAILURE:
        return produce(state, (draftState) => {
          draftState.loading.isLoadingCampaigns = false;
          console.log('fecth campaigns failure',action.payload)
        });

    case FETCH_USUARIOS_REQUEST:
        return produce(state, (draftState) => {
          draftState.loading.isLoadingUsuarios = true;
        });
    
    case FETCH_USUARIOS_SUCCESS:
      return produce(state, (draftState) => {
        draftState.loading.isLoadingUsuarios = false;
        if(action.payload.total > 0 ){ draftState.usuarios = action.payload.users } 
      });

    case FETCH_USUARIOS_FAILURE:
        return produce(state, (draftState) => {
          draftState.loading.isLoadingUsuarios = false;
          console.log('error fetch Usuarios')
        });

    case UPDATE_CAMPAIGN_CONFIG_REQUEST:
      return produce(state, (draftState) => {
        console.log('updat campaign config request')
        draftState.loading.isUpdatingCampaigns = true;
      });

    case UPDATE_CAMPAIGN_REQUEST:
      return produce(state, (draftState) => {
        console.log('updat campaign request')
        draftState.loading.isUpdatingCampaigns = true;
      });
      
    case UPDATE_CAMPAIGN_SUCCESS:
      return produce(state, (draftState) => {
        console.log('update campaign success')
        draftState.loading.isUpdatingCampaigns = false;

        const index = draftState.campaigns.findIndex(campaign => (
          action.payload.campaignId === campaign.id
        ));
        console.log(draftState.campaigns[index].Activa)
        draftState.campaigns[index].Activa = action.payload.active;
    
      });

    case UPDATE_CAMPAIGN_CONFIG_SUCCESS:
      return produce(state, (draftState) => {
        console.log('update campaign config success')
        draftState.loading.isUpdatingCampaigns = false;

        const index = draftState.campaigns.findIndex(campaign => (
          action.payload.params.id === campaign.id
        ));
        
        draftState.campaigns[index] = Object.assign( draftState.campaigns[index],action.payload.params );
      });


    case UPDATE_CAMPAIGN_SUCCESS:
      return produce(state, (draftState) => {
        console.log('update campaign success')
        draftState.loading.isUpdatingCampaigns = false;

        const index = draftState.campaigns.findIndex(campaign => (
          action.payload.campaignId === campaign.id
        ));
        console.log(draftState.campaigns[index].Activa)
        draftState.campaigns[index].Activa = action.payload.active;
    
      });
  
    case UPDATE_CAMPAIGN_FAILURE:
        return produce(state, (draftState) => {
          console.log('updat campaign failure')
          draftState.loading.isUpdatingCampaigns = false;
        });

    case UPDATE_CAMPAIGN_CONFIG_FAILURE:
      return produce(state, (draftState) => {
        console.log('updat campaign config failure')
        draftState.loading.isUpdatingCampaigns = false;
      });

    default: return state;
  }
}
