import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Campanas from './Campanas';
import Clientes from './Clientes';
import Plantillas from './Plantillas';
import Grupos from './Grupos';

const useStyles = makeStyles({
    container: {
        height: '100%',
        'overflow': 'hidden'
    },
    closeBtn: {
        width: '50px',
        marginTop: '20px',
        float: 'left',
        position: 'absolute'
    }

});

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 100,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

function Marketing () {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const AntTabs = withStyles({
        root: {
            borderBottom: '1px solid #e8e8e8',
        },
        indicator: {
            backgroundColor: '#1890ff',
        },
    })(Tabs);

    return (
        <Box style={{ height: 'calc(100vh - 65px)' , display : 'flex', flexDirection : 'column'}} >
            <AntTabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                onChange={handleChange}
            >
                <AntTab label="Clientes" />
                <AntTab label="Grupos" />
                <AntTab label="Plantillas" />
                <AntTab label="Campañas" />
            </AntTabs>
            
            <Box style={{  padding: '5px', flex : 1, backgroundColor: '#ededed' }} >
                    
                    {value == 0 ? <Clientes /> : null}
                    {value == 1 ? <Grupos /> : null}
                    {value == 2 ? <Plantillas /> : null}    
                    {value == 3 ? <Campanas /> : null}
                   
            </Box>
            
        </Box>
        
    )
}

export default Marketing;