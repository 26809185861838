import {  FETCH_USUARIOS_REQUEST ,FETCH_USUARIOS_SUCCESS ,FETCH_USUARIOS_FAILURE , FETCH_CAMPAIGNS_REQUEST, 
  UPDATE_CAMPAIGN_CONFIG_FAILURE,UPDATE_CAMPAIGN_CONFIG_REQUEST,UPDATE_CAMPAIGN_CONFIG_SUCCESS,
  UPDATE_CAMPAIGN_REQUEST, UPDATE_CAMPAIGN_FAILURE, UPDATE_CAMPAIGN_SUCCESS, FETCH_CAMPAIGNS_SUCCESS, FETCH_CAMPAIGNS_FAILURE } from './constants';
// import axios from 'axios';
import Api from '../../state/Api';

export const fetchCampaigns = (userName) => async (dispatch) => {
  dispatch({type: FETCH_CAMPAIGNS_REQUEST })
  try {
    const { data } = await Api.req.get(`admin/campaigns?usuario=${userName}`);
    dispatch({
      type: FETCH_CAMPAIGNS_SUCCESS,
      payload: data
    })
  }
  catch(err) {
    dispatch({
      type: FETCH_CAMPAIGNS_FAILURE,
      payload: err
    })    
  }
}

export const updateCampaignStatus = (campaignId, estado) => async (dispatch) => {
  console.log('pasa x')
  dispatch({ type: UPDATE_CAMPAIGN_REQUEST })
  try {
    const { data } = await Api.req.put(`/admin/campaign/${campaignId}/state`,
      { active: estado }
    )
    dispatch({
      type: UPDATE_CAMPAIGN_SUCCESS,
      payload: { campaignId: campaignId, active: data.data[0].active }
    })
  }
  catch (err) {
    dispatch({
      type: UPDATE_CAMPAIGN_FAILURE,
      payload: err
    })
  }
}

export const updateCampaign = (campaignId, estado) => async (dispatch) => {
  console.log('pasa x')
  dispatch({type: UPDATE_CAMPAIGN_REQUEST })
  try {
    const { data } = await Api.req.put(`/admin/campaign/${campaignId}/state`,
    { active : estado }
    )
    dispatch({
      type: UPDATE_CAMPAIGN_SUCCESS,
      payload: { campaignId: campaignId, active : data.data[0].active}
    })
  }
  catch(err) {
    dispatch({
      type: UPDATE_CAMPAIGN_FAILURE,
      payload: err
    })    
  }
}

export const updateCampaignConfig = (params) => async (dispatch) => {
  console.log('pasa x update Campaign Config')
  console.log(params)
  dispatch({type: UPDATE_CAMPAIGN_CONFIG_REQUEST })
  /*
  try {
    const { data } = await Api.req.put(`/admin/campaign/${campaignId}/state`,
    { active : estado }
    )
    dispatch({
      type: UPDATE_CAMPAIGN_CONFIG_SUCCESS,
      payload: { params : params }
    })
  }
  catch(err) {
    dispatch({
      type: UPDATE_CAMPAIGN_CONFIG_FAILURE,
      payload: err
    })    
  }*/
}

export const fetchUsuarios = (userName) => async (dispatch) => {
  dispatch({type: FETCH_USUARIOS_REQUEST })
  try {
    const { data } = await Api.req.get(`admin/users`);
    dispatch({
      type: FETCH_USUARIOS_SUCCESS,
      payload: data
    })
  }
  catch(err) {
    dispatch({
      type: FETCH_USUARIOS_FAILURE,
      payload: err
    })    
  }
}