import React,{ useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Box, Checkbox } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 120,
    },
}));

export default function PreSaveDialog(props) {
    const classes = useStyles();
    const [ open, setOpen ] = React.useState(false);
    const [nroDocu, setNroDocu] = React.useState('');
    const [tipo, setTipo] = React.useState('');
    const [verificado, setVerificado] = React.useState(1);
    const [editarDni, setEditarDni] = React.useState(false);

    const [readOnlyDni, setReadOnlyDni ] = React.useState(props.isStaff ? false : true); 

    const handleChange = (event) => {
        setTipo(event.target.value);
    };

    const handleChangeSelect = (event) => {
        setVerificado(event.target.value);
    };

    const handleSave = () => {
        let data = {
            nroDocu: nroDocu,
            tipo : tipo,
            verificado : verificado
        }
        props.onSave(data)
    }

    const getDisabledWithout = () => {
        if (tipo && verificado != '10'){
            return false;
        }else{
            return true
        }
        
    }

    const getDisabledStaff = () => {
        console.log(nroDocu.length, tipo, verificado)
        if (nroDocu.length > 0 && tipo && verificado != '10'){
            return false;
        }else{
            return true
        }
      
    }

    const getDisabled = () => {
        if (props.isStaff || readOnlyDni == false ){
           return getDisabledStaff()
        }else{
            return getDisabledWithout()
        }

    }

    useEffect(() => {
        setNroDocu(props.isStaff ? '' : props.nroDocu)
        setReadOnlyDni(props.isStaff ? false : true)
        setTipo('')
        setVerificado('10')
    },[props.open])

    return (
        <div>
            <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Guardar como Documentación de cliente específico</DialogTitle>
                <DialogContent>
                    {props.isStaff ? '' : 
                        <FormControlLabel
                        control={
                            <Checkbox label="Editar Dni"
                                checked={!readOnlyDni}
                                onChange={() => setReadOnlyDni(!readOnlyDni)}
                                inputProps={{ 'aria-label': 'controlled' }} />
                        }
                        label="Editar Dni"
                    /> }
                    
                    
                    <Box>
                        <DialogContentText>
                            Ingrese nro de DNI
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Nro Dni"
                            type="number"
                            onChange={(event) => setNroDocu(event.target.value)}
                            value={nroDocu}
                            inputProps={{ readOnly: readOnlyDni }}
                            fullWidth
                        />
                    </Box> 
                    
                    <Box style={{ margin : '10px 0' }}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Tipo</FormLabel>
                            <RadioGroup aria-label="gender" name="gender1" value={tipo} onChange={handleChange} row>
                                <FormControlLabel value="dni" control={<Radio />} label="DNI" />
                                <FormControlLabel value="tarjeta" control={<Radio />} label="TARJETA" />
                                <FormControlLabel value="tarjetaydni" control={<Radio />} label="TARJETA Y DNI" />
                                <FormControlLabel value="comprobantes" control={<Radio />} label="COMPROBANTES" />
                                <FormControlLabel value="autpretirar" control={<Radio />} label="AUT P/RETIRAR" />
                                <FormControlLabel value="tarjetasverificar" control={<Radio />} label="TARJETA SIN VERIFICAR" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Verificado</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={verificado}
                                displayEmpty
                                onChange={handleChangeSelect}
                            >
                                <MenuItem value={10} selected>Seleccione</MenuItem>
                                {props.isStaff ? <MenuItem value={0} selected>Incorrecto</MenuItem> : ''}
                                <MenuItem value={1}>Verificado</MenuItem>
                                <MenuItem value={9}>Pendiente</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleSave} disabled={getDisabled()} color="primary">
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}