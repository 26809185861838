import React, { useState, useEffect } from 'react';
import Api from '../../../state/Api';
import { TextField , Dialog, DialogActions, DialogTitle, DialogContent, Button, Box, FormControl, Input, InputLabel } from "@material-ui/core";
import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import emitter from '../../../factories/EventEmitter';
import {DropzoneArea} from 'material-ui-dropzone'
import * as appActions from '../../../state/app/actions';
import BlockUi from 'react-block-ui';

const AutoResponsesFileNew = ( props ) => {
  
  const classes = props.classes;

  const dispatch=useDispatch();
  
  const [titulo, setTitulo] = useState(null);
  const [descripcion, setDescripcion] = useState(null);
  const [tags, setTags] = useState(null);
  const [shortcut, setShortcut] = useState(null);
  const [file, setFile] = useState([]);
  const disabled = !(file[0]);
  const [enviandoDatos, setEnviandoDatos] = useState('');
  const handleModalClose = () => {
    props.onClose()
  };  

  const handleEmitirRespuesta = (message) => {
    props.onEmitioRespuesta(message)
  }

  const handleImageChange = (value) => {
      console.log(value)
  };

  const getFileName = (filename) => {
    if(filename.includes('.')){
      return filename.split('.')[0]
    }
    
    return filename;
  }

  const handleCreateNew = () => {

    const params = {
      tmpt : file[0]
    }

    

    var formData = new FormData();

    Object.keys(params).forEach(key => {
        formData.append(key, params[key]);
    })

    //axios.post('/some-end-point',  formData);
    setEnviandoDatos(true)
    Api.req.post(`prospectos/contact/${props.contactId}/media`,formData).then((response)=>{
      //debugger
      let paramsConId = Object.assign({  name : getFileName(file[0].name) , messagereference : response.data.url },params)
      props.onNewFile(paramsConId)
      setEnviandoDatos(false)
    }, (error) =>  { 
      dispatch(appActions.addToast('Ha habido un error al subir el archivo : ' + error.response.data.msg ,'error'))
      setEnviandoDatos(false)
    })
  };

  
  const [fileObjects, setFileObjects] = useState([]);

  return (
      <Dialog
      fullWidth={true}
      maxWidth = {'xs'}
      open={props.isOpen} 
      onClose={handleModalClose} aria-labelledby="form-dialog-title">
      <BlockUi tag="div" blocking={enviandoDatos}>
        <DialogTitle id="customized-dialog-title" >
          Adjuntar
        </DialogTitle>
        <DialogContent dividers>
            <Box style={{marginTop : '10px'}}>
              <DropzoneArea
                acceptedFiles={['application/pdf',"video/mp4"]}
                dropzoneText={"Arrastra un archivo pdf o video(mp4) o clickea aqui"}
                maxFileSize={10000000}
                getFileAddedMessage={() => `Archivo Agregado`}
                onChange={(files) => { console.log('Files:', files); setFile(files) ; console.log(files) }}
                filesLimit={1}
              />
            </Box>
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={handleCreateNew} color="primary">
            Enviar
          </Button>
          <Button onClick={handleModalClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
        </BlockUi>
      </Dialog>       
  )
}

export default AutoResponsesFileNew;
