import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainApp from './components/App';

// Redux
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';

import rootReducer from './state/reducer';

import * as serviceWorker from './serviceWorker';
//import Firebase from './firebase';

const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(reduxThunk) 
));

ReactDOM.render(
  <Provider store={ store }>
    <MainApp />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
//Firebase.register();