import React from "react";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import ChatIcon from "@material-ui/icons/Forum";
import SMSIcon from "@material-ui/icons/Sms";
import CallIcon from "@material-ui/icons/Call";
import InstagramIcon from '@material-ui/icons/Instagram';
import { LiveHelp, Inbox  } from "@material-ui/icons";

import MessengerIcon from "../Header/MessengerIcon";
import MeliIcon from "../Header/MeliIcon";

const IconShow = ( props ) => {
    const renderIcon = () => {
        switch(props.source) {
          case 'WhatsApp':
            return <WhatsappIcon className={props.classes.IconSize} />
          case 'MessengerIcon':
            return <MessengerIcon classes={props.classes} />
          case 'facebook-comments':
            return <FacebookIcon className={props.classes.IconSize}/>   
          case 'Instagram': 
            return <InstagramIcon className={props.classes.IconSize}/>        
          case 'Forum':
            return <ChatIcon className={props.classes.IconSize} />
          case 'Sms':
            return <SMSIcon className={props.classes.IconSize} />
          case 'Phone':
            return <CallIcon className={props.classes.IconSize} />
          case 'MercadoLibre':
            return <MeliIcon classes={props.classes} />
          case 'MeliPregunta':
            return <LiveHelp classes={props.classes} />
          case 'MeliInbox':
            return <Inbox classes={props.classes} />    
          default:
            return <SMSIcon className={props.classes.IconSize} />
        }
      };  

      return renderIcon(props.name)

}

export default IconShow;