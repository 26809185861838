import Api from '../Api';
import * as appActions from '../../state/app/actions';

import {
  ADD,
  USER_QUEUE_ADD,
  ONLINE_LIST,
  ONLINE,
  REQUEST_ALL_USERS,
  RECEIVED_ALL_USERS,
  INVALIDATE_ALL_USERS,
  OFFLINE,
  PAUSE_SUCCESS,
  PAUSE_FAILED,
  SET_PAUSA_LOGIN,
  INIT_PAUSA
} from './constants';

export const fetchData = id => (dispatch, getState) => {
  const user = getState().users.data[id];
  if (!user) {
    dispatch({ type: USER_QUEUE_ADD, id });

    return {
      id,
      username: 'loading...',
      displayname: 'loading...',
      loading: true,
      avatar: '',
    };
  }

  return {
    ...user,
    online: getState().users.online.includes(id),
  };
};

export const add = users => (dispatch) => {
  console.log(users);
  if (typeof users === 'number') dispatch({ type: USER_QUEUE_ADD, id: users });
  else dispatch({ type: ADD, data: users });
};

export const fetchByUsername = username => async (dispatch) => {
  const { data } = await Api.req.get(`/users/${username.trim()}`);
  dispatch({ type: ADD, data: { [data.id]: data } });

  return { ...data };
};


export const fetchAllUsers = campaignId => async (dispatch) => {
  dispatch({ type: REQUEST_ALL_USERS });
  console.log('pasa x fetch')
  try {
    const { data } = await Api.req.get(`/campaigns/${campaignId}/agents`);
    if(data){
      dispatch({ type: RECEIVED_ALL_USERS, payload: data.data })
    }

  }
  catch (err) {
    console.log(err)
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: INVALIDATE_ALL_USERS, error: (error || err) });
  }

};

export const handleOnlineList = users => dispatch => dispatch({
  type: ONLINE_LIST,
  data: users,
});

export const handleOnline = ({ userId, userName }) => dispatch => dispatch({ type: ONLINE, payload: { userId, userName } });

export const handleOffline = ({ userId }) => dispatch => dispatch({ type: OFFLINE, userId });


export const setPausaLogin = () => dispatch => {
  dispatch({type: SET_PAUSA_LOGIN})
};

export const initPausa = (userId) => async (dispatch,useState) => {
  dispatch({type: INIT_PAUSA})
  let pause = useState().users.config.pausa;
  let state = useState().users.config.estadoPausa;
  try {
    const { data } = await Api.req.put(`/agents/${userId.trim()}/status`, {pause : pause, state : state});
  } catch (error) {
    console.error(error)
    dispatch(appActions.addToast('Ha habido un error al efectuar la pausa','error'))
  }
}

export const handlePause = ( userId, pause, success, errorFn ) => async dispatch =>  {
  try {
    const { data } = await Api.req.put(`/agents/${userId.trim()}/status`, pause);
    dispatch({type: PAUSE_SUCCESS, data: {userId, pause} });
    success()
  } catch (error) {
    console.log(error)
    dispatch({ type: PAUSE_FAILED, error: (error) });
    dispatch(appActions.addToast('Ha habido un error al efectuar la pausa','error'))
    errorFn()
  }
}