export const getCampaigns = () => ({ admin: state }) => state.campaigns;
export const getUsuariosSelected = (agentes) => ({ admin: state }) => {
    let newUsers = state.usuarios.map((item) => ({
        ...item,
        selected: false       
    }));
    if(agentes){
        for(var i=0 ; i < agentes.length ; i ++ ){
            let index = newUsers.findIndex((x) => agentes[i].Agent === x.Usuario);
            if(index !== -1){
                newUsers[index].selected = true 
            }
        }
    }
   
    let usuariosSelected = newUsers.filter((user) => user.selected )

    return usuariosSelected;
} ;

export const getUsuariosNotSelected = (agentes) => ({ admin: state }) => {
    let newUsers = state.usuarios.map((item) => ({
        ...item,
        selected: false       
    }));
    if(agentes){
        for(var i=0 ; i < agentes.length ; i ++ ){
            let index = newUsers.findIndex((x) => agentes[i].Agent === x.Usuario);
            if(index !== -1){
                newUsers[index].selected = true 
            }
        }
    }

    let usuariosNotSelected = newUsers.filter((user) => !user.selected )
   
    return usuariosNotSelected;
} ;
