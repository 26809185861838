import React, { useState , useEffect  } from "react";
import UserIcon from "@material-ui/icons/AccountCircle";
import { green, indigo, pink, red } from '@material-ui/core/colors';
import clsx from 'clsx';

import { Typography, ListItemAvatar, Badge,  Avatar , Drawer, List, ListItem, IconButton, Divider, ListItemIcon, ListItemText, CardContent, CardActions } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom';

import Api from "../../state/Api";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LayersIcon from '@material-ui/icons/Layers';
import AccessAlarm from '@material-ui/icons/AccessAlarm';
import Supervisor from '@material-ui/icons/Hearing';
import Chart from '@material-ui/icons/BarChart';
import HeadsetMic from '@material-ui/icons/HeadsetMic';
import QueueRight from './QueueRight';
import moment from 'moment';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as authSelectors from '../../state/auth/selectors';
import * as appSelector from '../../state/app/selectors';
import * as userSelectors from '../../state/users/selectors';
import * as appActions from '../../state/app/actions';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import StatsData from './../ChatsDashboard/StatsData/StatsData';

const drawerWidth = 300;
const drawerWidthBig = 500;

const useStyles = makeStyles(theme => (
  {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      marginRight: '50px'
    },
    drawerBig: {
      width: drawerWidthBig,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      marginRight: '50px'
    },
    drawerOpenBig: {
      width: drawerWidthBig,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(6) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7) + 1,
      },
    },  
    heightAdjust: {
      display: "flex",
      flexDirection: "column",
      height: "calc(100vh - 65px)",
      border: theme.palette.borders
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    }
  }
))


const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const StyledBadgeRed = withStyles((theme) => ({
  badge: {
    backgroundColor: '#ee0000',
    color: '#ee0000',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  }

}))(StyledBadge);

const StyledBadgeCondicional = (props) => {
  if(props.pause){
    return  <StyledBadgeRed
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar >{props.nameCode}</Avatar>
            </StyledBadgeRed>
  }else{
    return <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar >{props.nameCode}</Avatar>
            </StyledBadge>
  }
 
}

const MenuRightChats = ( props ) => {
  const dispatch = useDispatch();
  const menu = useSelector(appSelector.getChatsMenuState());
  const [showChatMenu, setShowChatMenu ] = useState(false)
  const classes = useStyles(useTheme());
  const isAdmin = useSelector(authSelectors.isAdmin(), shallowEqual);
  const isSuper = useSelector(authSelectors.isSuper(), shallowEqual);
  const users = useSelector(userSelectors.getAllOnlineUsers());
  const [resultadosQueue,setResultadosQueue] = useState([]);
  const menuUsers = useSelector(appSelector.getUsersMenuState());

  const buscarQueue = async () => {
    try {
      await Api.req.get(`/supervisor/stats/campaign/2/queue?startDate=${moment().utcOffset(0, true).format("YYYY-MM-DD")}&endDate=${moment().utcOffset(0, true).format("YYYY-MM-DD")}`)
      .then((response) => { setResultadosQueue(response.data.data) });
    }
    catch(err){ }
  }

  const handleChangeMenuState = (e) => {
    dispatch(appActions.changeMenuState())
    
  }
  const handleChangeChatsMenuState = () => {
    dispatch(appActions.changeChatsMenuState())
  }

  useEffect(() => {
    buscarQueue()
    if(menu.isOpen){
      setTimeout(() => setShowChatMenu(true), 350)
    }else{
      setShowChatMenu(false)
    }
 
  }, [menu]);

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      className={clsx(classes.drawer, {
        [classes.drawer]: !props.isLargeScreen,
        [classes.drawerBig]: props.isLargeScreen,
        [classes.drawerOpen]: menu.isOpen && !props.isLargeScreen,
        [classes.drawerOpenBig]: menu.isOpen && props.isLargeScreen,
        [classes.drawerClose]: ! menu.isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: menu.isOpen && !props.isLargeScreen,
          [classes.drawerOpenBig]: menu.isOpen && props.isLargeScreen,
          [classes.drawerClose]: !menu.isOpen,
        }),
      }}

      PaperProps={{ style: { position: 'absolute' } }}
      open={menu.isOpen}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleChangeChatsMenuState}>
          { menu.isOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
     
      <div className={classes.heightAdjust}>
        {props.activeContact && showChatMenu ? <StatsData handleGuardoDatos={props.handleGuardoDatos} contact={props.activeContact} /> : '' } 
      </div>
    </Drawer>
  );  

};

export default MenuRightChats