import React, { useState } from "react";
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TextField ,Paper,Input,InputLabel,Select,MenuItem, Fab, Grid,Button, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Badge, CircularProgress, LinearProgress , Tooltip, CardContent, Typography } from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  AreaSeries,
  BarSeries,
  Title,
  Legend
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, EventTracker } from '@devexpress/dx-react-chart';

import Agents from './Agents';
import Queue from './Queue';

import Api from '../../../../state/Api';
import {
  LocalizationProvider,
  DatePicker
} from '@material-ui/pickers';

import {
  curveCatmullRom,
  area,
} from 'd3-shape';
import { scalePoint } from 'd3-scale';

import MomentUtils from '@date-io/moment';
import MomentAdapter from "@material-ui/pickers/adapter/moment";

import * as contactsActions from '../../../../state/contacts/actions';
import * as conversationActions from '../../../../state/conversation/actions';
import * as contactsSelector from '../../../../state/contacts/selectors';
import * as campaignsSelector from '../../../../state/channels/selectors';

import * as appActions from '../../../../state/app/actions'; 
import * as authSelectors from '../../../../state/auth/selectors';
import moment from 'moment';  

import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginRight: -drawerWidth
  },
  formControl: {
    margin: theme.spacing(1.5),
    minWidth: '90%',
  },
  cien: {
    margin: theme.spacing(1.5),
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  counter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
  },  
}));

const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
});
const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const legendLabelStyles = () => ({
  label: {
    whiteSpace: 'nowrap',
  },
});
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);



const Main = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const campaigns = useSelector(campaignsSelector.getChannels());
  //const chatsPublish = props.todas ? allChatsFiltered : allChats;
  const [fechaDesde, setFechaDesde] = useState(moment().utcOffset(0, true).format("YYYY-MM-DD"));
  const [fechaHasta, setFechaHasta] = useState(moment().utcOffset(0, true).format("YYYY-MM-DD"));
  const [selectedCampaign, setSelectedCampaign] = useState(2);
  const [selectedResult, setSelectedResult] = useState('');  
  const [resultados,setResultados] = useState([]);
  const [resultadosDashboard,setResultadosDashboard] = useState([]);
  const [resultadosHora,setResultadosHora] = useState([]);
  const [resultadosQueue,setResultadosQueue] = useState([]);

  // const { data: chartData } = resultadosHora;

  const cambioCampaign = (campaignId) =>{
    Api.req.get(`/supervisor/stats/campaigns/${campaignId}/agents`)
    .then((response) => {
      setResultados(response.data.data)
    });
  }

  const buscarHora = async () => {
    try {
      await Api.req.get(`/supervisor/stats/campaign/${selectedCampaign}/stats/hour?startDate=${moment(fechaDesde).utcOffset(0, true).format("YYYY-MM-DD")}&endDate=${moment(fechaHasta).utcOffset(0, true).format("YYYY-MM-DD")}`)
      .then((response) => { setResultadosHora(response.data.data) });
    }
    catch(err){ }
  }

  const buscarGeneral = async () => {
    try {
      await Api.req.get(`/supervisor/stats/campaign/${selectedCampaign}/stats?startDate=${moment(fechaDesde).utcOffset(0, true).format("YYYY-MM-DD")}&endDate=${moment(fechaHasta).utcOffset(0, true).format("YYYY-MM-DD")}`)
      .then((response) => {  setResultadosDashboard(response.data.data) });
    }
    catch(err){    }
  }

  const buscarPorFecha = async () => {
    try {
      await Api.req.get(`/supervisor/stats/campaign/${selectedCampaign}/agents?startDate=${moment(fechaDesde).utcOffset(0, true).format("YYYY-MM-DD")}&endDate=${moment(fechaHasta).utcOffset(0, true).format("YYYY-MM-DD")}`)
      .then((response) => { setResultados(response.data.data) });
    }
    catch(err){    }
  }

  const buscarQueue = async () => {
    try {
      await Api.req.get(`/supervisor/stats/campaign/${selectedCampaign}/queue?startDate=${moment(fechaDesde).utcOffset(0, true).format("YYYY-MM-DD")}&endDate=${moment(fechaHasta).utcOffset(0, true).format("YYYY-MM-DD")}`)
      .then((response) => { setResultadosQueue(response.data.data) });
    }
    catch(err){ }
  }

  const Area = props => (
    <AreaSeries.Path
      {...props}
      path={area()
        .x(({ arg }) => arg)
        .y1(({ val }) => val)
        .y0(({ startVal }) => startVal)
        .curve(curveCatmullRom)}
    />
  );
  

  return (
    <>
          <Grid container style={{marginRight: 10}}>
              <Grid item xs={3}>
                    {/* <InputLabel id="demo-customized-select-label">Campaña</InputLabel> */}
                    <FormControl className={classes.formControl} >
                    <Select
                      labelId="diposition-label"
                      id="diposition"
                      value={selectedCampaign}
                      onChange={(event) => { setSelectedCampaign(event.target.value); } }
                      fullWidth={true}
                      input={<Input />}
                      className={classes.cien}
                    >
                    {
                      campaigns.length > 0 ? campaigns.map((option, index) => 
                      <MenuItem key={index} value={option.id}>{option.Nombre}</MenuItem>
                    ) : null }
                    <MenuItem value={0}>Campaña</MenuItem>    
                    </Select>
                    {/* <FormHelperText>Required</FormHelperText> */}
                    </FormControl>
              </Grid>
                <Grid item xs={3} style={{ marginTop : '9px'}}>
                 <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                    <DatePicker
                        renderInput={(props) => <TextField {...props} helperText="" />}
                        disableToolbar
                        variant="inline"
                        fullWidth={true}
                        size="small"
                        inputFormat="DD/MM/YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        className={classes.cien}
                        label="Fecha Desde"
                        autoOk={true}
                        value={fechaDesde}
                        onChange={ date => setFechaDesde(date) }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                  </LocalizationProvider>
                </Grid>                      
                <Grid item xs={3} style={{ marginTop : '9px'}}>
                <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                    <DatePicker
                        renderInput={(props) => <TextField {...props}  helperText="" />}
                        disableToolbar
                        variant="inline"
                        inputFormat="DD/MM/YYYY"
                        margin="normal"
                        size="small"
                        id="date-picker-inline2"
                        className={classes.cien}
                        label="Fecha Hasta"
                        fullWidth={true}
                        autoOk={true}
                        value={fechaHasta}
                        onChange={ date => setFechaHasta(date) }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                  </LocalizationProvider>
                </Grid>                      
                <Grid item xs={3}>
                  <Button onClick={() => { buscarPorFecha(); buscarGeneral(); buscarHora(); buscarQueue(); } } 
                    fullWidth={true}
                    style={{ 'marginTop':'25px' }} 
                    size="small"
                    // disabled={!selectedCampaign || !selectedResult}
                    variant="contained" color="primary">
                    Buscar
                  </Button>
                </Grid>
              </Grid>
  
          {resultadosDashboard.map((row,index) => (
           <div key={index} className={classes.counter}>
            <Paper elevation={3} >
              <Typography variant="h2">{row.operadores} / {row.operadoresAsignados}</Typography>
              <Typography variant="body1">Operadores Disponibles / Asignados</Typography>
            </Paper>
            <Paper elevation={3} >
              <Typography variant="h2">{row.contactosLibres}</Typography>
                <Typography variant="body1">Contactos en Cola</Typography>
            </Paper>
            <Paper elevation={3} >
              <Typography variant="h2">{row.contactosAsignados}</Typography>
                <Typography variant="body1">Contactos Asignados</Typography>
            </Paper>
            <Paper elevation={3} >
              <Typography variant="h2">{row.contactosFinalizados}</Typography>
                <Typography variant="body1">Contactos Finalizados</Typography>
            </Paper>            
            <Paper elevation={3} >
              <Typography variant="h2">{row.contactosTotales}</Typography>
                <Typography variant="body1">Contactos Totales</Typography>
            </Paper>
          </div>
        ))}

      <Grid container >
        <Grid item xs={8}>
          <Agents classes={props.classes} buscarPorFecha={buscarPorFecha} agentStats={resultados}/>
        </Grid>
        <Grid item xs={4}>
        <div style={{margin: 5, marginRight: 10, height: '100px', position: 'relative',}}>
          <Paper elevation={3}>
            <Chart height={400}
              data={ resultadosHora }
            >
              {/* <ValueScale name="total" /> */}
              <ArgumentAxis />
              <ValueAxis max={2} />

              <BarSeries
                name="Contactos"
                valueField="contactos"
                argumentField="statHora"
                color="#ffd700"
                barWidth={500}
                // scaleName="Contactos"
              />
              <BarSeries
                name="Offline"
                valueField="contactosoff"
                argumentField="statHora"
                color="secondary"
                barWidth={500}
              />
              <AreaSeries
                name="Agentes"
                valueField="agentes"
                argumentField="statHora"
                seriesComponent={Area}
              />
              <Title text="Contactos por Hora" />
              {/* <Animation /> */}
              <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
              <Stack/>
            </Chart>
          </Paper>
            <div>
              <Queue classes={props.classes} queueStats={resultadosQueue}/>
            </div>
          </div>

        </Grid>
      </Grid>
    </>
  )
}

export default Main