/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import io from 'socket.io-client';

const Context = createContext();

export class ClientSocket extends React.Component {
  constructor(props) {
    super(props);

    this.socket = io(props.url, {
      transports: ['websocket'],
      query: `user=${props.user}`,
    });
  }

  componentWillUnmount() {
    this.socket.disconnect()
  }

  render() {
    const { children } = this.props;
    return (
      <Context.Provider value={this.socket}>
        {children}
      </Context.Provider>
    );
  }
}

ClientSocket.propTypes = {
  url: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export const useSocket = (eventKey, callback) => {
  const socket = useContext(Context);
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    if (eventKey && socket) {
      // eslint-disable-next-line no-inner-declarations
      // console.log(eventKey);
      function socketHandler() {
        // eslint-disable-next-line
        callbackRef.current && callbackRef.current.apply(this, arguments);
      }

      socket.on(eventKey, socketHandler);
      return () => socket.removeListener(eventKey, socketHandler);
    }

    return () => null;
  }, ['eventKey']);

  // const emitEvent = () => {
  //   socket.emit(eventKey, data)
  // }

  return socket;
};



// export const sendSocket = (eventKey, callback) => {
//   const socket = useContext(Context);

//   socket.emit(eventKey, callback);
//   // return () => null;

//   return socket;
// };