import React, { useState } from 'react';
import { Dialog, DialogContentText, DialogActions, DialogTitle, DialogContent, Button, TextField } from "@material-ui/core";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import CommentIcon from '@material-ui/icons/Announcement';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ImageIcon from '@material-ui/icons/Image';
import MicIcon from '@material-ui/icons/Mic';
import ListIcon from '@material-ui/icons/List';
import ProductosGallery from './ProductosGallery';
import LinkIcon from '@material-ui/icons/Link';

import { relative } from 'path';
import moment from 'moment';

import { useDispatch, useSelector,shallowEqual } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import * as contactsActions from '../../../state/contacts/actions';
import * as authSelectors from '../../../state/auth/selectors';
import * as campaignsSelector from '../../../state/channels/selectors';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    bottom: 40,
    left: 0,
    margin: '0 auto',
  },
}))
const ActionsButton = ( props ) => {
  const classes = useStyles();

  const dispatch=useDispatch();
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const campaign = useSelector(campaignsSelector.getChannel(props.campaignid));
  const [direction, setDirection] = useState('right');
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCatalogOpen, setModalCatalogOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [commentText, setCommentText] = useState('');  
  const [isOpenAdjunto, setIsOpenAdjunto] = useState(false);  
  const [ show, setShow ] = useState(props.contact);

  const handleDirectionChange = event => {
    setDirection(event.target.value);
  };

  const handleHiddenChange = event => {
    setHidden(event.target.checked);
  };

  const handleOpenResponses = () => {
    props.onOpenResponses();
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };  

  const handleModalCatalogoOpen = () => {
    setModalCatalogOpen(true);
  };

  const handleModalCatalogClose = () => {
    setModalCatalogOpen(false);
    setOpen(false)
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalTevecomprasLink = () => {
    props.onSendTevecomprasLink()
  };
  
  const handleModalClose = () => {
    setModalOpen(false);
  };  

  const handleAddPdfFile = () => {
    props.onPdfFile()
  }


  const [outgoingMessage, setMessage] = useState('');

  const sendMessage = (e) => {
    dispatch(contactsActions.sendMessage(e))
    setCommentText('');
  }

  const handleNoteAdd = () => {
    sendMessage({
      contactId: props.contact.id,
      campaignid: props.contact.campaignid,
      callerid: props.contact.callerid,
      message: {
        id: '0',
        from: me.username,
        msg: commentText,
        fechaCorta: moment().format('DD/MM/YYYY'),
        outbound: true,
        readByAgent: false,
        status: 0,
        isSecret: true
      }
    });
    
    handleModalClose();   
  }

  const actions = [
    { campaignId: 0, icon: <CommentIcon />, name: 'Agregar Observaciones', actionHandler: handleModalOpen },
    { campaignId: 0, icon: <ShoppingCartIcon />, name: 'Agregar Link', actionHandler: handleModalCatalogoOpen },
    { campaignId: 0, icon: <LinkIcon />, name: 'Agregar Link Tevecompras', actionHandler: handleModalTevecomprasLink },
    
    { campaignId: 3, icon: <AttachFileIcon />, name: 'Archivo', actionHandler: handleNoteAdd },
    { campaignId: 3, icon: <ImageIcon />, name: 'Imagen', actionHandler: handleNoteAdd },
    { campaignId: 3, icon: <MicIcon />, name: 'Audio', actionHandler: handleNoteAdd },

    { campaignId: 99, icon: <CommentIcon />, name: 'Agregar Observaciones', actionHandler: handleModalOpen },
    { campaignId: 0, icon: <ListIcon />, name: 'Mensajes Predefinidos', actionHandler: handleOpenResponses },
  ];  

  if(props.contact.campaign_sendFile){
    actions.push({ campaignId: 0 , icon: <AttachFileIcon />, name: 'Adjuntar', actionHandler: handleAddPdfFile })
  }


  const handleNewMsg = (msg) => {
    setOpen(false)
    props.onNewMsg(msg)
  }

  const actionsMessageDue = () => {
    if(!props.messageDue){
      return actions.filter(x => x.name !== 'Mensajes Predefinidos' && x.name !== 'Agregar Link' )
    }else{
      return actions;
    }
  }

  return (
    <div style={{ position: 'relative', margin: '0 auto', display: props.campaignid !== 13 ? 'block' : 'none' }}>
    <ProductosGallery dataPanel={props.dataPanel}  modalOpen={modalCatalogOpen} onNewMessage={handleNewMsg} onModalCatalogClose={handleModalCatalogClose}></ProductosGallery>
    <Dialog open={modalOpen} onClose={handleModalClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">AGREGAR OBSERVACIONES</DialogTitle>
        <DialogContent  style={{width: '500px'}}>
          <DialogContentText>
            Escribir comentario.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="Nota"
            // label="OBSERVACIONES"
            value={commentText}
            onChange={({ target: { value } }) => setCommentText(value)}
            onKeyPress={(event, value) => event.key === 'Enter' && commentText ? handleNoteAdd() : null}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleNoteAdd} color="primary" disabled={!commentText}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>       
      <SpeedDial
        ariaLabel="Acciones"
        className={classes.fabButton}
        hidden={hidden}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={direction}
      >
          {actionsMessageDue().filter(x => x.campaignId === props.campaignid || x.campaignId === 0 ).map(action => (
            <SpeedDialAction
              key={action.name}
              hidden={true}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipPlacement="top"
              // openIcon={{backgroundColor: '#90caf9'}}
              onClick={action.actionHandler}
            />
          ))}
      </SpeedDial>
    </div>
  )
}

export default ActionsButton;
