export const isFetching = () => ({ contacts: state }) => state.fetching;

export const isInitialized = () => ({ contacts: state }) => state.initialized;

export const hasFailed = () => ({ contacts: state }) => !!state.fetchError;

export const fetchContacts = () => ({ contacts: state }) => state.contacts;

export const getContacts = () => ({ contacts: state }) => state.contacts;
export const getContactsFiltered = () => ({ contacts: state }) => state.contactsFiltered;

export const hasMessagesUnread = () => ({ contacts: state }) => state.sinleer.mensajesSinleer;

export const getContact = contactId => ({ contacts: state }) =>  {

  const index = state.contacts.findIndex(contact => (
    contactId === contact.id
  ));

  return contactId ? state.contacts[index] : [];

}

export const getContactClientInfo = contactId => ({ contacts: state }) => {

  const index = state.contacts.findIndex(contact => (
    contactId === contact.id
  ));

  return contactId && state.contacts[index].clientData ? state.contacts[index].clientData : {};

}

export const getMessagesSuper = contactId => ({ contacts: state }) => {

  const index = state.contactsFiltered.findIndex(contact => (
    contactId === contact.id
  ));

  return contactId ? state.contactsFiltered[index].messages : [];

}

export const getMessages = contactId => ({ contacts: state }) => {

    const index = state.contacts.findIndex(contact => (
      contactId === contact.id
    ));
  
    return contactId && state.contacts.length > 0 ? state.contacts[index].messages : [];

}

export const getMessagesSent = contactId => ({ contacts: state }) => {

  const index = state.contacts.findIndex(contact => (
    contactId === contact.id
  ));

  const sentMessages = state.contacts[index].messages.filter((message) => message.outbound == 1)

  return contactId ? sentMessages : [];

}

export const getMessagesSentFiltered = contactId => ({ contacts: state }) => {

  const index = state.contactsFiltered.findIndex(contact => (
    contactId === contact.id
  ));

  const sentMessages = state.contactsFiltered[index].messages.filter((message) => message.outbound == 1)

  return contactId ? sentMessages : [];

}

export const getUnreadMercadolibre = () => ({ contacts: state }) => {
  return state.mercadoLibre.unread;
}

export const totalUnread = () => ({ contacts: state }) => state.contacts.reduce((sum, mId) => (
  sum + state.contacts[mId].unread), 0);