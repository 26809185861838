import React, { useState } from "react";
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {Tabs, Tab,Box,Paper, } from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';


import Main from './Main/Main';
import Contactos from './Contactos/Contactos';

import Api from '../../../state/Api';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// import {
//   curveCatmullRom,
//   area,
// } from 'd3-shape';
// import { scalePoint } from 'd3-scale';

import { makeStyles } from '@material-ui/core/styles';
import ContactosDiarios from "./ContactosDiarios/ContactosDiarios";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginRight: -drawerWidth
  },
  formControl: {
    margin: theme.spacing(1.5),
    minWidth: '90%',
  },
  cien: {
    margin: theme.spacing(1.5),
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  counter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
  },  
}));



const SupervisorDashboard = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  //const chatsPublish = props.todas ? allChatsFiltered : allChats;
  const [isExpanded, setExpanded] =  useState(false); 
  const [value, setValue] = useState(0);

  const handleExpandClick = () => {
    props.handleChangeMenuState(); 
    setExpanded(!isExpanded);
}

const handleChange = (event, newValue) => {
    setValue(newValue);
};

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Dashboard"  />
        <Tab label="Contactos x Hora"  />
         <Tab label="Contactos Diarios"/>
        {/*<Tab label="Stats"/> */}
      </Tabs>
      <Box  style={{'height' : '100%',padding : '10px'}} >
          { value == 0 ? <Main contact={props.contact} ></Main>  : null }
          { value == 1 ? <Contactos></Contactos> : null }
          { value == 2 ? <ContactosDiarios/> : null }
      </Box>
    </>
  )
}

export default SupervisorDashboard