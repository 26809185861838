import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import {connect} from "react-redux";


const LoadingWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform :translate(-50%,-50%);
  z-index:2000;
`;

const mapStateToProps = state => ({
   loading: state.contacts.loading.isLoadingContacts || state.contacts.loading.isLoadingMessages || state.admin.loading.isLoadingCampaigns || state.admin.loading.isLoadingUsuarios || state.admin.loading.isLoadingUsuarios 
})


const LoadingPage = (props) => {
      return (props.loading ?  <LoadingWrapper>
        <CircularProgress color="secondary" />
      </LoadingWrapper> : null)
}



export default connect(mapStateToProps) (LoadingPage);
