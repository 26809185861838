import React, { useEffect, useState, useCallback } from "react";
import { Grid, Popper, MenuItem,  IconButton, Button , InputAdornment , MenuList, TableBody, TableCell, TableHead, TableRow, Chip, Avatar, Typography, Box, TextField } from "@material-ui/core";
import { DataGrid } from '@mui/x-data-grid';
import Api from "../../state/Api";
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { debounce } from "lodash";
import AgregarContactoModal from "./AgregarContactoModal/AgregarContactoModal";
import AgregarContactoGrupoModal from "./AgregarContactoGrupoModal/AgregarContactoGrupoModal";
import AddIcon from '@material-ui/icons/Add';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DehazeIcon from '@material-ui/icons/Dehaze';
import { Refresh } from "@material-ui/icons";

const useStyles = makeStyles ((theme) => ({

    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    root: {
        '& .super-app-theme--header': {
            color : 'black'
        },
        '& .super-app-theme--row': {
            color: 'black'
        },
    },
    blackText : {
        color : 'black'
    },
    tableContainer: {
        height: 'calc(100vh - 175px)'
    },
    chip: {
        marginBottom: '10px'
    },
    paperStyle: {
        width: '50%',
        textAlign: 'center',
        marginBottom: '10px'
    },
    destacadoNumber: {
        color: '#42a5f5'
    },
    heightAdjust: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 185px)",
        // border: theme.palette.borders
    },
    th: {
        color : 'red'
    }
}));




function Clientes() {
    const [clientes, setClientes ] = React.useState([]);
    const classes = useStyles();
    const [ searchField, setSearchField ] = React.useState("")
    const [ clienteModalOpen, setClienteModalOpen ] = React.useState(false)
    const [ contactoModalOpen, setContactoModalOpen ] = React.useState(false)
    const [ currentContactId, setCurrentContactId ] = React.useState("")
    const [pageSize, setPageSize] = React.useState(50);
    const [page, setPage ] =  React.useState(0);
    const [rowCount, setRowCount ] =  React.useState();
    const [loading , setLoading ] = React.useState();
    const [search , setSearch ] = React.useState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopper = Boolean(anchorEl);
   
    const handleClose = () => {
        setClienteModalOpen(false)
    }
   
    const handleCloseAgregarGrupo = () => {
        setContactoModalOpen(false)
    }

    const getColor = (value) => {
        if(value == true ){ return 'green '}
        if(value == false ){ return 'green '}
        if(value == null ){ return 'grey '}
    }

    const refreshClientes = () => {
        var param = 5000;
        Api.req.get(`marketing/${param}/contacts`)
            .then((response) => {
                setClientes(response.data.data)
                setClienteModalOpen(false)
            })
    }

    const getLabelName = (val) => {
        if (val == null) {
            return 'pending'
        }
        if (val == true) {
            return 'opted in'
        }
        if (val == false) {
            return 'opted out'
        }
    }

    const getChipColor = (val) => {
        if(val == null){
            return 'default'
        }
        if (val == true){
            return 'primary'
        }
        if (val == false) {
            return 'secondary'
        }
    }

    const buscar = ( param ) => {
        Api.req.get(`marketing/5000/contacts?rows=50&page=0&orderBy=createdAt&search=${param}`)
            .then((response) => {
                setClientes(response.data.data)
                setRowCount(response.data.total)
            })
        
    }


    const getClientes = async (pageParam) => {
        var param = 5000;
        var pageA = pageParam ? pageParam : page ;
        setLoading(true)
        Api.req.get(`marketing/${param}/contacts?page=${pageA}&rows=50`)
            .then((response) => {
                setLoading(false)
                setRowCount(response.data.total)
                setClientes(response.data.data)
            })
    }

    useEffect(() => {
       getClientes()


    }, [page]);

    const limpiar = () => {
        setSearch('');
        getClientes(1)
    }

    const divideTags = (tags) => {
        if(tags){
            const myArray = tags.split(",");

            return myArray;
        }else{
            return [];
        }
        
    }

    const handleOpenPopper = (event,contactId) => {
        setCurrentContactId(contactId)
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClosePopper = () => {
        setAnchorEl(null);
    }


    function getMarketingOptIn(params) {
        return `${params.getValue(params.id, 'firstName') || ''} ${
          params.getValue(params.id, 'lastName') || ''
        }`;
      }

    const columns = [{
        headerName: 'Nombre',
        headerClassName: 'super-app-theme--header',
        field: 'name',
        headerAlign: 'center',
        flex: 1,
        renderCell: (params) => (<><Avatar style={{ marginRight: 10, }} >{params.value[0]}</Avatar> <Typography className={classes.blackText} variant="body1">{params.value}</Typography></>)
    },{
        field: 'callerid',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        width: 240,
    },{
        field: 'source',
        headerClassName: 'super-app-theme--header',
        headerName: 'Origen',
        headerAlign: 'center',
        width: 140,
    },{
        field: 'marketingOptIn',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (params) => (<Chip color={getChipColor(params.value)} label={ getLabelName(params.value) } />), 
        width: 140,
    }, {
        field: 'tags',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (params) => {
            return divideTags(params.value).map((value, index) => (<Chip key={index} color="primary" style={{ marginRight : '5px' }} label={value} />))
        }, 
        width: 140,
    },{
        field: 'createdAt',
        headerClassName: 'super-app-theme--header',
        headerName: 'Fecha',
        headerAlign: 'center',
        width: 140,
        valueFormatter: (params) => {
            return `${moment(params.value).format('DD/MM/YYYY')}`;
        },
    },{
        field: 'createdAt',
        headerClassName: 'super-app-theme--header',
        headerName: 'Fecha',
        headerAlign: 'center',
        width: 140,
        valueFormatter: (params) => {
            return `${moment(params.value).format('DD/MM/YYYY')}`;
        },
    }, {
        field: 'Acciones',
        headerClassName: 'super-app-theme--header',
        headerName: 'Acciones',
        headerAlign: 'center',
        width: 140,
        renderCell: (params) => (<div><IconButton onClick={(event) => {handleOpenPopper(event, params.row.contactid) } } size="small" variant="contained" color="secondary" ><DehazeIcon fontSize="small" /></IconButton></div>)
    }]

    return (
        <>
            <Popper style={{ backgroundColor : '#FFF' }} id="cliente-popper" open={openPopper} anchorEl={anchorEl}>
                <ClickAwayListener onClickAway={handleClosePopper}>
                    <MenuList autoFocusItem={openPopper} id="menu-list-grow">
                        <MenuItem onClick={ () => setContactoModalOpen(true) }>Agregar Grupo</MenuItem>
                    </MenuList>
                </ClickAwayListener>
               
            </Popper >
            <AgregarContactoModal onUpdate={refreshClientes} onClose={handleClose} isOpen={clienteModalOpen} />
            <AgregarContactoGrupoModal onUpdate={refreshClientes} contactId={currentContactId} onClose={handleCloseAgregarGrupo} isOpen={contactoModalOpen} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Box style={{ display: 'flex', flexDirection: 'row' }} >
                        <Button startIcon={<AddIcon />} variant="contained"  onClick={() => setClienteModalOpen(true)} style={{ float: 'left', marginRight: '10px' }} color="primary" > Agregar </Button>
                        <Button startIcon={<Refresh />} variant="contained" onClick={() => getClientes()} style={{ float: 'left', marginRight: '10px' }} color="yellow" > Refrescar </Button>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }} >

                        <TextField
                            onChange={(event) => setSearch(event.target.value)}
                            value={search}
                            style={{ float: 'left', width: '200px' }}
                            id="input-with-icon-adornment"
                            placeholder="Buscar Clientes"
                            variant="outlined"
                            size="small"

                        />
                        <Button variant="contained" size="small" onClick={() => buscar(search)} style={{ float: 'right', marginRight: '10px', marginLeft : '10px' }} color="primary" > Buscar </Button>
                        <Button variant="contained" size="small" onClick={() => limpiar()} style={{ float: 'right', marginRight: '10px', minWidth: '38px' }} color="secondary" > X </Button>
                    </Box>
                </Grid>
            </Grid>
            <div style={{ marginBottom : '20px', textAlign: 'right'}}>
            </div>
                <DataGrid className={classes.root} style={{backgroundColor: '#fff'}}
                    rows={clientes} 
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    rowCount={rowCount}
                    loading={loading}
                    getRowClassName={(params) =>
                        `super-app-theme--row`
                    }
                    pagination
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    {...clientes}
                />

        </>
    )
}

export default Clientes;