import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

const MeliIcon = ( props ) => {
  const classes = props.classes
  return (
    <SvgIcon viewBox="0 0 24 24" className={classes.IconSize} style={{backgroundColor: 'F5D402', borderRadius: 50}} >
            <path fill="#FFFFFF" d="M8.339,9.39c0,0.012-0.117,0.128-0.046,0.211c0.175,0.221,0.701,0.339,1.238,0.221
              c0.315-0.07,0.723-0.396,1.121-0.711c0.432-0.339,0.853-0.678,1.273-0.818c0.455-0.141,0.735-0.082,0.935-0.023
              c0.21,0.058,0.455,0.199,0.852,0.49c0.736,0.561,3.715,3.153,4.24,3.608c0.42-0.187,2.254-0.98,4.765-1.528
              c-0.222-1.333-1.027-2.56-2.266-3.552c-1.717,0.724-3.819,1.099-5.863,0.094c-0.011,0-1.12-0.526-2.218-0.501
              c-1.623,0.033-2.336,0.746-3.083,1.482L8.339,9.377V9.39z"/>
            <path fill="#FFFFFF" d="M17.822,12.66c-0.035-0.035-3.503-3.06-4.286-3.655c-0.456-0.339-0.712-0.433-0.969-0.467
              c-0.14-0.025-0.327,0.01-0.467,0.045c-0.362,0.105-0.841,0.422-1.272,0.76c-0.443,0.351-0.853,0.678-1.238,0.771
              c-0.49,0.105-1.086-0.022-1.367-0.211C8.106,9.834,8.036,9.74,8,9.658c-0.105-0.233,0.082-0.42,0.117-0.454l0.958-1.041
              c0.117-0.118,0.222-0.221,0.339-0.326C9.11,7.872,8.818,7.953,8.537,8.035C8.187,8.129,7.86,8.221,7.522,8.221
              c-0.141,0-0.899-0.128-1.04-0.163C5.618,7.824,4.859,7.591,3.726,7.066c-1.354,1.015-2.265,2.276-2.535,3.678
              c0.199,0.048,0.515,0.14,0.643,0.176c3.083,0.688,4.041,1.389,4.216,1.543c0.188-0.212,0.456-0.341,0.76-0.341
              c0.338,0,0.642,0.176,0.829,0.433c0.175-0.14,0.419-0.257,0.735-0.257c0.141,0,0.292,0.023,0.444,0.081
              c0.351,0.118,0.525,0.35,0.618,0.562c0.117-0.059,0.257-0.094,0.432-0.094c0.164,0,0.339,0.035,0.515,0.115
              c0.572,0.246,0.653,0.807,0.606,1.228c0.035,0,0.082-0.011,0.117-0.011c0.678,0,1.226,0.548,1.215,1.226
              c0,0.21-0.059,0.409-0.152,0.572c0.187,0.106,0.654,0.34,1.063,0.279c0.327-0.046,0.456-0.151,0.503-0.222
              c0.034-0.047,0.058-0.093,0.034-0.127l-0.863-0.971c0,0-0.141-0.141-0.094-0.186c0.047-0.058,0.14,0.022,0.2,0.07
              c0.443,0.372,0.979,0.922,0.979,0.922c0.013,0.013,0.048,0.081,0.247,0.116c0.174,0.035,0.479,0.012,0.688-0.164
              c0.059-0.045,0.105-0.093,0.152-0.15l-0.012,0.011c0.222-0.28-0.023-0.571-0.023-0.571l-1.017-1.145c0,0-0.14-0.129-0.093-0.187
              c0.047-0.047,0.14,0.022,0.198,0.08c0.327,0.27,0.771,0.726,1.215,1.146c0.082,0.058,0.468,0.305,0.97-0.034
              c0.303-0.199,0.373-0.445,0.361-0.632c-0.023-0.246-0.209-0.42-0.209-0.42l-1.391-1.39c0,0-0.152-0.129-0.094-0.188
              c0.047-0.058,0.141,0.023,0.197,0.071c0.445,0.373,1.637,1.459,1.637,1.459c0.012,0.013,0.432,0.304,0.934-0.023
              c0.188-0.116,0.305-0.291,0.305-0.502C18.068,12.869,17.822,12.66,17.822,12.66z"/>
            <path fill="#FFFFFF" d="M11.107,14.422c-0.21,0-0.456,0.13-0.479,0.105c-0.012-0.01,0.011-0.092,0.035-0.151
              c0.023-0.047,0.305-0.898-0.385-1.19c-0.526-0.224-0.853,0.022-0.958,0.141c-0.034,0.035-0.045,0.023-0.045-0.013
              c-0.012-0.152-0.083-0.572-0.538-0.712c-0.654-0.198-1.075,0.258-1.18,0.421c-0.046-0.374-0.362-0.655-0.747-0.655
              c-0.42,0-0.76,0.339-0.76,0.76c0,0.42,0.339,0.76,0.76,0.76c0.199,0,0.385-0.083,0.525-0.211c0,0,0.011,0.013,0,0.022
              c-0.035,0.188-0.093,0.864,0.619,1.146c0.28,0.104,0.525,0.021,0.724-0.118c0.059-0.046,0.07-0.023,0.059,0.034
              c-0.023,0.176,0.012,0.551,0.526,0.762c0.396,0.162,0.63,0,0.793-0.153c0.071-0.058,0.083-0.046,0.093,0.048
              c0.024,0.502,0.445,0.91,0.946,0.91c0.526,0,0.947-0.42,0.947-0.946c0-0.524-0.421-0.945-0.947-0.945L11.107,14.422z"/>
            <path fill="#352F90" d="M11.107,16.256c-0.479,0-0.864-0.372-0.875-0.839c0-0.036,0-0.153-0.093-0.153
              c-0.036,0-0.071,0.024-0.106,0.06c-0.105,0.094-0.233,0.199-0.432,0.199c-0.094,0-0.188-0.024-0.281-0.061
              c-0.501-0.198-0.514-0.548-0.49-0.688c0-0.035,0.012-0.07-0.024-0.104l-0.035-0.023H8.736c-0.023,0-0.046,0.012-0.082,0.035
              c-0.14,0.104-0.281,0.151-0.433,0.151c-0.082,0-0.164-0.011-0.234-0.047c-0.665-0.258-0.607-0.888-0.572-1.074
              c0-0.035,0-0.07-0.023-0.081l-0.047-0.036L7.3,13.641c-0.128,0.128-0.292,0.187-0.479,0.187c-0.375,0-0.69-0.302-0.69-0.689
              c0-0.373,0.304-0.688,0.69-0.688c0.338,0,0.63,0.257,0.677,0.596l0.024,0.188l0.105-0.152c0.011-0.022,0.292-0.444,0.793-0.432
              c0.094,0,0.199,0.012,0.304,0.048c0.41,0.127,0.479,0.489,0.491,0.641c0.012,0.095,0.071,0.095,0.082,0.095
              c0.035,0,0.058-0.024,0.082-0.048c0.082-0.082,0.245-0.21,0.502-0.21c0.117,0,0.245,0.022,0.373,0.082
              c0.643,0.279,0.351,1.099,0.351,1.108c-0.058,0.141-0.058,0.198,0,0.233l0.024,0.012h0.023c0.035,0,0.07-0.012,0.129-0.034
              c0.093-0.035,0.221-0.081,0.351-0.081l0,0c0.49,0,0.887,0.397,0.887,0.887c0,0.49-0.397,0.875-0.887,0.875H11.107z M17.963,12.298
              c-1.063-0.934-3.539-3.083-4.205-3.584c-0.385-0.294-0.642-0.445-0.875-0.504c-0.104-0.034-0.245-0.069-0.432-0.069
              c-0.175,0-0.351,0.035-0.549,0.093c-0.433,0.141-0.864,0.48-1.285,0.806L10.594,9.05c-0.386,0.305-0.794,0.632-1.098,0.701
              C9.367,9.787,9.227,9.8,9.087,9.8c-0.339,0-0.643-0.094-0.76-0.247C8.305,9.53,8.316,9.495,8.362,9.438l0.012-0.013l0.934-1.016
              c0.736-0.736,1.438-1.437,3.037-1.472c0.024,0,0.059,0,0.083,0c0.993,0,1.997,0.443,2.102,0.502
              c0.934,0.456,1.892,0.689,2.861,0.689c1.004,0,2.055-0.247,3.152-0.759c-0.116-0.106-0.244-0.199-0.385-0.304
              c-0.957,0.419-1.881,0.63-2.768,0.63c-0.899,0-1.811-0.221-2.686-0.643c-0.047-0.023-1.146-0.538-2.291-0.538
              c-0.035,0-0.058,0-0.093,0c-1.343,0.036-2.103,0.515-2.616,0.924C9.215,7.45,8.783,7.567,8.397,7.673
              c-0.339,0.094-0.63,0.175-0.922,0.175c-0.118,0-0.328-0.011-0.352-0.011C6.797,7.824,5.139,7.415,3.819,6.925
              c-0.14,0.094-0.268,0.199-0.385,0.291c1.378,0.562,3.048,1.005,3.574,1.041c0.151,0.012,0.303,0.022,0.455,0.022
              c0.351,0,0.701-0.093,1.04-0.187c0.198-0.058,0.42-0.115,0.653-0.163C9.099,7.987,9.028,8.045,8.97,8.116L8.012,9.145
              c-0.071,0.081-0.234,0.28-0.128,0.526C7.931,9.774,8.012,9.869,8.129,9.95c0.222,0.152,0.631,0.256,1.016,0.256
              c0.141,0,0.28-0.01,0.408-0.046c0.398-0.093,0.818-0.42,1.262-0.783c0.351-0.279,0.853-0.642,1.238-0.746
              c0.105-0.035,0.245-0.048,0.351-0.048c0.035,0,0.058,0,0.093,0c0.257,0.036,0.502,0.118,0.947,0.445
              c0.783,0.583,4.251,3.62,4.286,3.655c0,0,0.222,0.186,0.21,0.513c-0.012,0.175-0.105,0.339-0.281,0.444
              c-0.151,0.093-0.303,0.14-0.455,0.14c-0.233,0-0.396-0.104-0.396-0.115c-0.012-0.012-1.203-1.1-1.635-1.472
              c-0.07-0.059-0.141-0.105-0.211-0.105c-0.035,0-0.07,0.013-0.094,0.047c-0.069,0.081,0.012,0.197,0.094,0.28l1.391,1.389
              c0,0,0.175,0.163,0.186,0.375c0.012,0.233-0.105,0.419-0.326,0.572c-0.163,0.105-0.326,0.163-0.49,0.163
              c-0.211,0-0.363-0.093-0.398-0.118l-0.197-0.198c-0.362-0.361-0.736-0.724-1.018-0.957c-0.068-0.058-0.139-0.105-0.209-0.105
              c-0.035,0-0.059,0.012-0.082,0.035c-0.035,0.036-0.059,0.095,0.023,0.198c0.035,0.048,0.07,0.083,0.07,0.083l1.016,1.133
              c0.012,0.011,0.211,0.244,0.023,0.49l-0.035,0.048c-0.035,0.033-0.059,0.067-0.092,0.092c-0.176,0.142-0.41,0.151-0.492,0.151
              c-0.047,0-0.092,0-0.14-0.01c-0.104-0.024-0.164-0.048-0.199-0.082l-0.011-0.013c-0.059-0.059-0.561-0.583-0.993-0.935
              c-0.059-0.046-0.128-0.104-0.198-0.104c-0.036,0-0.071,0.011-0.094,0.047c-0.081,0.092,0.046,0.233,0.094,0.28l0.864,0.958
              c0,0.012-0.013,0.021-0.034,0.058c-0.036,0.048-0.141,0.152-0.444,0.188c-0.036,0-0.083,0.01-0.118,0.01
              c-0.326,0-0.665-0.149-0.84-0.243c0.082-0.176,0.128-0.363,0.128-0.55c0-0.712-0.572-1.284-1.284-1.284c-0.012,0-0.035,0-0.047,0
              c0.024-0.326-0.023-0.935-0.653-1.216c-0.187-0.081-0.362-0.115-0.538-0.115c-0.14,0-0.269,0.021-0.397,0.069
              c-0.128-0.258-0.351-0.444-0.631-0.536c-0.151-0.06-0.315-0.084-0.467-0.084c-0.256,0-0.502,0.084-0.725,0.233
              C7.393,12.181,7.09,12.03,6.75,12.03c-0.292,0-0.56,0.115-0.77,0.314c-0.269-0.21-1.332-0.887-4.193-1.53
              c-0.141-0.035-0.456-0.116-0.654-0.176c-0.035,0.153-0.058,0.316-0.069,0.468c0,0,0.524,0.129,0.629,0.152
              c2.92,0.641,3.878,1.319,4.042,1.447c-0.059,0.13-0.082,0.281-0.082,0.421c0,0.606,0.49,1.099,1.098,1.099
              c0.071,0,0.141-0.013,0.199-0.025c0.094,0.445,0.386,0.784,0.829,0.958c0.129,0.047,0.257,0.07,0.386,0.07
              c0.082,0,0.163-0.011,0.245-0.035c0.082,0.211,0.269,0.468,0.678,0.631c0.14,0.06,0.292,0.095,0.433,0.095
              c0.115,0,0.221-0.024,0.338-0.06c0.198,0.479,0.666,0.796,1.191,0.796c0.351,0,0.677-0.142,0.923-0.387
              c0.211,0.117,0.643,0.326,1.085,0.326c0.06,0,0.118,0,0.164-0.01c0.443-0.059,0.643-0.234,0.736-0.362
              c0.012-0.024,0.034-0.047,0.047-0.07c0.104,0.036,0.223,0.058,0.35,0.058c0.246,0,0.469-0.082,0.701-0.256
              c0.233-0.164,0.397-0.398,0.42-0.607v-0.013c0.082,0.013,0.152,0.025,0.234,0.025c0.244,0,0.49-0.084,0.723-0.236
              c0.445-0.291,0.527-0.677,0.527-0.934c0.081,0.011,0.163,0.023,0.232,0.023c0.234,0,0.455-0.07,0.678-0.21
              c0.281-0.176,0.443-0.456,0.468-0.771c0.023-0.21-0.036-0.434-0.152-0.62c0.747-0.325,2.476-0.957,4.497-1.411
              c-0.012-0.153-0.035-0.317-0.059-0.47c-2.453,0.551-4.275,1.333-4.742,1.543L17.963,12.298z"/>
            </SvgIcon>
  )
}

export default MeliIcon