import React,{ useState ,useEffect} from "react";
import ReactDOM from 'react-dom';
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from 'react-window';
import { useSelector } from 'react-redux';
import * as adminSelectors from '../../../state/admin/selectors';
import Api from '../../../state/Api';
import { Box , FormControl, InputLabel, InputAdornment , Input , ListItemSecondaryAction,  Checkbox , ListItemIcon , ListItem , ListItemText, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import './styles.css';



const ListadoAgentesRwindow = (props) => {
    const [searchFieldSeletected, setSearchFieldSelected] = useState('');
    const [searchFieldNotSelected, setSearchFieldNotSelected] = useState('');
    const usuariosSelectedList = useSelector(adminSelectors.getUsuariosSelected(props.agentes))
    const usuariosNotSelectedList = useSelector(adminSelectors.getUsuariosNotSelected(props.agentes))
    const filteredSelected = usuariosSelectedList.filter( usuario => usuario.Usuario.toLowerCase().indexOf(searchFieldSeletected.toLowerCase()) !== -1)
    const filteredNotSelected = usuariosNotSelectedList.filter( usuario => usuario.Usuario.toLowerCase().indexOf(searchFieldNotSelected.toLowerCase()) !== -1)
   
    const changeEstado = (agente) =>{
        //If para reutilizar component para admin y agentes
        if(props.admin){
          Api.req.put(`admin/campaign/${props.selectedCampaign.id}/admins/${agente.Usuario}`, {
            checked : !agente.selected
          })
          .then((response) => {
              props.onActualizoAgentes()
          })
        }else{
          Api.req.put(`admin/campaign/${props.selectedCampaign.id}/agents/${agente.Usuario}`, {
            checked : !agente.selected
          })
          .then((response) => {
              props.onActualizoAgentes()
          })
        }
    }

    const Row = ({ data, index, style }) => {
        return(
            <ListItem ContainerProps={{ style: style }} ContainerComponent="div" selected={data[index].selected} button  key={index} >
                <ListItemIcon>
                    <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        onClick={() => changeEstado(data[index])}
                        checked={data[index].selected}
                    />
                </ListItemIcon>
                <ListItemText id={'ListItemText'+index} primary={data[index].Display}  />
                <ListItemSecondaryAction>
                    <Input style={{ 'width' : '50px' }}
                      id="input-with-icon-adornment"
                    />
                </ListItemSecondaryAction>

            </ListItem>
        )
    };

    return(
        <>  
        <Grid container>
            <Grid style={{ overflow: 'auto', height: 'calc(100vh - 65px)' }} item xs={6}>
            <Box>
                    <FormControl style={{'marginLeft':'15px'}}>  
                        <InputLabel htmlFor="input-with-icon-adornment">Filtrar Contactos</InputLabel>
                        <Input
                        fullWidth = {true}
                        onChange={(event)=> setTimeout(setSearchFieldSelected(event.target.value),2000) }
                        id="input-with-icon-adornment"
                        startAdornment={
                            <InputAdornment position="start">
                            <SearchIcon />
                            </InputAdornment>
                        }
                        
                        />
                    </FormControl>
                </Box>
                <div style={{ 'height' : '80%' }}>   
                    <AutoSizer>
                        {({ height, width }) => (
                        <List
                            className="List"
                            height={height}
                            width={width}
                            itemCount={filteredSelected.length}
                            itemSize={61}
                            itemData={filteredSelected}
                            style={{'border' : 'none'}}
                        >
                            {Row}
                        </List>
                        )}
                    </AutoSizer>
                </div>
            </Grid>
            <Grid style={{ overflow: 'auto', height: 'calc(100vh - 65px)' }} item xs={6}>
                <Box>
                    <FormControl style={{'marginLeft':'15px'}}>  
                        <InputLabel htmlFor="input-with-icon-adornment">Filtrar Contactos</InputLabel>
                        <Input
                        fullWidth = {true}
                        onChange={(event)=> setTimeout(setSearchFieldNotSelected(event.target.value),2000) }
                        id="input-with-icon-adornment"
                        startAdornment={
                            <InputAdornment position="start">
                            <SearchIcon />
                            </InputAdornment>
                        }
                        
                        />
                    </FormControl>
                </Box>
                <div style={{ 'height' : '80%' }}>   
                    <AutoSizer>
                        {({ height, width }) => (
                        <List
                            className="List"
                            height={height}
                            width={width}
                            itemCount={filteredNotSelected.length}
                            itemSize={61}
                            itemData={filteredNotSelected}
                            style={{'border' : 'none'}}
                        >
                            {Row}
                        </List>
                        )}
                    </AutoSizer>
                </div>
            </Grid>
            
        </Grid>
            
        </>
    )

}

export default ListadoAgentesRwindow;