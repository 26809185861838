import React, { useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getCampaigns } from './../../../state/admin/selectors';
import Api from '../../../state/Api';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as contactsActions from '../../../state/contacts/actions';

const CampaignTabs = (props) => {
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const [campaigns, setCampaigns] = React.useState([{ campaignid: props.contact.campaignid, campaign: props.contact.campaignName, id: props.contact.id  }]);
    const handleChange = (event, newValue) => {

        if(newValue != 0){
            props.esTabPrincipal(false);
        }else{
            props.esTabPrincipal(true);
        }
        setValue(newValue);
        let contact = { ...props.contact } ;
        contact.campaignid = campaigns[newValue].campaignid;
        contact.contactId = contact.id;
        contact.id = campaigns[newValue].id;
        dispatch(contactsActions.fetchMessages(contact, props.username));
    };

    useEffect( () => {
  
        const getCampaigns = async() => {

            if (props.contact.Tipo_Docu && props.contact.Nro_Docu){
                const data = await Api.req.get('/prospectos/customer/' + props.contact.Tipo_Docu + '/' + props.contact.Nro_Docu + '/campaigns');
                let responseData = data.data.data;
                let filteredResponse = responseData.filter((item) => item.campaignid != props.contact.campaignid  )
                
                let newCampaingsList = campaigns.concat(filteredResponse);
                setCampaigns(newCampaingsList);
            }

        }

        getCampaigns()

        
    } , [ props.contact.id] )

    return(
        <Paper square>
            <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
            >
                {campaigns.map(campaign => (
                    <Tab label={campaign.campaign} />
                 )) }
            </Tabs>
        </Paper>

    )
}

export default CampaignTabs;