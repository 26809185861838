import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import { useOnMount } from '../../hooks';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as channelActions from '../../state/channels/actions';
import * as channelSelectors from '../../state/channels/selectors';
import * as authSelectors from '../../state/auth/selectors';
import IconShow from "../shared/icons";

import { Badge, Tooltip }   from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  IconSize: {
    fontSize: '2.3rem',
  }, 
  headerBadge: {
    // marginLeft: 0,
    // marginRight: 10
    // marginTop: -20
  },
}));

const CampaignStatus = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const channels = useSelector(channelSelectors.getChannels());
  // const channels = [{
  //   id: 1, Nombre: 'Whatsapp', shortcode: 'whatsapp', count: 0
  // }];

  
  useOnMount(() => {
    dispatch(channelActions.fetchChannels(me.username))
    .then(
      (response) => { 
        dispatch(channelActions.fetchChannelsResponses(response));
      } 
    )
  });

  useEffect(() => {

  }, [channels]);  

  const renderIcon = (source) => {
    return  <IconShow source={source} classes={classes}></IconShow>
  };  

  return (
    <>
      { channels.length > 0 ? ( 
      <div style={{ 'display' : 'inline' }}>
        { channels.map( (channel, index) => {
          return (<Tooltip title={channel.Nombre} key={'channel-'+channel.id}>
              <IconButton component="span" disabled={!channel.active} id={'icon-'+channel.id}>
                <Badge badgeContent={channel.pending} color="secondary" className={classes.headerBadge}>
                  {renderIcon(channel['mui-icon'])}
                </Badge>                  
              </IconButton>
            </Tooltip>
          )
        })}
      </div>
      ) : ( <div id={'channel-0'}></div> ) } 
    </>
  )
};

export default CampaignStatus;