import { Chart } from "react-google-charts";import React, { useState } from "react";
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TextField,Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Input,InputLabel,Select,MenuItem, Fab, Grid,Button, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Badge, CircularProgress, LinearProgress , Tooltip, CardContent, Typography } from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import {
  LocalizationProvider,
  DatePicker
} from '@material-ui/pickers';
import * as _ from 'lodash';
import {
  curveCatmullRom,
  area,
} from 'd3-shape';
import { scalePoint } from 'd3-scale';

import MomentUtils from '@date-io/moment';
import Api from '../../../state/Api';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import * as contactsActions from '../../../state/contacts/actions';
import * as conversationActions from '../../../state/conversation/actions';
import * as contactsSelector from '../../../state/contacts/selectors';
import * as campaignsSelector from '../../../state/channels/selectors';

import * as appActions from '../../../state/app/actions'; 
import * as authSelectors from '../../../state/auth/selectors';
import moment from 'moment';  

import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginRight: -drawerWidth
  },
  formControl: {
    margin: theme.spacing(1.5),
    minWidth: '90%',
  },
  cien: {
    margin: theme.spacing(1.5),
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  counter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
  },  
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize : '18px'
    },
  },
});

const AgentsActivityPeriodo = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const campaigns = useSelector(campaignsSelector.getChannels());
  //const chatsPublish = props.todas ? allChatsFiltered : allChats;
  const [fechaDesde, setFechaDesde] = useState(moment().utcOffset(0, true).format("YYYY-MM-DD"));
  const [fechaHasta, setFechaHasta] = useState(moment().utcOffset(0, true).format("YYYY-MM-DD"));
  const [selectedCampaign, setSelectedCampaign] = useState(2);
  const [selectedResult, setSelectedResult] = useState('');  
  const [resultados,setResultados] = useState([]);
  const [resultadosDashboard,setResultadosDashboard] = useState([]);
  const [resultadosHora,setResultadosHora] = useState([]);
  const [resultadosQueue,setResultadosQueue] = useState([]);
  // const { data: chartData } = resultadosHora;

  const visualizeDuration = (time) => {
    return moment.duration(time, "seconds").format("hh:mm", { trim: false });
  }

  const buscarPorFecha = () => {    
    Api.req.get(`/supervisor/stats/agents/report/logins?startDate=${moment(fechaDesde).utcOffset(0, true).format("YYYY-MM-DD")}&endDate=${moment(fechaHasta).utcOffset(0, true).format("YYYY-MM-DD")}`)
      .then((response) => { 

        setResultados(response.data.data)
      })
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell component="th" scope="row" style={{display: 'flex', flexDirection: 'row', alighContent: 'center', alignItems: 'center'}}>
            <Avatar style={{ marginRight: 10}}>{row.username.charAt(0)}</Avatar> <Typography variant="h6">{row.username}</Typography>
          </TableCell>
          <TableCell align="center">{row.evento}</TableCell>
          <TableCell align="center">{ moment(row.startDate).format("DD/MM HH:mm") } </TableCell>
          <TableCell align="center">{ moment(row.maxEndDate).format("DD/MM HH:mm") }</TableCell>
          <TableCell style={{ color : '#6fbe22'}} align="center">{ visualizeDuration(row.tiempoTotal) }</TableCell>
          <TableCell style={{ color : 'red'}} align="center">{ visualizeDuration(row.tiempoPausa) }</TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  

  return (
    <>
          <Grid container style={{marginRight: 10}}>
                <Grid item xs={3}>
                  <LocalizationProvider className={classes.cien}  dateAdapter={MomentAdapter}>
                    <DatePicker 
                        disableToolbar
                        renderInput={(props) => <TextField {...props} helperText=""/>}
                        variant="inline"
                        fullWidth={true}
                        size="small"
                        inputFormat="DD/MM/YYYY"
                        margin="normal"
                        id="date-picker-inline"
                        className={classes.cien}
                        label="Fecha Desde"
                        autoOk={true}
                        value={fechaDesde}
                        onChange={ date => setFechaDesde(date) }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                  </LocalizationProvider>
                </Grid>                      
                <Grid item xs={3} >
                    <LocalizationProvider className={classes.cien}  dateAdapter={MomentAdapter}>
                        <DatePicker 
                                disableToolbar
                                renderInput={(props) => <TextField {...props} helperText=""/>}
                                variant="inline"
                                fullWidth={true}
                                size="small"
                                inputFormat="DD/MM/YYYY"
                                margin="normal"
                                id="date-picker-inline"
                                className={classes.cien}
                                label="Fecha Hasta"
                                autoOk={true}
                                value={fechaHasta}
                                onChange={ date => setFechaHasta(date) }
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                        />
                    </LocalizationProvider>
                </Grid>                      
                <Grid item xs={3}>
                  <Button onClick={() => { buscarPorFecha(); } } 
                    fullWidth={true}
                    style={{ 'marginTop':'25px' }} 
                    size="small"
                    // disabled={!selectedCampaign || !selectedResult}
                    variant="contained" color="primary">
                    Buscar
                  </Button>
                </Grid>
              </Grid>

      <Grid container >
        <Grid item xs={12}>
          <div style={{margin: 10, marginRight: 10, height: '100px', position: 'relative'}}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Agente</TableCell>
                    <TableCell align="center">Evento</TableCell>
                    <TableCell align="center">Ingreso</TableCell>
                    <TableCell align="center">Salida</TableCell>
                    <TableCell align="center">Tiempo Transcurrido</TableCell>
                    <TableCell align="center">Pausa</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultados.map((row,index) => (
                    <Row key={index} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default AgentsActivityPeriodo