import {
  ERRORS_ADD,
  ERRORS_CLEAR,
  TOASTS_ADD,
  TOASTS_REMOVE,
  CHANGE_STATE,
  SOUNDS_ADD,
  SOUNDS_REMOVE,
  ENABLE_SOUND,
  DISABLE_SOUND,
  ENABLE_REFRESH_CONTACTS,
  DISABLE_REFRESH_CONTACTS,
  INIT_USER,
  CHANGE_USERSMENU_STATE,
  SET_ACCESS_TOKEN,
  ENABLE_DARK_MODE,
  DISABLE_DARK_MODE,
  CHANGE_CHATSMENU_STATE
} from './constants';

export const addError = error => dispatch => dispatch({ type: ERRORS_ADD, data: error });

export const clearErrors = () => dispatch => dispatch({ type: ERRORS_CLEAR });

let previousId = 0;

export const addToast = (toast,type) => dispatch => {
  let id = previousId ++;
  dispatch({ type: TOASTS_ADD, data: { id : id , toast : toast , type : type } })
} 

export const removeToast = id => dispatch => dispatch({ type: TOASTS_REMOVE, data : id });

let previousIdSound = 0;
export const addSound = (type) => dispatch => {
  let idSound = previousIdSound ++;
  dispatch({ type: SOUNDS_ADD, data: { id : idSound , type : type } })
} 

export const initUser = () => dispatch => {
  dispatch({type: INIT_USER})
} 

export const setAccesToken = (token) => dispatch => {
  dispatch({ type: SET_ACCESS_TOKEN, token : token })
} 

export const removeSound = id => dispatch => dispatch({ type: SOUNDS_REMOVE, data : id });

export const enableSound = id => dispatch => dispatch({ type: ENABLE_SOUND });

export const disableSound = id => dispatch => dispatch({ type: DISABLE_SOUND});


export const enableDarkMode = id => dispatch => dispatch({ type: ENABLE_DARK_MODE });

export const disableDarkMode = id => dispatch => dispatch({ type: DISABLE_DARK_MODE });

export const enableRefreshContacts = id => dispatch => dispatch({ type: ENABLE_REFRESH_CONTACTS });

export const disableRefreshContacts = id => dispatch => dispatch({ type: DISABLE_REFRESH_CONTACTS });

export const changeMenuState = () => dispatch => dispatch({ type: CHANGE_STATE });

export const changeUsersMenuState = () => dispatch => dispatch({ type: CHANGE_USERSMENU_STATE });

export const changeChatsMenuState = () => dispatch => dispatch({ type: CHANGE_CHATSMENU_STATE });


