import React, { useState, useEffect, forwardRef ,useImperativeHandle } from "react";
import moment from 'moment';
import 'moment/locale/es';
import { makeStyles, useTheme ,withStyles } from "@material-ui/core/styles";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as ListWindow } from 'react-window';

// import UserIcon from "@material-ui/icons/AccountCircle";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import ChatIcon from "@material-ui/icons/Forum";
import SMSIcon from "@material-ui/icons/Sms";
import AcceptIcon from "@material-ui/icons/Check";
import RejectIcon from '@material-ui/icons/Delete';
import PhoneIcon from '@material-ui/icons/Phone';
import MessengerIcon from "../../Header/MessengerIcon";
import Chip from '@material-ui/core/Chip';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import InstagramIcon from '@material-ui/icons/Instagram';

import { green } from '@material-ui/core/colors';
import Api from '../../../state/Api';
import {
  LocalizationProvider,
  DateTimePicker,
  DatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { TextField , Box,InputLabel,Select,MenuItem, Fab, Grid,Button, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Badge, CircularProgress, LinearProgress , Tooltip, CardContent, Typography } from "@material-ui/core";

import ContactsHeader from './ContactsHeader'
import ContactSkeleton from './ContactSkeleton'

import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import * as contactsActions from '../../../state/contacts/actions';
import * as conversationActions from '../../../state/conversation/actions';
import * as contactsSelector from '../../../state/contacts/selectors';
import * as campaignsSelector from '../../../state/channels/selectors';

import * as appActions from '../../../state/app/actions';
import * as authSelectors from '../../../state/auth/selectors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

moment.updateLocale('es', {
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s:  '1s',
    ss: '%ss',
    m:  '1m',
    mm: '%dm',
    h:  '1h',
    hh: '%dh',
    d:  '1d',
    dd: '%dd',
    M:  '1Mes',
    MM: '%dM',
    y:  '1año',
    yy: '%dY'
  }
});

const useStyles = makeStyles(theme => ({
  iconwhatsapp: {
    backgroundColor: '#25D366',
  },
  iconfacebook: {
    backgroundColor: '#3f51b5',
  },  
  iconinsta: {
    backgroundColor: '#eb6d31',
  },  
  iconsms: {
    backgroundColor: '#2196f3',
  },
  iconchat: {
    backgroundColor: '#f50057',
  },
  iconchatoffline: {
    backgroundColor: '#757575',
  },
  fabProgress: {
    color: '#ffcc00',
    position: 'absolute',
    top: -5,
    left: -5,
    zIndex: 1,
  },
  IconSize: {
    fontSize: '2.3rem'
  },
  contactname: {
    marginLeft: 10,
  },
  contactnameSecondaryText:{
    fontSize:'0.9em',
    fontWeight: 'lighter',
    paddingLeft: 10
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  heightAdjust: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 65px)",
    border: theme.palette.borders
  }    
}));

const StyledBadge2 = withStyles(theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,.5)',
      outline: '1px solid slategrey'
    }
  },
  badge: {
    backgroundColor: '#f50057',
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid #f60158',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.9)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.8)',
      opacity: 0.5,
    },
  },
}))(Badge);

const ContactListBusquedaSuper = forwardRef ( ( props, ref ) => {

  const classes = useStyles();
  
  const allChatsFiltered = useSelector(contactsSelector.getContacts());
  const campaigns = useSelector(campaignsSelector.getChannels());
  //const chatsPublish = props.todas ? allChatsFiltered : allChats;
  const [fechaDesde, setFechaDesde] = useState();
  const [fechaHasta, setFechaHasta] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState(0);
  const [selectedResult, setSelectedResult] = useState('');
  const [selectedAgente, setSelectedAgente] = useState('');    
  const [resultados,setResultados] = useState([]);
  const [agentes,setAgentes] = useState([]);
  const [callerId,setCallerId] = useState('');

  const setActiveContact = props.setActiveContact
  const removeActiveContact = props.removeActiveContact
  const me = useSelector(authSelectors.getMe());
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedContactIndex, setSelectedContactIndex] = useState(null);

  const [completed, setCompleted] = useState(0);


  const cambioCampaign = (campaignId) =>{
    Api.req.get(`/prospectos/campaigns/${campaignId}/resultcodes`)
    .then((response) => {
      setResultados(response.data.data)
    });

    Api.req.get(`/campaigns/${campaignId}/agents`)
    .then((response) => {
      setAgentes(response.data.data)
      setSelectedAgente('')
    });


  }
 
  const renderIcon = (source) => {
    switch(source) {
      case 'whatsapp':
      case 'whatsapp-sandbox':
        return <WhatsappIcon className={classes.IconSize} />
      case 'instagram': 
        return <InstagramIcon className={classes.IconSize}/>    
      case 'facebook':
        return <MessengerIcon classes={classes} />
      case 'facebook-comments':
        return <FacebookIcon className={classes.IconSize}  />
      case 'chat':
      case 'chat-offline':
        return <ChatIcon className={classes.IconSize} />
      case 'sms':
        return <SMSIcon className={classes.IconSize} />
      case 'phone':
        return <PhoneIcon className={classes.IconSize} />
      default:
        return <PhoneIcon className={classes.IconSize} />
    }
  };

  const renderStyle = (source) => {
    switch(source) {
      case 'whatsapp':
      case 'whatsapp-sandbox':
        return classes.iconwhatsapp;
      case 'facebook':
        return classes.iconfacebook;
        case 'instagram': 
        return classes.iconinsta;       
      case 'chat':
        return classes.iconchat;
      case 'chat-offline':
        return classes.iconchatoff;
      case 'sms':
        return classes.iconsms;
      default:
        return '';
    }
  };  

  const buscarPorFecha = () => {
    props.removeActiveContact();
    dispatch(contactsActions.fetchContactsFiltered(fechaDesde,fechaHasta,selectedCampaign,selectedResult, selectedAgente, callerId))
  }

  const buscar = () => {
    props.removeActiveContact();
    dispatch(contactsActions.fetchContactsSupervisorInbox(selectedAgente))
  }

  const handleSelectedContact = (e, contact,index) => {
    dispatch(contactsActions.fetchMessages(contact, me.username));
    dispatch(contactsActions.fetchClientData(contact));
    setSelectedIndex(contact.id);
    setActiveContact(e, contact);
    setSelectedContactIndex(index)
  }

  useImperativeHandle(  ref , () => ({
    refreshData
  }))

  const refreshData = (id) => {
    console.log('paso x refresh data')
    let activeConversation = allChatsFiltered.find((conversation) => conversation.id == selectedIndex)
    setActiveContact(false, activeConversation);
  }


  const handleRemoveSelectedContact = (e, contact) => {
    removeActiveContact();
  }  

  const handleSelectedResult = (param) => {
    setSelectedResult(param)
  }
  
  const checkDisabled = () => {
    if ( (!!selectedCampaign && selectedResult !== '') || (!!selectedCampaign && !!callerId ) ) {
      return false
    }else {
      return true
    }
  }

  useEffect( () => {
    props.removeActiveContact();
    dispatch(contactsActions.emptyContacts());
    setSelectedIndex(null)

    if(props.inbox){
      Api.req.get(`/supervisor/activeAgents`)
        .then((response) => {
          setAgentes(response.data.agents)
          setSelectedAgente('')
      });
    }else{
      setAgentes([])
      setSelectedAgente('')
    }
  } , [props.inbox] )

  const header = () => {
    return (
      <Grid container spacing={2} style={{'padding':'15px'}} >
            <Grid item xs={6}>
                  <InputLabel id="demo-customized-select-label">Campaña</InputLabel>
                  <Select
                    labelId="diposition-label"
                    id="diposition"
                    value={selectedCampaign}
                    onChange={(event) => { setSelectedCampaign(event.target.value); cambioCampaign(event.target.value); } }
                    fullWidth={true}
                  >
                  {
                    campaigns.length > 0 ? campaigns.map((option, index) => 
                    <MenuItem key={index} value={option.id}>{option.Nombre}</MenuItem>
                  ) : null }
                  <MenuItem value={0}>Seleccione</MenuItem>    
                  </Select>
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="demo-customized-select-label">Caller Id</InputLabel>
              <TextField
                fullWidth={true}
                placeholder="Ej. 5491148588000"
                value={callerId}
                onChange={(event) => { setCallerId(event.target.value) }}
              />

                
            </Grid>
            <Grid item xs={6} >
                <InputLabel id="demo-customized-select-label">Agente</InputLabel>
                <Select
                  labelId="diposition-label"
                  id="diposition"
                  fullWidth={true}
                  disabled={!!callerId}
                  value={selectedAgente}
                  onChange={(event) => setSelectedAgente(event.target.value) }
                >
                {
                agentes.length > 0 ? agentes.map((option, index) => 
                  <MenuItem key={index} value={option.Agent}>{option.Agent}</MenuItem>
                ) : null }
                <MenuItem value=''>Seleccione</MenuItem>    
                </Select>
            </Grid>
            <Grid item xs={6} >
                <InputLabel id="demo-customized-select-label">Resultado</InputLabel>
                <Select
                  labelId="diposition-label"
                  id="diposition"
                  fullWidth={true}
                  disabled={!!callerId}
                  value={selectedResult}
                  onChange={(event) => setSelectedResult(event.target.value)}
                >
                  {
                    resultados.length > 0 ? resultados.map((option, index) =>
                      <MenuItem key={index} value={option.codigo}>{option.descripcion}</MenuItem>
                    ) : null}
                  <MenuItem value=''>Seleccione</MenuItem>
                </Select>
            </Grid>    
            <Grid item xs={4}>
              <LocalizationProvider className={classes.cien}  dateAdapter={MomentAdapter}>
                              <DatePicker
                                  disableToolbar
                                  renderInput={(props) => <TextField {...props} helperText=""/>}
                                  variant="inline"
                                  fullWidth={true}
                                  size="small"
                                  disabled={!!callerId}
                                  inputFormat="DD/MM/YYYY"
                                  margin="normal"
                                  id="date-picker-inline"
                                  className={classes.cien}
                                  label="Fecha Desde"
                                  autoOk={true}
                                  value={fechaDesde}
                                  onChange={ date => setFechaDesde(date) }
                                  KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                  }}
                              />
              </LocalizationProvider>
            </Grid>
                            
            <Grid item xs={4}>
            <LocalizationProvider className={classes.cien}  dateAdapter={MomentAdapter}>
                              <DatePicker
                                  disableToolbar
                                  renderInput={(props) => <TextField {...props} helperText=""/>}
                                  variant="inline"
                                  inputFormat="DD/MM/YYYY"
                                  margin="normal"
                                  size="small"
                                  disabled={!!callerId}
                                  id="date-picker-inline2"
                                  className={classes.cien}
                                  label="Fecha Hasta"
                                  fullWidth={true}
                                  autoOk={true}
                                  value={fechaHasta}
                                  onChange={ date => setFechaHasta(date) }
                                  KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                  }}
                              />
              </LocalizationProvider>
          </Grid>                      
          <Grid item xs={4}>
            <Button onClick={() => buscarPorFecha()} 
              fullWidth={true}
              style={{ 'marginTop':'25px' }} 
              size="small"
              disabled={checkDisabled()}
              variant="contained" color="primary">
              Buscar
            </Button>
          </Grid>
        </Grid>  )
  }

  const headerInbox = () => {
    return (
      <Grid container spacing={2} style={{ 'padding': '15px' }} >
        <Grid item xs={6}>
          <InputLabel id="demo-customized-select-label">Agente</InputLabel>
          <Select
            labelId="diposition-label"
            id="diposition"
            fullWidth={true}
            value={selectedAgente}
            onChange={(event) => setSelectedAgente(event.target.value)}
          >
            {
              agentes && agentes.length > 0 ? agentes.map((option, index) =>
                <MenuItem key={index} value={option.usuario}>{option.usuario}</MenuItem>
              ) : null}
            <MenuItem value=''>Seleccione</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => buscar()}
            fullWidth={true}
            style={{ 'marginTop': '15px' }}
            size="small"
            disabled={selectedAgente == ''}
            variant="contained" color="primary">
            Buscar
          </Button>


        </Grid>
      </Grid>
    )
  }

  const getSecondaryText = (data,index) => {
      return (
        <>
          <Chip component="span"  size="small" label={data[index].agentid} /> 
          <Chip component="span"  size="small" color="primary" label={data[index].statusDescription} /> 
        </>
      )
  }

  const getPrimaryText = (name, surname, profile_id, isStaff) => {
    if (name && surname) {
      return (<span>{name + ' ' + surname} {getCheckIcon(isStaff)} </span>)
    } else if (name) {
      return (<span>{name} {getCheckIcon(isStaff)} </span>);
    } {
      return (<span>{profile_id} {getCheckIcon(isStaff)} </span>);
    }

  }

  const getCheckIcon = (boolean) => {
    if (boolean) {
      return <CheckCircleIcon color="primary" style={{ verticalAlign: 'bottom' }} />
    } else {
      return ''
    }

  }

  const Row = ({ data, index, style }) => {
      return(    
        <ListItem onClick={e => handleSelectedContact(e, data[index],index) } selected={selectedIndex === data[index].id} ContainerProps={{ style: style }}   button={data[index].status === 0 ? false : true }   key={data[index].i} style={{paddingLeft: 5, paddingRight: 0, height: 73}}  ContainerComponent="div" >
            
        <Tooltip title={data[index].source.toUpperCase()}><Avatar style={{borderRadius: 0, width: 60, height: 60, color: 'white' }} className={renderStyle(data[index].source)}>{renderIcon(data[index].source)}</Avatar></Tooltip>
           { data[index].status === 0 && <CircularProgress size={50} className={classes.fabProgress} variant="static" value={completed}  /> }
        <ListItemText 
            primary={getPrimaryText(data[index].name, data[index].surname, data[index].profile_id, data[index].isStaff)}
           secondary={data[index].lastMsgTime && (<span><span>{moment(data[index].lastMsgTime).fromNow()}</span><span style={{color:'#aaa', fontSize: '18px'}}> | </span> <span>{getSecondaryText(data,index)}</span></span>) } 
           classes={{ primary: classes.contactname, secondary: classes.contactnameSecondaryText }}/>
           <Divider absolute />
           <ListItemSecondaryAction style={{ marginRight: 10}}>
              <StyledBadge2 badgeContent={data[index].unread} color="secondary"></StyledBadge2>
          </ListItemSecondaryAction>
   </ListItem>
      )
  };

  return (
    <div style={{'display' : 'flex','flexDirection':'column','height':'100%'}}>
      {props.inbox ? headerInbox() : header()}
      <div style={{'flex' : '1'}}>
        { allChatsFiltered.length > 0 ? (
            <AutoSizer>
              {({ height, width }) => (
              <ListWindow
                  className="List"
                  height={height}
                  width={width}
                  itemCount={allChatsFiltered.length}
                  itemSize={73}
                  itemData={allChatsFiltered}
                  style={{'border' : 'none', 'overflowX' : 'hidden', scrollbarColor : 'red'}}
              >
                  {Row}
              </ListWindow>
              )}
            </AutoSizer>
        ) : (
          <ContactSkeleton classes={classes} />
        )}
      </div>

  </div>
  );

})

export default ContactListBusquedaSuper;