import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Avatar, Collapse, Typography, Box } from '@material-ui/core';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
            fontSize: '18px'
        },
    },
});


const RegistroRow = (props) => {
    const { row } = props;
    const [open, setOpen] = React.useState(true);
    const classes = useRowStyles();

    console.log(row)

    const visualizeDuration = (time) => {
        return moment.duration(time, "seconds").format("hh:mm:ss", { trim: false });
    }

    const totalTime = row.fechas.reduce(
        (accumulator, currentValue) => accumulator + currentValue.elapsed,
        0
    );


    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" style={{ display: 'flex', flexDirection: 'row', alighContent: 'center', alignItems: 'center' }}>
                    <Avatar style={{ marginRight: 10 }}>{row.estado.charAt(0)}</Avatar> <Typography variant="h6">{row.estado}</Typography>
                </TableCell>
                <TableCell align="left">{ visualizeDuration(totalTime) }</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Registros
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell>Duración</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.fechas.map((historyRow, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {moment(historyRow.fecha).format('DD/MM/YYYY') }
                                            </TableCell>
                                            <TableCell >{visualizeDuration(historyRow.elapsed)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default RegistroRow;