import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch , useSelector } from 'react-redux';
import * as appActions from '../../state/app/actions';
import * as appSelector from './../../state/app/selectors';
//import {msgSound} from '/monopop.mp3';
//import {contactSound} from '/zapsplat.mp3';

const SECONDS_TO_DISMISS = 2;

const AudioPlayer = ({ index, id, type = 'incomingMsg' }) => {
  const dispatch = useDispatch();
  const clear = () => dispatch(appActions.removeSound(id));
  const estadoSonido = useSelector(appSelector.getEstadoSonido());
  useEffect(()=> {
    if(type == 'incomingMsg' && estadoSonido){
      let audio = new Audio("https://omni.tevecompras.com/assets/audio/monopop.mp3")
      audio.play()
    }
    if(type == 'addContact' && estadoSonido){
      let audio = new Audio("https://omni.tevecompras.com/assets/audio/zapsplat.mp3")
      audio.play()
    }
    const timer = setTimeout(clear, SECONDS_TO_DISMISS * 1000);
    return () => clearTimeout(timer);
  })

  return (
    null
  );
};

export default AudioPlayer;
