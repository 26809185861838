import Api from '../Api';
import decode from "jwt-decode";
import * as authSelectors from '../../state/auth/selectors';
import * as userActions from '../../state/users/actions';

import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
} from './constants';

export const login = (user, pass, authCode) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN, auto: false });
    const username = Buffer.from(user).toString('base64')
    const password = Buffer.from(pass).toString('base64')
    const params = authCode ? { username, password, authCode } : { username, password };
    const { data } = await Api.req.post('/auth/login', params);
    // console.log(data);
    if(data.authenticated) {
      const decoded = decode(data.token);
      Api.setJWT(data.token);
      localStorage.setItem('jwt', data.token);
      localStorage.setItem('username', decoded.username);
      localStorage.setItem('displayname', decoded.username);
      // localStorage.setItem('isAdmin', decoded.isAdmin);
      dispatch({ type: LOGIN_SUCCESS, data: decoded });
      dispatch(userActions.setPausaLogin())
    } else {
      dispatch({ type: LOGIN_FAIL, error: data.error });
    }

    return data;
  } catch (err) {
    console.log(err)
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }

    dispatch(logout());
    dispatch({ type: LOGIN_FAIL, error });
    throw err;
  }
};

export const jwtLogin = jwt => async (dispatch) => {
  try {
    dispatch({ type: LOGIN, auto: true });
    Api.setJWT(jwt);
    // console.log(jwt)
    const decoded = decode(jwt);
    // localStorage.setItem('jwt', token);
    localStorage.setItem('username', decoded.username);
    localStorage.setItem('displayname', decoded.username);
    // const { data } = await Api.req.get('/auth/me');
    // const data = await dispatch(parseRawData(rawData));

    dispatch({ type: LOGIN_SUCCESS, data: decoded });

  } catch (error) {
    dispatch({ type: LOGOUT });
    dispatch({ type: LOGIN_FAIL, error });
  }
};


export const logout = (username) => async (dispatch) => {
  
  const { username } = window.localStorage;
    try {
      const { data } = await Api.req.post('/auth/logout', { username });

    } catch (err) {

    }

  Api.setJWT('');

  localStorage.removeItem('jwt');
  if (username !== null) {
    localStorage.removeItem('username');
  }

  dispatch({ type: LOGOUT });

};