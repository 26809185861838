import styled from 'styled-components';

const Bubble = styled.span`
  margin-left: ${props => (props.outgoing ? '3px' : '3px')};
  margin-right: ${props => (props.outgoing ? '6px' : '3px')};
  margin-top: 0;
  margin-bottom: 0;
  padding: 3px 9px;
  min-width: ${props => (props.isSecret ? '100%' : 'initial')};
  background-color: ${props => (props.outgoing ? props.isSecret ? '#edf8c6' : '#dcf8c6' : '#fff')};
  border-radius: ${props => (props.outgoing ?  props.isSecret ? '0 0 0 0' : '9px 9px 0 9px' : '9px 9px 9px 0')};
  opacity: 1;
  box-shadow: 0 1px 0.5px 0.5px rgba(0,0,0,.13);
`;

export default Bubble;
