/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';

const useOnBottomScroll = (cb, { node, offset = 0 }, update) => useEffect(() => {
  const handleOnScroll = () => {
    if (node.scrollHeight - offset <= node.scrollTop + node.clientHeight) cb();
  };

  if (node) node.addEventListener('scroll', handleOnScroll);

  return () => {
    if (node) return node.removeEventListener('scroll', handleOnScroll);
    return null;
  };
}, update);

export default useOnBottomScroll;
