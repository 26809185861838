import { Dialog, Card, Box , CardMedia, Typography, CardContent, CardActionArea , TextField, Grid, InputLabel, Input, DialogTitle, DialogContent, Button, Select, MenuItem } from "@material-ui/core";
import React, { useEffect } from "react";
import LaunchIcon from '@material-ui/icons/Launch';
import Api from "../../../state/Api";
import { addToast } from "../../../state/app/actions";
import { useSelector, useDispatch } from 'react-redux';
import {
    LocalizationProvider,
    DateTimePicker,
    DatePicker
} from '@material-ui/pickers';
import moment from "moment";

import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { getCampaigns } from './../../../state/admin/selectors';
import * as appActions from '../../../state/app/actions';

function AgregarCampañaModal(props) {
    const dispatch = useDispatch();
    const [name, setName] = React.useState('');
    const [descripcion, setDescripcion] = React.useState('');
    const [templateId, setTemplateId] = React.useState('');
    const [currentPlantilla, setCurrentPlantilla] = React.useState(null);
    const [startDate, setStartDate] = React.useState(moment());
    const [endDate, setEndDate] = React.useState(moment().endOf('month'));
    const [templates, setTemplates] = React.useState([]);
    const [grupos, setGrupos] = React.useState([]);
    const [selectedGrupo, setSelectedGrupo] = React.useState('');
    const [campaigns, setCampaigns] = React.useState([]);
    const [currentPaso, setCurrentPaso] = React.useState(1);
    const [selectedCampaign, setSelectedCampaign] = React.useState(null);

    const [subcampaignid, setSubcampaignId ] = React.useState('')

    const handleClose = () => {
        props.onClose()
    }

    const getDisabled = () => {

        if (name && descripcion && templateId && startDate && endDate && selectedCampaign) {
            return false
        } else {
            return true
        }
    }

    const getTemplates = async () => {
        let param = 37

        Api.req.get(`marketing/${param}/templates`)
            .then((response) => {
                setTemplates(response.data.data)
            })
    }

    const getCampaigns = async () => {
        let param = 37

        Api.req.get(`marketing/campaigns`)
            .then((response) => {
                setCampaigns(response.data.campaigns)
            })
    }

    const getGrupos = async (selectedCampaign) => {
        let param = 37

        Api.req.get(`marketing/${selectedCampaign}/groups`)
            .then((response) => {
                setGrupos(response.data.data)
            })
    }

    useEffect(
        ()=> {
            if(props.isOpen){
                setCurrentPaso(1)
                getTemplates()
                getCampaigns()
                setName('');
                setDescripcion('');
                setTemplateId('');
                setCurrentPlantilla(null);
                setStartDate(moment());
                setEndDate(moment().endOf('month'));
                setSelectedCampaign('')
                setSelectedGrupo('');
                setSelectedCampaign(null);

            }

        }
    , [props.isOpen])

    const guardar = async () => {
        let datos = {
            name: name,
            descripcion: descripcion,
            templateId: templateId,
            startdate: moment(startDate).format('YYYY-MM-DD'),
            enddate: moment(endDate).format('YYYY-MM-DD')
        }
        try {
            const guardar = await Api.req.post(`marketing/${selectedCampaign}/subcampaigns`, datos);
            dispatch(addToast('Se ha guardado la campaña'))
            setSubcampaignId(guardar.data.data[0].id)
            getGrupos(selectedCampaign)
            setCurrentPaso(2)
        } catch (error) {
            dispatch(addToast('Se han guardado los cambios', 'error'))
        }


    }

    const handleGuardarGrupo = () => {

        let data = {
            "campaignid": selectedCampaign,
            "subcampaignid": subcampaignid,
            "templateid": templateId
        }

        try {
            Api.req.post(`marketing/${selectedCampaign}/subcampaigns/${name}/contacts/${selectedGrupo}`, data)
                .then((response) => {
                    dispatch(appActions.addToast('Se han guardado los cambios'))
                   // props.onSave()
                    props.onUpdate()
                })
        } catch (error) {
            dispatch(appActions.addError('Ha habido un error al agregar el grupo'))
            console.log(error)
        }

    }

    const siguiente = () => {
        getGrupos()
        setCurrentPaso(2)
    }

    const getDisabledGrupo = () => {
        if(selectedGrupo != ''){
            return false;
        }   
        return true;
    }

    return (
        <Dialog fullWidth={true} maxWidth='lg' disableEnforceFocus onClose={handleClose} open={props.isOpen}>
            <DialogTitle>Generar Campaña</DialogTitle>
            <DialogContent>

                <Grid container spacing={5} style={{ minHeight : '300px'}} >
                    <Grid item xs={6}>
                    { currentPaso == 1 ?
                        <Grid container spacing={2} >
                            <Grid item xs={12}>
                                <InputLabel htmlFor="descripcion">Campaña</InputLabel>
                                <Select
                                    fullWidth={true}
                                    value={selectedCampaign}
                                    onChange={(event) => {setSelectedCampaign(event.target.value) } }>
                                    {campaigns && campaigns.map((option, index) => (
                                        <MenuItem key={index} value={option.campaignid}>
                                            { '(' + option.phonenumber + ') ' + option.descripcion }
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="descripcion">Nombres</InputLabel>
                                <Input
                                    onChange={(event) => setName(event.target.value)}
                                    value={name}

                                    id="descripcion"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="descripcion">Descripción</InputLabel>
                                <Input
                                    onChange={(event) => setDescripcion(event.target.value)}
                                    value={descripcion}
                                    multiline
                                    rows={3}
                                    id="descripcion"
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel htmlFor="descripcion">Template Id</InputLabel>
                                <Select
                                    fullWidth={true}
                                    value={templateId}
                                    onChange={(event) => { setTemplateId(event.target.value); setCurrentPlantilla(templates.find((template) => template.id == event.target.value))  } }>
                                    {templates && templates.map((option, index) => (
                                        <MenuItem key={index} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={MomentAdapter} >
                                    <DatePicker
                                        disableToolbar
                                        renderInput={(props) => <TextField {...props} helperText="" />}
                                        variant="inline"
                                        fullWidth={true}
                                        size="small"
                                        inputFormat="DD/MM/YYYY"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Fecha Desde"
                                        autoOk={true}
                                        value={startDate}
                                        onChange={date => setStartDate(date)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </LocalizationProvider>

                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={MomentAdapter} >
                                    <DatePicker
                                        disableToolbar
                                        renderInput={(props) => <TextField {...props} helperText="" />}
                                        variant="inline"
                                        fullWidth={true}
                                        size="small"
                                        inputFormat="DD/MM/YYYY"
                                        margin="normal"
                                        id="date-picker-inline"
                                        label="Fecha Hasta"
                                        autoOk={true}
                                        value={endDate}
                                        onChange={date => setEndDate(date)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                      
                        </Grid>

                    : '' }

                        {currentPaso == 2 ?   
                            <Grid container spacing={2} >
                  
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="descripcion">Grupo</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        value={selectedGrupo}
                                        onChange={(event) => {setSelectedGrupo(event.target.value) } }>
                                        {grupos && grupos.map((option, index) => (
                                            <MenuItem key={index} value={option.id}>
                                                { option.description  }
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid> : '' }
                    </Grid> 

                    {currentPaso == 1 ?
                        <Grid spacing={4} item xs={6} style={{ background: "url(./assets/whatsapBackground.png) center center #e5ddd5"  }} >
                            <Card style={{ maxWidth: 400, margin: '0 auto', marginTop: '10px', padding: '15px', background: "#FFFFFF", paddingLeft: '20px' }}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        alt="Contemplative Reptile"
                                        height="140"
                                        image="/assets/whatsapTemplateImage.png"
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography style={{ color: '#000000', whiteSpace: 'pre-line' }} variant="body2" color="textPrimary" component="p">
                                            {currentPlantilla ? currentPlantilla.body : ''}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <Box>
                                    {currentPlantilla && currentPlantilla.buttons.map((button, index) => (

                                        <Button fullWidth key={index} size="small" variant="outlined" style={{ marginBottom: '10px' }} startIcon={button.paramType == 'link' ? <LaunchIcon /> : ''} color="primary">
                                            {button.paramName}
                                        </Button>

                                    ))}
                                </Box>
                            </Card> 
                        </Grid> 
                    : ''}
                </Grid>
                <Grid container style={{ marginTop : '20px' }} >
                    <Grid item xs={6}>
                        <Button style={{ marginRight : '20px' }} variant="contained" color="secondary" onClick={handleClose}>Cerrar</Button>
                        {currentPaso == 1 ? <Button disabled={getDisabled()} variant="contained" style={{ float: 'right', marginRight: '10px' }} color="primary" onClick={guardar}>Siguiente</Button> : ''}
                        {currentPaso == 2 ? <Button disabled={getDisabledGrupo()} style={{ float: 'right', marginRight: '10px' }} variant="contained" color="primary" onClick={handleGuardarGrupo}>Finalizar</Button> : ''}

                    </Grid>
                </Grid>
              

            </DialogContent>

        </Dialog>
    )
}

export default AgregarCampañaModal;