import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Typography, Paper, Chip, Avatar } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    counter: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
        },
    },
}));

function createData(name, status, calories, fat, carbs, protein) {
    return { name, status, calories, fat, carbs, protein };
}

const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

const renderOnlineBadge = (ispaused) => {
    if (ispaused) {
        return (
            <>
                <Chip label="En Pausa" style={{ backgroundColor: 'red' }} />
            </>
        )
    }
    if (ispaused == null) {
        return (
            <>
                <Chip label="Offline" style={{ backgroundColor: '#ccc', color: '#000' }} />
            </>
        )
    }
    if (!ispaused) {
        return (
            <>
                <Chip label="Online" style={{ backgroundColor: 'rgb(76, 175, 80)' }} />
            </>
        )
    }
}

const ContactosTable = (props) => {
    const classes = useStyles();



    return (
        <>
            <div style={{ margin: 10, marginTop: 5 }}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Nombre </TableCell>                               
                                <TableCell align="center">Contactos Asignados</TableCell>
                                <TableCell align="center">Contactos Finalizados</TableCell>
                                <TableCell align="center">Contactos Libres</TableCell>
                                <TableCell align="center">Contactos Totales</TableCell>
                                <TableCell align="center">Operadores</TableCell>
                                <TableCell align="center">Pendientes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.contactos.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="center">{row.nombre}</TableCell>
                                    <TableCell align="center">{row.contactosAsignados}</TableCell>
                                    <TableCell align="center">{row.contactosFinalizados}</TableCell>
                                    <TableCell align="center">{row.contactosLibres}</TableCell>
                                    <TableCell align="center">{row.contactosTotales}</TableCell>
                                    <TableCell align="center">{row.operadores}</TableCell>
                                    <TableCell align="center">{row.pendientes}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

export default ContactosTable