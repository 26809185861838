export const FETCH_CHANNELS_SUCCESS = 'channels/FETCH_CHANNELS_SUCCESS';
export const FETCH_CHANNELS_FAILURE = 'channels/FETCH_CHANNELS_FAILURE';

export const ADD_CHANNEL_SUCCESS = 'channels/ADD_CHANNEL_SUCCESS';

export const FETCH_CHANNELS_TEXT_RESPONSES_REQUEST = 'channels/FETCH_CHANNELS_TEXT_RESPONSES_REQUEST';
export const FETCH_CHANNELS_TEXT_RESPONSES_SUCCESS = 'channels/FETCH_CHANNELS_TEXT_RESPONSES_SUCCESS';
export const FETCH_CHANNELS_TEXT_RESPONSES_FAILURE = 'channels/FETCH_CHANNELS_TEXT_RESPONSES_FAILURE';

export const FETCH_CHANNELS_MEDIA_RESPONSES_REQUEST = 'channels/FETCH_CHANNELS_MEDIA_RESPONSES_REQUEST';
export const FETCH_CHANNELS_MEDIA_RESPONSES_SUCCESS = 'channels/FETCH_CHANNELS_MEDIA_RESPONSES_SUCCESS';
export const FETCH_CHANNELS_MEDIA_RESPONSES_FAILURE = 'channels/FETCH_CHANNELS_MEDIA_RESPONSES_FAILURE';

export const FETCH_CHANNEL_MEDIA_RESPONSES_REQUEST = 'channels/FETCH_CHANNEL_MEDIA_RESPONSES_REQUEST';
export const FETCH_CHANNEL_MEDIA_RESPONSES_SUCCESS = 'channels/FETCH_CHANNEL_MEDIA_RESPONSES_SUCCESS';
export const FETCH_CHANNEL_MEDIA_RESPONSES_FAILURE = 'channels/FETCH_CHANNEL_MEDIA_RESPONSES_FAILURE';

export const EDIT_TEXT_RESPONSE = 'channels/EDIT_TEXT_RESPONSE';
export const ADD_TEXT_RESPONSE = 'channels/ADD_TEXT_RESPONSE';

export const ADD_IMAGE_RESPONSE = 'channels/ADD_IMAGE_RESPONSE';