import { Dialog, Grid, InputLabel, Select, MenuItem ,  Input, DialogTitle, DialogContent, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import Api from "../../../state/Api";
import { addToast } from "../../../state/app/actions";
import { useSelector, useDispatch } from 'react-redux';


function AgregarContactoGrupoModal(props) {
    const dispatch = useDispatch();
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [grupos, setGrupos] = React.useState([]);
    const [selectedGrupo, setSelectedGrupo] = React.useState('0');
    const idProspecto = props.idProspecto;

    const handleClose = () => {
        props.onClose()
    }


    const getDisabled = () => {
        if (name && description) {
            return false
        } else {
            return true
        }
    }



    const guardar = async ( groupId) => {
        const contactId = props.contactId;
        let datos = {
            name: name,
            description: description
        }
        try {
            const guardar = await Api.req.post(`marketing/5000/contact/${contactId}/group/${selectedGrupo}`);
            dispatch(addToast('Se ha guardado el grupo'))
            props.onClose()
        } catch (error) {
            dispatch(addToast('Se han guardado los cambios', 'error'))
        }


    }

    const getGrupos = async () => {
        var param = 37;
        Api.req.get(`marketing/${param}/groups`)
        .then((response) => {
            setGrupos(response.data.data)
        })

    }

    useEffect( () => {
    
        if(props.isOpen){
            getGrupos()
            setSelectedGrupo('0')
        }
    }, [props.isOpen] )

    return (
        <Dialog onClose={handleClose} open={props.isOpen}>
            <DialogTitle>Agregar Grupo</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <InputLabel id="tipo">Grupo</InputLabel>
                        <Select
                            id="tipo"
                            value={selectedGrupo}
                            onChange={(event) => setSelectedGrupo(event.target.value)}
                        >
                            <MenuItem  value='0'>
                                Seleccione
                            </MenuItem>
                            {grupos.map((option, index) => (
                                <MenuItem key={index} value={option.id}>
                                    {option.description}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <Button disabled={ selectedGrupo == '0' }  style={{ float: 'right' }} variant="contained" color="primary" onClick={guardar}>Agregar</Button>
                        <Button onClick={props.onClose} style={{ float: 'left' }} variant="contained" color="secondary" >Cancelar</Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}

export default AgregarContactoGrupoModal;