import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid,  CardContent, Box, Typography } from "@material-ui/core";

const ContactSkeleton = ( props ) => {

  const classes = props.classes

  return (
  <Grid item className={classes.heightAdjust} xs={12} style={{flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <CardContent style={{ flexDirection: 'column', justifyContent:'space-between', color: '#888', width: 300 }} className={classes.EmptyF}>
        {/* <PortraitIcon style={{ fontSize: '5em'}} /> */}
        <Box style={{ marginBottom: 40, flex: 1, display:'flex', flexDirection: 'column', width: '100%' }}>
          <Box pt={1} style={{display: 'flex', width: '100%', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <Skeleton variant="rect" width={40} height={40} style={{marginRight: 10}} />
            <Box pt={1} style={{flex: 1, display: 'flex', width: '100%', flexDirection: 'column'}}>
              <Skeleton width="30%" />
              <Skeleton width="60%" />
            </Box>
          </Box>            
          <Box pt={1} style={{display: 'flex', width: '100%', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <Skeleton variant="rect" width={40} height={40} style={{marginRight: 10}} />
            <Box pt={1} style={{flex: 1, display: 'flex', width: '100%', flexDirection: 'column'}}>
              <Skeleton width="30%" />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box pt={1} style={{display: 'flex', width: '100%', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <Skeleton variant="rect" width={40} height={40} style={{marginRight: 10}} />
            <Box pt={1} style={{flex: 1, display: 'flex', width: '100%', flexDirection: 'column'}}>
              <Skeleton width="30%" />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box pt={1} style={{display: 'flex', width: '100%', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <Skeleton variant="rect" width={40} height={40} style={{marginRight: 10}} />
            <Box pt={1} style={{flex: 1, display: 'flex', width: '100%', flexDirection: 'column'}}>
              <Skeleton width="30%" />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box pt={1} style={{display: 'flex', width: '100%', flex: 1, flexDirection: 'row', alignItems: 'center'}}>
            <Skeleton variant="rect" width={40} height={40} style={{marginRight: 10}} />
            <Box pt={1} style={{flex: 1, display: 'flex', width: '100%', flexDirection: 'column'}}>
              <Skeleton width="30%" />
              <Skeleton width="60%" />
            </Box>
          </Box>
        </Box>            
        <Typography variant="h5"> 
          Esperando Contactos
        </Typography>
      </CardContent>
    </Grid>
  )};

  export default ContactSkeleton;