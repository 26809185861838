import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
// import Grow from '@material-ui/core/Grow';
// import Paper from '@material-ui/core/Paper';
// import Popper from '@material-ui/core/Popper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Api from '../../../../state/Api';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as authActions from '../../../../state/auth/actions';
import * as authSelectors from '../../../../state/auth/selectors';
import * as appSelector from '../../../../state/app/selectors';
import * as appActions from '../../../../state/app/actions';

import { makeStyles, useTheme } from "@material-ui/core/styles";

  const useStyles = makeStyles({
    root: {
      minWidth: 150,
      // width: '100%'
    },
  });

  const SupervisorAgentMenu = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const [username, setUsername] = useState('')
    


  useEffect(() => {
    if(me){
      // console.log(me);
      setUsername(me.username);
    }
  }, [me]);    

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(5);
  const estadoSonido = useSelector(appSelector.getEstadoSonido());

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  // const open = Boolean(anchorEl);


  
  const handleClick = (event) => {
    console.log('hizo handle Click')
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  function handleMenuClick(Val1) {
    console.log(Val1);
  }


  const handleForzarCerrarSesion = (username) => {
    console.log(username)
    setOpenDialog(true);
  };

  const handleAgentLogout = () => {
    dispatch(authActions.logout())
  };

  const handleSound = (event, index) => {
   if(estadoSonido){
      dispatch(appActions.disableSound())
    }else if(!estadoSonido){
      dispatch(appActions.enableSound())
    }  
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpen(false);
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
    setOpen(false);
  };

  const desloguearUsuario = async (usuario) => {
    console.log('pasa por desloguear usuario')
    try {
      await Api.req.post(`/supervisor/agents/${usuario}/force/logout`)
      .then((response) => { 
        props.buscarPorFecha()
        setOpen(false);
        setOpenDialog(false);
        console.log('deslogueo')
        //setResultados(response.data.data) 
      });
    }
    catch(err){    }
  }

  return (
    <>
      <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Forzar Cerrar Sesion</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
             Estas a punto de cerrar forzosamente la sesión del usuario {props.username}. ¿ Estás seguro que desea hacerlo ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => desloguearUsuario(props.username)} color="primary" autoFocus>
              Cerrar Sesión
            </Button>
          </DialogActions>
      </Dialog>
      <IconButton
        color="secondary"
        size="small"
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
        <Menu
          // id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
        >
        <MenuItem style={{ display : 'none' }} onClick={() => handleMenuClick(props.username)}>
          <ListItemIcon>
            <Switch label="sonido" size="small" color="primary" onClick={(e) => {}} checked={false}/>
          </ListItemIcon>
          <Typography variant="inherit">Forzar Pausa</Typography>
        </MenuItem>
        <MenuItem
          onClick={event => handleForzarCerrarSesion(props.username)}
        >
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <Typography variant="inherit">Cerrar Sesión</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default SupervisorAgentMenu