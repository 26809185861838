import React, { useState } from "react";
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TextField , Paper,Input,InputLabel,Select,MenuItem, Fab, Grid,Button, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Badge, CircularProgress, LinearProgress , Tooltip, CardContent, Typography } from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  AreaSeries,
  BarSeries,
  Title,
  Legend
} from '@devexpress/dx-react-chart-material-ui';
import { scaleBand } from '@devexpress/dx-chart-core';
import { ValueScale, ArgumentScale, Stack, Animation, EventTracker } from '@devexpress/dx-react-chart';


import Api from '../../../../state/Api';
import moment, { Moment } from "moment";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import {
  TimePicker,
  DatePicker,
  LocalizationProvider 
} from '@material-ui/pickers';

import {
  curveCatmullRom,
  area,
} from 'd3-shape';
import { scalePoint } from 'd3-scale';

import MomentUtils from '@date-io/moment';

import * as contactsActions from '../../../../state/contacts/actions';
import * as conversationActions from '../../../../state/conversation/actions';
import * as contactsSelector from '../../../../state/contacts/selectors';
import * as campaignsSelector from '../../../../state/channels/selectors';

import * as appActions from '../../../../state/app/actions'; 
import * as authSelectors from '../../../../state/auth/selectors';

import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginRight: -drawerWidth
  },
  formControl: {
    margin: theme.spacing(1.5),
    minWidth: '90%',
  },
  cien: {
    margin: theme.spacing(1.5),
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  counter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
  },  
}));

const legendStyles = () => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row',
  },
});
const legendRootBase = ({ classes, ...restProps }) => (
  <Legend.Root {...restProps} className={classes.root} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const legendLabelStyles = () => ({
  label: {
    whiteSpace: 'nowrap',
  },
});
const legendLabelBase = ({ classes, ...restProps }) => (
  <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);



const Contactos = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const campaigns = useSelector(campaignsSelector.getChannels());
  //const chatsPublish = props.todas ? allChatsFiltered : allChats;
  const [fechaDesde, setFechaDesde] = useState(moment().utcOffset(0, true).format("YYYY-MM-DD"));
  const [horarioDesde, setHorarioDesde] = useState(new Date());
  const [horarioHasta, setHorarioHasta] = useState(new Date());
  const [selectedCampaign, setSelectedCampaign] = useState(2);
 
  const [selectedResult, setSelectedResult] = useState('');  
  const [resultados,setResultados] = useState([]);
  const [resultadosDashboard,setResultadosDashboard] = useState([]);
  const [resultadosHora,setResultadosHora] = useState([]);
  const [resultadosQueue,setResultadosQueue] = useState([]);
  const [timeShift,setMaxTimeShift] = useState(campaigns[0].maxTimeShift);
  const [hayError,setHayError] = useState(false);


  // const { data: chartData } = resultadosHora;
  const pad = (value) => {
    if(value < 10) {
        return '0' + value;
    } else {
        return value;
    }
  }
  

  const agregarHora = (resultados) => {
    resultados.forEach((resultado) => {
      resultado.horario = resultado.statHora + ':' + pad(resultado.statMinute);
    });
    return resultados;
  }

  const buscarPorFecha = async () => {
    let initialdate = fechaDesde;
    let start_time = moment(horarioDesde).format("HH:mm:ss") ;
    let enddate = fechaDesde;
    let end_time = moment(horarioHasta).format("HH:mm:ss");

    let datetimeA = moment(initialdate + " " + start_time);
    let datetimeB = moment(enddate + " " + end_time);

    try {
      await Api.req.get(`/supervisor/stats/campaign/${selectedCampaign}/stats/minute?startDate=${moment(datetimeA).utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss")}&endDate=${moment(datetimeB).utcOffset(0, true).format("YYYY-MM-DD HH:mm:ss")}`)
      .then((response) => { setResultadosHora(agregarHora(response.data.data)) });
    }
    catch(err){    }
  }

  const setTimeShift = (value) => {
    let maxTimeShiftCampaign = campaigns.find((campaign) => campaign.id == value);

    if(maxTimeShiftCampaign){
      setMaxTimeShift(maxTimeShiftCampaign.maxTimeShift)
    }
  }

  const Area = props => (
    <AreaSeries.Path
      {...props}
      path={area()
        .x(({ arg }) => arg)
        .y1(({ val }) => val)
        .y0(({ startVal }) => startVal)
        .curve(curveCatmullRom)}
    />
  );
  
  const interpretarError = (error) => {
    if(error == null){
      setHayError(false)
    }else if( error == 'maxTime' || error == 'minTime' ) {
      setHayError(true)
    }
  }


  const cambioCampaign = (value) => {
    setSelectedCampaign(value);
    setTimeShift(value)
  }

  const calcularHorario = () => {
    console.log('pasa x calcular horario')
    let duration = moment.duration(moment(horarioDesde).endOf('day').diff(horarioDesde));
    let hours = duration.asHours();

    if(hours > timeShift){
      return moment(horarioDesde).add(timeShift,'hours').toDate()
    }else{
      return null;
    }
  }

  const labelHalfWidth = 50;
  let lastLabelCoordinate;
  const ArgumentLabel = props => {
    const { x } = props;
    // filter Labels
    if (
      lastLabelCoordinate &&
      lastLabelCoordinate < x &&
      x - lastLabelCoordinate <= labelHalfWidth
    ) {
      return null;
    }
    lastLabelCoordinate = x;
    return <ArgumentAxis.Label {...props} />;
  };

  return (
    <>
          <Grid container style={{marginRight: 10}}>
              <Grid item xs={2}>
                    {/* <InputLabel id="demo-customized-select-label">Campaña</InputLabel> */}
                    <FormControl className={classes.formControl} >
                    <Select
                      labelId="diposition-label"
                      id="diposition"
                      value={selectedCampaign}
                      onChange={(event) => { cambioCampaign(event.target.value) } }
                      fullWidth={true}
                      input={<Input />}
                      className={classes.cien}
                    >
                    {
                      campaigns.length > 0 ? campaigns.map((option, index) => 
                      <MenuItem key={index} value={option.id}>{option.Nombre}</MenuItem>
                    ) : null }
                    <MenuItem value={0}>Campaña</MenuItem>    
                    </Select>
                    {/* <FormHelperText>Required</FormHelperText> */}
                    </FormControl>
              </Grid>
              <Grid item xs={2} style={{ marginTop : '9px'}}>
                  <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                    <DatePicker
                        disableToolbar
                        renderInput={(props) => <TextField {...props} helperText="" />}
                        variant="inline"
                        inputFormat="YYYY-MM-DD"
                        margin="normal"
                        size="small"
                        ampm={false}
                        id="date-picker-inline2"
                        className={classes.cien}
                        label="Fecha"
                        fullWidth={true}
                        onError={(reason) => interpretarError(reason) }
                        autoOk={true}
                        value={fechaDesde}
                        onChange={ date => setFechaDesde(moment(date).utcOffset(0, true).format("YYYY-MM-DD")) }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                  </LocalizationProvider>
              </Grid>    
              <Grid item xs={2} style={{ marginTop : '9px'}}>
                  <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                    <TimePicker
                        disableToolbar
                        renderInput={(props) => <TextField {...props}   helperText=""  />}
                        variant="inline"
                        fullWidth={true}
                        size="small"
                        ampm={false}
                        inputFormat="HH:mm"
                        margin="normal"
                        id="date-picker-inline"
                        className={classes.cien}
                        label="Horario Desde"
                        //onChange={calcularHorario()}
                        onError={ (error) => { interpretarError(error) } }
                        autoOk={true}
                        value={horarioDesde}
                        onChange={ date => setHorarioDesde(date) }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={2} style={{ marginTop : '9px'}}>
                  <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                    <TimePicker
                        disableToolbar
                        renderInput={(props) => <TextField {...props}   helperText=""  />}
                        variant="inline"
                        fullWidth={true}
                        size="small"
                        ampm={false}
                        inputFormat="HH:mm"
                        margin="normal"
                        maxTime={calcularHorario()}
                        minTime={horarioDesde}
                        id="date-picker-inline"
                        className={classes.cien}
                        label="Horario Hasta"
                        onError={ (error) => { interpretarError(error) } }
                        autoOk={true}
                        value={horarioHasta}
                        onChange={ date => setHorarioHasta(date) }
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </LocalizationProvider>
                </Grid>                         
                <Grid item xs={1}>
                </Grid>    
                <Grid item xs={3}>
                  <Button onClick={() => { buscarPorFecha(); } } 
                    fullWidth={true}
                    disabled={hayError}
                    style={{ 'marginTop':'25px' }} 
                    size="small"
                    // disabled={!selectedCampaign || !selectedResult}
                    variant="contained" color="primary">
                    Buscar
                  </Button>
                </Grid>
              </Grid>
  

      <Grid container >
        <Grid item xs={12}>
        <div style={{margin: 5, marginRight: 10, height: '100px', position: 'relative',}}>
          <Paper elevation={3}>
            <Chart height={400}
              data={ resultadosHora }
            >

              {/* <ValueScale name="total" /> */}   
              <ArgumentAxis  labelComponent={ArgumentLabel}  >
              </ArgumentAxis>
              <ValueAxis name="Horario" showLine max={2} />

              <BarSeries
                name="Contactos"
                valueField="contactos"
                argumentField="horario"
                color="#ffd700"
   
              />
              <AreaSeries
                name="Agentes"
                valueField="agentes"
                argumentField="horario"
                seriesComponent={Area}
          
              />
              <Title text="Contactos por Minuto" />
              {/* <Animation /> */}
              <Legend position="bottom" rootComponent={Root} labelComponent={Label} />

            </Chart>
          </Paper>
          
          </div>

        </Grid>
      </Grid>
    </>
  )
}

export default Contactos