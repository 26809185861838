import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { ClientSocket, useSocket } from '../../hooks/useSocket';
import * as contactActions from '../../state/contacts/actions';
import * as userActions from '../../state/users/actions';
import * as authActions from '../../state/auth/actions';
import * as authSelectors from '../../state/auth/selectors';
import { useLocation } from "react-router-dom";

import { SOCKET_ACTIONS, API_URL } from '../../constants';

const {
  CONTACTS,
  CHANNELS,
  USERS,
  AUTH
} = SOCKET_ACTIONS;

const GlobalSockets = () => {
  const dispatch = useDispatch();

  useSocket('reconnect', () => {
  });

  // useSocket(USERS.ONLINE_LIST, handleOnlineList);

  return <div />;
};

const ContactsSockets = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const inboxLocation = '/';
 
  const handleNewContact = data => {
   
    if (location.pathname == inboxLocation){
      dispatch(contactActions.addContact(data));
    }
  }
  const handleRemoveContact = data => dispatch(contactActions.contactTransfered(data));
  const handleIncomingMessage = data => dispatch(contactActions.incomingMessage(data));
  const handleMessageAcknowledge = data => dispatch(userActions.handleOffline(data));
  const handleMessageRead = data => dispatch(contactActions.handleMessageRead(data));
  const handleMessageDelivered = data => dispatch(contactActions.handleMessageDelivered(data));
  const handleMessageRejected = data => dispatch(contactActions.handleMessageRejected(data));
  const handleMessageReacted = data => dispatch(contactActions.handleMessageReacted(data));

  useSocket(CONTACTS.NEW_CONTACT, handleNewContact);
  useSocket(CONTACTS.REMOVE_CONTACT , handleRemoveContact);
  useSocket(CONTACTS.INCOMING_MESSAGE, handleIncomingMessage);
  useSocket(CONTACTS.MESSAGE_ACK, handleMessageAcknowledge);
  useSocket(CONTACTS.MESSAGE_READ, handleMessageRead);
  useSocket(CONTACTS.MESSAGE_DELIVERED, handleMessageDelivered);
  useSocket(CONTACTS.MESSAGE_REJECTED, handleMessageRejected);
  useSocket(CONTACTS.MESSAGE_REACTED, handleMessageReacted);

  return <div />;
};

const UserSockets = () => {
  const dispatch = useDispatch();

  const handleOnlineList = data => dispatch(userActions.handleOnlineList(data));
  const handleOnline = data => dispatch(userActions.handleOnline(data));
  const handleOffline = data => dispatch(userActions.handleOffline(data));

  useSocket(USERS.ONLINE_LIST, handleOnlineList);
  useSocket(USERS.ONLINE, handleOnline);
  useSocket(USERS.OFFLINE, handleOffline);

  return <div />;
};

const AuthSockets = () => {
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const dispatch = useDispatch();
  const handleForcedDisconnect = () => dispatch(authActions.logout());

  useSocket(AUTH.FORCED_DISCONNECT, handleForcedDisconnect);

  return <div />;
};

const Sockets = () => {
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  // console.log(API_URL)
  if (!me) return <div></div>;

  return (
    <ClientSocket url={`${API_URL}`} user={me.username}>
      <GlobalSockets />
      <ContactsSockets />
      <UserSockets />
      <AuthSockets />
    </ClientSocket>
  );
};

export default Sockets;
