import React, { useState, useEffect } from 'react';
import Api from '../../../state/Api';
import { TextField , Dialog, DialogActions, DialogTitle, DialogContent, Button, Box, FormControl, Input, InputLabel } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import * as appActions from '../../../state/app/actions';
import BlockUi from 'react-block-ui';

const AutoResponsesNew = ( props ) => {


  const dispatch=useDispatch();
  
  const [titulo, setTitulo] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [tags, setTags] = useState('');
  const [shortcut, setShortcut] = useState('');
  const [shortcutRepeat, setShortcutRepeat] = useState(false);
  const [enviandoDatos, setEnviandoDatos] = useState('');
  const disabled = !(!!titulo && !!mensaje && !!tags);
  const handleModalClose = () => {
    props.onClose()
  };  

  const handleCreateNew = () => {
    setEnviandoDatos(true)
    const params = {
      title : titulo,
      message : mensaje,
      tags : tags,
      shortcut : shortcut
    }
    if(props.selectedResponse){
      Api.req.put(`/campaigns/${props.campaignId}/templates/text/${props.selectedResponse.id}`,params).then((response)=>{
        let paramsConId = Object.assign({ id : props.selectedResponse.id },params)
        props.onUpdate(paramsConId)
        setEnviandoDatos(false)
      }, (error) =>  { 
        console.log(error)
        dispatch(appActions.addToast('Ha habido un error al crear la respuesta : ' + error.response.data.msg,'error'))
        setEnviandoDatos(false)
      })
    }else{
      Api.req.post(`/campaigns/${props.campaignId}/templates/text`,params).then((response)=>{
        let paramsConName = Object.assign({ name : params.title, id : response.data.data[0].id },params)
        props.onNew(paramsConName)
        setEnviandoDatos(false)
      }, (error) => {
        setEnviandoDatos(false)
        dispatch(appActions.addToast('Ha habido un error al crear la respuesta : ' + error.response.data.msg,'error'))
      })
    }
    
  };
  
  console.log(shortcut)

  useEffect( () => {
    if(props.selectedResponse && props.textResponses){
      let repeat = props.textResponses.find( (response) => 
        response.shortcut == shortcut  && response.id != props.selectedResponse.id
      );
      console.log(!!repeat)
      setShortcutRepeat(!!repeat)
    }

    
  } ,[shortcut])

  useEffect( () => {
    console.log(props)
    let response = props.selectedResponse;

    setTitulo(response ? response.name : '' )
    setMensaje(response ? response.message : '')
    setTags(response ? response.tags : '')
    setShortcut(response ? response.shortcut : '')

    
  } ,[props.selectedResponse])

  return (
     
      <Dialog
      fullWidth={true}
      maxWidth = {'xs'}
      open={props.isOpen} 
      onClose={handleModalClose} aria-labelledby="form-dialog-title">
      <BlockUi tag="div" blocking={enviandoDatos}>
        <DialogTitle id="customized-dialog-title" >
          Crear Nuevo 
        </DialogTitle>
        <DialogContent dividers>
          
              <Box>
                <FormControl>
                    <InputLabel htmlFor="titulo">Titulo</InputLabel>
                    <Input
                      onChange={(event)=> setTitulo(event.target.value)}
                      value={titulo}
                      id="titulo"
                      fullWidth={true}
                    />
                </FormControl>
              </Box>
              <Box style={{ 'marginTop' : '10px'}}>
                  <TextField
                    id="mensaje"
                    label="Mensaje"
                    multiline
                    fullWidth
                    rows={4}
                    variant="outlined"
                    value={mensaje}
                    onChange={(event) => setMensaje(event.target.value)}    
                  />
              </Box>
              <Box>
                <FormControl>
                      <InputLabel htmlFor="tags">Tags</InputLabel>
                      <Input
                        onChange={(event)=> setTags(event.target.value)}
                        value={tags}
                        id="tags"
                        fullWidth={true}
                      />
                  </FormControl>                     
              </Box>
              <Box>
                <FormControl>
                      <InputLabel htmlFor="tags">Shortcut</InputLabel>
                      <Input
                        onChange={(event)=> setShortcut(event.target.value)}
                        value={shortcut}
                        id="shortcut"
                        fullWidth={true}
                      />
                  </FormControl>                     
              </Box>
           
        </DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={handleCreateNew} color="primary">
            { props.selectedResponse ? 'Editar' : 'Crear' }
          </Button>
          <Button onClick={handleModalClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
        </BlockUi>  
      </Dialog>     
      
  )
}

export default AutoResponsesNew;
