import React, { useState } from "react";
// import { makeStyles, ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { lightGreen } from '@material-ui/core/colors';

import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../state/auth/actions';
import * as authSelectors from '../../state/auth/selectors';
import Link from '@material-ui/core/Link';
import "./Login.css";
import { Button, Card, CardContent, CardMedia, CardActions, Typography, TextField } from "@material-ui/core";
import ModalTerminos from "../shared/modalTerminos";

const Login = ( props ) => {

  const classes = props.classes;

  const [loggingIn, setLoggingIn] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [telefono, setTelefono] = useState('');
  const [clave, setClave] = useState('');
  const [requireClave, setRequireClave] = useState(false);
  const dispatch = useDispatch();
  const loginError = useSelector(authSelectors.getLoginError());
  const preventDefault = (event) => event.preventDefault();
  const [openModalTerminos, setOpenModalTerminos ] = useState(false);
  const [language, setLanguage] = useState('spanish');

  const login = async () => {
    try {
      setLoggingIn(true);
      const data = await dispatch(authActions.login(username, password));

      if(data.required2fa){
        setRequireClave(true)
      }
      
      setLoggingIn(false);

    } catch (error) {
      setLoggingIn(false);
    }
  };  

  const loginConfirm = async () => {
    try {
      setLoggingIn(true);
      const data = await dispatch(authActions.login(username,password,clave));

      if(data.required2fa){
        setRequireClave(true)
      }
      
      setLoggingIn(false);

    } catch (error) {
      setLoggingIn(false);
    }
  };  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const validLogin = () => {
    if(username.length > 0 && password.length > 0){
      return false
    }else{
      return true;
    }
  }

  const maxLengthCheck = (object) => {
    let maxLength = 6;
    if (object.target.value.length > maxLength) {
      object.target.value = object.target.value.slice(0, maxLength)
    }
  }

  const handleCloseTerminos = () => {
    setOpenModalTerminos(false)
  }

  const openTerminos = (idioma) => {
    setLanguage(idioma)
    setOpenModalTerminos(true)
  }

  return (
    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 auto', height: "calc(100vh - 65px)"}} >
        <ModalTerminos language={language} open={openModalTerminos} onClose={handleCloseTerminos} />
        <form  noValidate autoComplete="off">            
          <Card style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 40, paddingTop: 20, borderRadius: 20 }} >
            {/* <CardHeader title="Login" /> */}
            <CardMedia 
              style={{ height: 106, width: 200 , margin : '0 auto' , marginTop : 10}}
              title="TeVeCompras Omnichannel"
              image="https://api.tevecompras.com/assets/logo-tevecompras.png"
            />
            <CardContent style={{  display: 'flex', flexDirection: 'column', justifyContent:'space-evenly', alignItems: 'center', padding: 40, height: 300 }} > 
                { !requireClave ? 
                <>
                  <TextField 
                    label="Usuario"
                    onChange={({ target: { value } }) => setUsername(value)}
                    disabled={loggingIn}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                  />
                  <TextField 
                    label="Contraseña"
                    // placeholder="Contraseña"
                    type="password"
                    onChange={({ target: { value } }) => setPassword(value)}
                    disabled={loggingIn}
                    onKeyPress={handleKeyPress}
                    variant="outlined"
                  />
                </>
                : null }
                { requireClave ? 
                <TextField 
                  label="Clave numérica"
                  placeholder="Ingrese Clave Numerica"
                  type = "number"
                  onChange={({ target: { value } }) => setClave(value)}
                  disabled={loggingIn}
                  onInput={maxLengthCheck}
                  onKeyPress={handleKeyPress}
                  variant="outlined"
                />
                : null }

                {loginError && (
                  <Typography>
                    {loginError}
                  </Typography>
                )}
            </CardContent>
            <CardActions style={{justifyContent: 'center'}}>
            { !requireClave ? 
              <Button 
                style={{width: 150}}
                color="primary"
                onClick={login}
                disabled={validLogin()}
                variant="contained"
              >Login</Button>
            : null }
            { requireClave ? 
              <Button 
                style={{width: 150}}
                color="primary"
                onClick={loginConfirm}
                variant="contained"
                disabled={!clave}
              >Confirmar Login</Button>
            : null }
            </CardActions>
          </Card>         
            <Button  onClick={ () =>{ openTerminos('spanish') } }>
              Política de Privacidad
            </Button>
            <Button onClick={  () =>{openTerminos('english') } }>
              Privacy Policy
            </Button>
          </form>
          
    </div>
  );
  
}

export default Login;
