import React, { useEffect } from "react";
import { Avatar ,Grid, List, ListItemText, ListItem, Button, ListItemIcon, ListItemAvatar, TextField, FormControl, InputLabel, MenuItem, Select, Box, CardActionArea, CardMedia, CardContent, Typography, CardActions, Card, Divider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import Api from "../../state/Api";
import LaunchIcon from '@material-ui/icons/Launch';
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import { Refresh } from "@material-ui/icons";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableContainer: {
        height: '100%',
        maxHeight: '500px'
    },
    chip: {
        marginBottom: '10px'
    },
    paperStyle: {
        width: '50%',
        textAlign: 'center',
        marginBottom: '10px'
    },
    destacadoNumber: {
        color: '#42a5f5'
    },
    formControl : {
        color : '#000'
    },
    blackText : {
        color : '#000 !important' 
    } ,
    root: {
        width: '100%',
        '& .super-app-theme--header': {
            color: 'black'
        },
        '& .super-app-theme--row': {
            color: 'black'
        }
    },
    rightContainer: {
        '& .MuiInputBase-input': {
            color: 'black'
        },
        '& .MuiFormLabel-root': {
            color: 'black'
        },
        '& .MuiOutlinedInput-notchedOutline ' : {
            borderColor : 'grey'
        },
        '& .MuiInput-underline ': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        }
    },
});

function Plantillas() {

    const [plantillas, setPlantillas] = React.useState([]);
    const [selectedPlantilla, setSelectedPlantilla] = React.useState()

    const getData = () => {
        var param = 37;
        Api.req.get(`marketing/${param}/templates`)
            .then((response) => {
                setPlantillas(response.data.data)
                setSelectedPlantilla(response.data.data[0])
            })
    }

    useEffect(() => {
        getData()

    }, [])

    const classes = useStyles();
    
    return (
        <>
            <Grid container style={{ flexDirection: 'row', justifyContent:'space-between', color: '#888' }}>
                <Grid item xs={2} className={classes.leftContainer} style={{ overflow: 'auto', height: 'calc(100vh - 122px)', backgroundColor: '#fff', paddingRight: '30px', borderRight: '1px solid #cdcdcd' }}>
                    <List component="nav" aria-label="main mailbox folders">
                        <div>
                            <ListItem style={{ backgroundColor: '#fff' }}
                                onClick={() => {
                                    getData()
                                }}
                                button >
                                <ListItemAvatar>
                                    <Avatar style={{ background : 'red' }}>
                                        <Refresh  />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Refrescar" />
                            </ListItem>
                            <Divider component="li" />
                        </div>
                        {plantillas.map((plantilla, index) => (
                            <div key={index}>
                                <ListItem style={{ backgroundColor: '#fff' }}
                                    selected={plantilla === selectedPlantilla}
                                    key={index}
                                    onClick={() => {
                                        setSelectedPlantilla(plantilla)
                                    }}
                                    button >
                                    <ListItemAvatar> 
                                        <Avatar>
                                            <WhatsappIcon /> 
                                        </Avatar>
                                    </ListItemAvatar>
                                        <ListItemText primary={plantilla.name} />
                                    </ListItem>
                                    <Divider component="li" />
                            </div>
                        ))}
                    </List>

                </Grid>
                <Grid item xs={6} className={classes.rightContainer} style={{ padding: '10px', border: 3, backgroundColor: '#fff' }} >

                    {selectedPlantilla ?
                        <>
                            <TextField style={{color : '#000 !important'}} id="standard-basic" value={selectedPlantilla.description} label="Descripción"  fullWidth/> <br /><br />

                            <label className={classes.blackText} htmlFor="">Encabezado</label><br />  <br />
                            <FormControl className={classes.formControl}>
                                <InputLabel className={classes.blackText} id="demo-simple-select-label">Tipo</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPlantilla.type}
                                    readOnly={true}
                                    className={classes.blackText}
                                    style={{ minWidth: '30px' }}
                                    //onChange={handleChange}
                                >
                                    <MenuItem value="text">text</MenuItem>
                                    <MenuItem value="image">image</MenuItem>
                                </Select>
                            </FormControl>        <br />  <br />           

                            <label className={classes.blackText} htmlFor="">Mensaje</label><br />  <br />
                            <TextField
                                id="standard-multiline-static"
                                label="Cuerpo del Mensaje"
                                multiline
                                rows={10}
                                fullWidth
                                className={classes.blackText} 
                                value={selectedPlantilla.body}
                                readOnly={true}
                                variant="outlined"
                                inputProps={{ readOnly: true }}
                            /><br /><br />
                            

                            <label className={classes.blackText} htmlFor="">Botones</label><br />  <br />
                            {selectedPlantilla.buttons.map((button, index) => (
                                <div key={index} style={{ padding :'5px', borderRadius: '5px' }}>
                                    <Grid container style={{ border: '1px solid grey', marginTop: '5px' }} spacing={2}>
                                        <Grid item xs={3} >
                                            <TextField id="standard-basic" readOnly={true} value={button.paramName} variant="filled" size="small" />
                                        </Grid>
                                        <Grid item xs={2} >
                                            <TextField id="standard-basic" readOnly={true} fullWidth value={button.paramType} label="Param Type" size="small" />
                                        </Grid>
                                        <Grid item xs={7} >
                                            <TextField id="standard-basic" readOnly={true} fullWidth value={button.paramTextUrl} label="Param Text Url" size="small" />
                                        </Grid>
                                    </Grid>                                    
                                </div>

                            ))}<br />

                            <br></br>
                        </>
                        : ''}
                </Grid>
                <Grid item xs={4} style={{ background: "url(./assets/whatsapBackground.png) center center #e5ddd5" }} >
                    <Card style={{maxWidth : 400, margin : '0 auto', marginTop : '10px', padding : '15px' , background : "#FFFFFF", paddingLeft : '20px'}}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                height="140"
                                image="/assets/whatsapTemplateImage.png"
                                title="Contemplative Reptile"
                            />
                            <CardContent>
                                <Typography  style={{ color: '#000000', whiteSpace: 'pre-line' }} variant="body2" color="textPrimary" component="p">
                                    {selectedPlantilla ? selectedPlantilla.body : '' }
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <Box>
                            {selectedPlantilla && selectedPlantilla.buttons.map((button, index) => (
                                
                                <Button fullWidth key={index} size="small" variant="outlined" style={{ marginBottom : '10px' }} startIcon={button.paramType == 'link' ? <LaunchIcon /> : ''} color="primary">
                                    {button.paramName } 
                                </Button>
                                
                            ))}
                        </Box>
                    </Card>
                </Grid>
            </Grid>




        </>
    )
}

export default Plantillas;