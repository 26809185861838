import React, {useEffect, useState } from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListadoAgentesRwindow from './ListadoAgentesRwindow';
import Api from '../../../state/Api';
import Configuracion from './Configuracion';
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

const useStyles = makeStyles({
    container: {
      height: '100%',
      'overflow' : 'hidden'
    } 
});

const Prospecto = ( props ) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [agentes, setAgentes] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [cargoAdmins, setCargoAdmins] =  useState(false);
    const [cargoAgentes, setCargoAgentes] =  useState(false);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const actualizarAdmins = () => {
        Api.req.get(`admin/campaign/${props.selectedCampaign.id}/admins`)
        .then((response) => {
            response.data.total > 0 ? setAdmins(response.data.admins) : setAdmins([]) ;  
            setCargoAdmins(true)
        })
    }

    const actualizarAgentes = () => {
        Api.req.get(`admin/campaign/${props.selectedCampaign.id}/agents`)
        .then((response) => {
            response.data.total > 0 ? setAgentes(response.data.agents) : setAgentes([]) ;  
            setCargoAgentes(true)
        })
      }

    useEffect(() => {
        if(props.selectedCampaign){
            setCargoAdmins(false);
            setAgentes(false);
            actualizarAgentes()
            actualizarAdmins()
        }
    }, [props.selectedCampaign]);

    return (
        <div className={classes.container}>
            <BlockUi className={classes.container} tag="div" blocking={!cargoAgentes || !cargoAdmins}>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        <Tab label="Configuracion"/>
                        <Tab label="Agentes"  />
                        <Tab label="Supervisores"/>
                </Tabs>
                <Box  style={{'height' : '100%'}} >
                    { value == 0 ? <Configuracion selectedCampaign={props.selectedCampaign} /> : null  }
                    { value == 1 ? <ListadoAgentesRwindow style={{'height':'90%'}} admin={false} onActualizoAgentes={actualizarAgentes} selectedCampaign={props.selectedCampaign} className={classes.container} users={props.users} agentes={agentes} /> : null }
                    { value == 2 ? <ListadoAgentesRwindow style={{'height':'90%'}} admin={true} onActualizoAgentes={actualizarAdmins} selectedCampaign={props.selectedCampaign} className={classes.container} users={props.users} agentes={admins} /> : null }
                </Box>
            </BlockUi>
        </div>
    )

}

export default Prospecto;