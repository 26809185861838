import { ADD_CHANNEL_SUCCESS, FETCH_CHANNELS_SUCCESS, FETCH_CHANNELS_FAILURE,
  FETCH_CHANNELS_TEXT_RESPONSES_FAILURE,FETCH_CHANNELS_TEXT_RESPONSES_SUCCESS, FETCH_CHANNELS_TEXT_RESPONSES_REQUEST,
  FETCH_CHANNELS_MEDIA_RESPONSES_FAILURE,FETCH_CHANNELS_MEDIA_RESPONSES_SUCCESS, FETCH_CHANNELS_MEDIA_RESPONSES_REQUEST,
  EDIT_TEXT_RESPONSE, ADD_TEXT_RESPONSE, ADD_IMAGE_RESPONSE, FETCH_CHANNEL_MEDIA_RESPONSES_SUCCESS, FETCH_CHANNEL_MEDIA_RESPONSES_FAILURE , FETCH_CHANNEL_MEDIA_RESPONSES_REQUEST } from './constants';
// import axios from 'axios';
import Api from '../../state/Api';
import axios from 'axios';
import * as appActions from '../../state/app/actions';

export const addChannel = (message) => (dispatch) => {

  // console.dir(message)
  dispatch({
    type: ADD_CHANNEL_SUCCESS,
    payload: message
  })
}

export const fetchChannels = (userName) => async (dispatch) => {

  try {
    const { data } = await Api.req.get(`/agents/${userName}/campaigns`);
    dispatch({
      type: FETCH_CHANNELS_SUCCESS,
      payload: data.campaigns
    })
    return data.campaigns;
  }
  catch(err) {
    dispatch({
      type: FETCH_CHANNELS_FAILURE,
      payload: err
    })    
    return null
  }
}

export const addImageResponse = (campaignId,response) => async (dispatch) => {
  dispatch({ type: ADD_IMAGE_RESPONSE, payload: { campaignId : campaignId , response : response }})
}

export const addTextResponse = (campaignId,response) => async (dispatch) => {
  dispatch({ type: ADD_TEXT_RESPONSE, payload: { campaignId : campaignId , response : response }})
}

export const editTextResponse = (campaignId, response) => async (dispatch) => {
  dispatch({ type: EDIT_TEXT_RESPONSE, payload: { campaignId : campaignId , response : response }})
}

export const fetchChannelResponses = (campaignId) => async (dispatch) => {
  try {
    let response = await Api.req.get(`/campaigns/${campaignId}/templates/media`)
    dispatch({
      type: FETCH_CHANNEL_MEDIA_RESPONSES_SUCCESS,
      payload: { campaignId : campaignId , response : response } 
    })
  }
  catch(err){
    console.log(err)
    dispatch(appActions.addToast('Ha habido un problema al traer las respuestas automaticas de imagen','warning'))
  }
}

export const fetchChannelsResponses = (campaigns) => async (dispatch) => {

    dispatch({type: FETCH_CHANNELS_TEXT_RESPONSES_REQUEST })
    let arrayQuerysText = campaigns.map((campaign) => Api.req.get(`/campaigns/${campaign.id}/templates/text`))
    let arrayQuerysMedia = campaigns.map((campaign) => Api.req.get(`/campaigns/${campaign.id}/templates/media`))
 
    try {
      let [...results] = await axios.all(arrayQuerysText);
      dispatch({
        type: FETCH_CHANNELS_TEXT_RESPONSES_SUCCESS,
        payload: { campaigns : campaigns , responses : results } 
      })
    }
    catch(err) {
      dispatch(appActions.addToast('Ha habido un problema al traer las respuestas automaticas de texto','warning'))
      dispatch({
        type: FETCH_CHANNELS_TEXT_RESPONSES_FAILURE,
        payload: err
      })    
    }

    try {
      let [...results] = await axios.all(arrayQuerysMedia);
      dispatch({
        type: FETCH_CHANNELS_MEDIA_RESPONSES_SUCCESS,
        payload: { campaigns : campaigns , responses : results } 
      })
    }
    catch(err) {
      dispatch(appActions.addToast('Ha habido un problema al traer las respuestas automaticas de Imagen','warning'))
      dispatch({
        type: FETCH_CHANNEL_MEDIA_RESPONSES_FAILURE,
        payload: err
      })    
    }
}
