import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ListItem, ListItemText , Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Grid, Typography, Paper, Chip, Avatar } from '@material-ui/core';
import moment from 'moment';  
import AutorenewIcon from '@material-ui/icons/Autorenew';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 200,
  },
  counter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
  },  
}));

function createData(name, status, calories, fat, carbs, protein) {
  return { name, status, calories, fat, carbs, protein };
}

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const renderOnlineBadge = (ispaused) =>{
  if(ispaused){
    return (
      <>
        <Chip label="En Pausa" style={{ backgroundColor: 'red'}} />
      </>
    )
  }
  if(ispaused == null){
    return (
      <>
        <Chip label="Offline" style={{ backgroundColor: '#ccc', color: '#000'}} />
      </>
    )
  }
  if(!ispaused){
    return (
      <>
        <Chip label="Online" style={{ backgroundColor: 'rgb(76, 175, 80)'}} />
      </>
    )
  }
}

const QueueRight = (props) => {
  const classes = useStyles();

  return (
    <>
     <Paper elevation={3} >
        <Grid container >
            <Grid item xs={12} style={{display: 'flex', flex: 1, flexDirection: 'column',justifyItems: 'center', alignItems: 'center', alignContent: 'center', textAlign: 'center'}}>
              <AutorenewIcon style={{ fontSize: 45, margin: 10 }}/> 
            </Grid>
            <Grid item xs={12} >
              {props.queueStats.map( ( evento, index ) => (
                <ListItem key={index}>
                    <ListItemText
                      primary={evento.agentid}
                      secondary={ moment(evento.lastInteractionTime).calendar()}
                    />
                  </ListItem>
              ))}
            </Grid>
        </Grid>
    </Paper>
    </>
  );
}

export default QueueRight