import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Paper, TableCell, TableBody ,  TableRow, TableHead ,  Table , TableContainer, InputLabel, Select, MenuItem, Fab, Grid, Button, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Badge, CircularProgress, LinearProgress, Tooltip, CardContent, Typography } from "@material-ui/core";

import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    AreaSeries,
    BarSeries,
    Title,
    Legend
} from '@devexpress/dx-react-chart-material-ui';


import Api from '../../../../state/Api';
import {
    LocalizationProvider,
    DatePicker
} from '@material-ui/pickers';

import MomentAdapter from "@material-ui/pickers/adapter/moment";

import * as campaignsSelector from '../../../../state/channels/selectors';

import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import VerDetallesModal from "./VerDetallesModal";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginRight: -drawerWidth
    },
    formControl: {
        margin: theme.spacing(1.5),
        minWidth: '90%',
    },
    cien: {
        margin: theme.spacing(1.5),
        width: '90%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    counter: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
        },
    },
}));

const legendStyles = () => ({
    root: {
        display: 'flex',
        margin: 'auto',
        flexDirection: 'row',
    },
});
const legendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const legendLabelStyles = () => ({
    label: {
        whiteSpace: 'nowrap',
    },
});
const legendLabelBase = ({ classes, ...restProps }) => (
    <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);



const TiempoDeAtencion = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const campaigns = useSelector(campaignsSelector.getChannels());
    const [fechaDesde, setFechaDesde] = useState(moment().startOf('month').utcOffset(0, true).format("YYYY-MM-DD"));
    const [fechaHasta, setFechaHasta] = useState(moment().endOf('month').utcOffset(0, true).format("YYYY-MM-DD"));
    const [resultados, setResultados] = useState([]);
    const [resultadosModal, setResultadosModal] = useState([]);
    const [agentes, setAgentes] = useState([]);
    const [selectedAgente, setSelectedAgente] = useState([]);
    const [openVerDetalles, setOpenVerdetalles] = useState(false)

    const [loading, setLoading] = useState(false);

    const visualizeDuration = (time) => {
        return moment.duration(time, "seconds").format("hh:mm:ss", { trim: false });
    }

    const openVerDetallesModal = () => {
       
    }

    // const { data: chartData } = resultadosHora;
    useEffect(() => {
        Api.req.get(`/supervisor/activeAgents`)
            .then((response) => {
                setAgentes(response.data.agents)
                setSelectedAgente('')
            });

    }, [])

    const buscarPorFecha = async () => {
        try {
            Api.req.get(`/supervisor/stats/attentionTime/agent/${selectedAgente}/?startDate=${moment(fechaDesde).utcOffset(0, true).format("YYYY-MM-DD")}&endDate=${moment(fechaHasta).utcOffset(0, true).format("YYYY-MM-DD")}`)
                .then((response) => {
                    setLoading(false)
                    if (response.data.total != 0) {
                        setResultados(response.data.data)
                    } else {
                        setResultados([])
                    }

                });
        }
        catch (err) { }
    }

    const buscarVerDetalles = (fecha) => {
        setOpenVerdetalles(true)
        Api.req.get(`/supervisor/stats/attentionTime/agent/${selectedAgente}/byDate?startDate=${moment(fecha).utcOffset(0, true).format("YYYY-MM-DD")}&endDate=${moment(fecha).utcOffset(0, true).format("YYYY-MM-DD")}`)
            .then((response) => {
                if (response.data.total != 0) {
                    setResultadosModal(response.data.data)
                } else {
                    setResultadosModal([])
                }

            });
    }


    const closeModal = () => {
        setOpenVerdetalles(false)
    }

    return (
        <>
            <VerDetallesModal onClose={closeModal} resultados={resultadosModal} open={openVerDetalles} />
            <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                <Grid container spacing={2} style={{ marginRight: 10 }}>

                    <Grid item xs={3} style={{ marginTop: '9px' }}>
                        <InputLabel id="demo-customized-select-label">Agente</InputLabel>
                        <Select
                            labelId="diposition-label"
                            id="diposition"
                            fullWidth={true}
                            value={selectedAgente}
                            onChange={(event) => setSelectedAgente(event.target.value)}
                        >
                            {
                                agentes.length > 0 ? agentes.map((option, index) =>
                                    <MenuItem key={index} value={option.usuario}>{option.usuario}</MenuItem>
                                ) : null}
                            <MenuItem value=''>Seleccione</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={2} style={{ marginTop: '9px' }}>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} helperText="" />}
                            disableToolbar
                            variant="inline"
                            fullWidth={true}
                            size="small"
                            inputFormat="DD/MM/YYYY"
                            margin="normal"
                            id="date-picker-inline"
                            className={classes.cien}
                            label="Fecha Desde"
                            autoOk={true}
                            value={fechaDesde}
                            onChange={date => setFechaDesde(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} style={{ marginTop: '9px' }}>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} helperText="" />}
                            disableToolbar
                            variant="inline"
                            fullWidth={true}
                            size="small"
                            inputFormat="DD/MM/YYYY"
                            margin="normal"
                            id="date-picker-inline"
                            className={classes.cien}
                            label="Fecha Hasta"
                            autoOk={true}
                            value={fechaHasta}
                            onChange={date => setFechaHasta(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button onClick={() => { buscarPorFecha(); }}
                            fullWidth={true}
                            style={{ 'marginTop': '25px', marginLeft: '15px' }}
                            size="small"
                            disabled={!selectedAgente}
                            variant="contained" color="primary">           
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item xs={12}>
                        <div style={{ margin: 10, marginTop: 5 }}>
                            {loading ? <CircularProgress size={30} /> : 
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Tiempo</TableCell>
                                                <TableCell>Acciones</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {resultados.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell component="th" scope="row">
                                                        {moment(row.fecha).format('DD/MM/YYYY')}
                                                    </TableCell>
                                                    <TableCell>{ visualizeDuration(row.averageAttentionTime) }</TableCell>
                                                    <TableCell>
                                                        <Button 
                                                            onClick={() => { buscarVerDetalles(row.fecha); }}
                                                            fullWidth={true}
                                                            style={{ 'marginTop': '25px', marginLeft: '15px', width : '100px' }}
                                                            size="small"
                                                            disabled={!selectedAgente}
                                                            variant="contained" color="primary">
                                                            Ver Detalles
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer> 
                            }
                        </div>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </>
    )
}

export default TiempoDeAtencion