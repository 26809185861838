import React , { useState } from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import CommentIcon from '@material-ui/icons/Comment';
import SendIcon from '@material-ui/icons/Send'
import EditIcon from '@material-ui/icons/Edit';
import ListItemText from '@material-ui/core/ListItemText';
import SearchIcon from '@material-ui/icons/Search';
import { sizing } from '@material-ui/system';
import { Button , TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid ,FormControl, Input, InputLabel, Typography , Tabs, Tab,Box,Paper } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import AddBoxIcon from '@material-ui/icons/AddBox';
import AutoResponsesNew from './AutoResponsesNew';
import AutoResponsesImageNew from './AutoResponsesImageNew';
import moment from 'moment';
import * as authSelectors from '../../../state/auth/selectors';
import * as contactsActions from '../../../state/contacts/actions';
import * as channelActions from '../../../state/channels/actions';
import { useSelector,shallowEqual,useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as campaignsSelector from '../../../state/channels/selectors';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Api from '../../../state/Api';
import * as appActions from '../../../state/app/actions';
import DeleteDialog from './Dialogs/ConfirmDeleteImage';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles(theme => ({
    listaScrolleable: {
        width: '100%',
        maxWidth: 600,
        height : '100%',
        position: 'relative',
        overflowY: 'scroll',
        overflowX: 'hidden',
        flex: 1
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    buscarForm : {
      marginTop: '10px',
      float: 'left',
      marginLeft : '16px'
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    list: {
        width: '500px',
        textAlign: 'center',
        display : 'flex',
        flexFlow: 'column',
        maxHeight : '100%',
        height : '100%'
    },
    fullList: {
        width: 'auto',
    },
    title : {
      padding: '10px'
    },
    root: {
      maxWidth: 345,
      maxHeight : 200
    },
    media: {
      height: 140,
    }
  
}))

const initialState = {
  mouseX: null,
  mouseY: null,
};

export default function AutoResponsesDrawer(props) {
  
  const classes = useStyles(useTheme());
  const [open, setOpen] = useState(false);
  const [isOpenNewResponses, setOpenNewResponses] = useState(false);
  const [isOpenNewResponsesImage, setOpenNewResponsesImage] = useState(false);
  const [searchField, setSearchField] = useState('');
  const [searchFieldImages, setSearchFieldImages] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [commentText,setCommentText] = useState('')
  const [selectedImage,setSelectedImage] = useState('')
  const [selectedResponse, setSelectedResponse] = useState(null);
  const textResponses = useSelector(campaignsSelector.getTextResponseByChannel(props.contact.campaignid));
  const mediaResponses = useSelector(campaignsSelector.getMediaResponseByChannel(props.contact.campaignid));
  const dispatch = useDispatch();
  const me = useSelector(authSelectors.getMe(), shallowEqual);
  const [state, setState] = React.useState(initialState);
  const [ deleteImageId, setDeleteImageId ] = React.useState();
  const [DeleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const isSuper = useSelector(authSelectors.isSuper(), shallowEqual);


  let filtered = textResponses.filter( message => 
    message.message.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 || message.tags.toLowerCase().indexOf(searchField.toLowerCase()) !== -1
  )


  let filteredImages = mediaResponses.filter( message => {
    if(message.name && message.tags){
      return message.name.toLowerCase().indexOf(searchFieldImages.toLowerCase()) !== -1 || message.tags.toLowerCase().indexOf(searchFieldImages.toLowerCase()) !== -1
    }
  })

  const handleAutoResponseCreated = (response) => {
    setOpenNewResponses(false);
    setSelectedResponse(null);
    dispatch(channelActions.addTextResponse(props.contact.campaignid,response))
    setSelectedResponse(null);
   //props.onNewCreated()
  }
  const handleAutoResponseEdit = (response) => {
    setOpenNewResponses(false);
   
    dispatch(channelActions.editTextResponse(props.contact.campaignid,response))
    setSelectedResponse(null);
   //props.onNewCreated()
  }

  

  const handleAutoResponseImageCreated = (response) => {
    dispatch(channelActions.addImageResponse(props.contact.campaignid,response))
    setOpenNewResponsesImage(false);
    //props.onNewCreated()
  }

  const handleAutoResponseClose = (msg) => {
    setOpenNewResponses(false);
    setSelectedResponse(null);
  }

  const handleAutoResponseImageClose = (msg) => {
    setOpenNewResponsesImage(false);
    setSelectedResponse(null);
  }

  const handleEmitirRespuesta = (message) => {
    props.onEmitioRespuesta(message)
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEditar = (response) => {
    setSelectedResponse(response);
    setOpenNewResponses(true);
  };

  const handleModalClose = () => {
    setModalOpen(false)
    console.log('cerro modal de imagenes')
  }

  const sendMessage = (e) => {
    console.log(e)
    dispatch(contactsActions.sendMessage(e))
  }

  const handleNoteAdd = () => {
    sendMessage({
      contactId: props.contact.id,
      campaignid: props.contact.campaignid,
      callerid: props.contact.callerid,
      calledid: props.contact.calledid,
      message: {
        id: '0',
        from: me.username,
        msg: commentText,
        messagereference: selectedImage,
        messagetype:'image',
        fechaCorta: moment().format('DD/MM/YYYY'),
        outbound: true,
        readByAgent: false,
        status: 0,
        isSecret: false,
        sent : false,
        sending : true
      }
    });
    handleModalClose()
    props.onClose()
  }

  const PredefinidasTexto = () => (
    <>
      <Grid xs={10} item>
            <FormControl className={classes.buscarForm}  >  
              {/* <InputLabel htmlFor="input-with-icon-adornment">Filtrar Mensajes</InputLabel> */}
              <Input
                onChange={(event)=> setSearchField(event.target.value)}
                id="input-with-icon-adornment"
                placeholder="Filtrar Mensajes"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              
              />
            </FormControl>
        </Grid>
        <Grid xs={2} item>
          {isSuper &&  (
            <IconButton onClick={() => setOpenNewResponses(true)} color="primary" aria-label="add to shopping cart">
              <AddBoxIcon fontSize="large"  />
          </IconButton>) }
        </Grid>
    </>
  )

  const getShortName = (name) => {
    if(name && name.length > 18){
      return name.substring(0, 18) + '...';
    }else{
      return name;
    }
  }



  const images = filteredImages.map( (image, index) => {
     
    return <Grid xs={4} key={index} onContextMenu={(e) => { handleClickContextMenu(e, image.id) }}  onClick={ () => {setModalOpen(true);setSelectedImage(image.messagereference) }} item>
       <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={image.messagereference}
              title={image.name}
            />
            <CardContent>
              <Typography variant="body2" >
                {getShortName(image.name)}
              </Typography>
              <Typography variant="body2" >
                {image.shortcut ? '!'+image.shortcut : null}
              </Typography>
            
            </CardContent>
          </CardActionArea>
        </Card>

    </Grid> 
  });

  const PredefinidasImagenes = () => (
    <>
      <Dialog maxWidth={'xl'} open={modalOpen} onClose={handleModalClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">AGREGAR IMAGEN</DialogTitle>
          <DialogContent  style={{width: '500px'}}>
            <DialogContentText style={{ textAlign : 'center' }}>
              <img src={selectedImage} style={{ maxWidth : '100%'  }} />
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="Nota"
              // label="OBSERVACIONES"
              value={commentText}
              onChange={({ target: { value } }) => setCommentText(value)}
              onKeyPress={(event, value) => event.key === 'Enter' && commentText ? handleNoteAdd() : null}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleNoteAdd} color="primary" disabled={!commentText}>
              Enviar
            </Button>
          </DialogActions>
      </Dialog>
      <Grid container>
        <Grid xs={10} item>
              <FormControl className={classes.buscarForm}  >  
                {/* <InputLabel htmlFor="input-with-icon-adornment">Filtrar Mensajes</InputLabel> */}
                <Input
                  onChange={(event)=> setSearchFieldImages(event.target.value)}
                  id="input-with-icon-adornment"
                  placeholder="Filtrar Imagenes"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                
                />
              </FormControl>
          </Grid>
          <Grid xs={2} item>
            <IconButton onClick={() => setOpenNewResponsesImage(true)} color="primary" aria-label="add to shopping cart">
                <AddBoxIcon fontSize="large"  />
            </IconButton>
          </Grid>
          
      </Grid>
    </>
  )
  
  const handleClickContextMenu = (event, imageId) => {
    console.log('clickeo')
    setDeleteImageId(imageId)
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };             
  
  const handleCloseMenu = () => {
    setState(initialState)
  }

  const renderPrimary = (message) => {
    if(message.shortcut){
      return message.name + ' /' +  message.shortcut;
    }else{
      return message.name
    }
   
  }

  const handleDeleteItem = () => {
    setDeleteDialogOpen(true)
  }

  const handleAccept = () => {
    Api.req.delete(`/campaigns/${props.campaignId}/templates/media/${deleteImageId}`).then((response) => {
      console.log('borro ccha de tu ma')
      handleCloseMenu()
      setDeleteDialogOpen(false)
      dispatch(appActions.addToast('Se ha borrado la imágen exitosamente'))
      dispatch(channelActions.fetchChannelResponses(props.campaignId) ) 
      //props.onNew(paramsConName)
      //setEnviandoDatos(false)
    })
  }

  const sideList = side => (
     
    <div
      className={classes.list}
      role="presentation"
    > 
    <DeleteDialog open={DeleteDialogOpen} onAccept={handleAccept} onClose={handleCloseMenu} ></DeleteDialog>
    <AutoResponsesNew textResponses={textResponses} selectedResponse={selectedResponse} onNew={handleAutoResponseCreated} onUpdate={handleAutoResponseEdit} campaignId={props.campaignId} isOpen={isOpenNewResponses} onClose={handleAutoResponseClose}   />
    <AutoResponsesImageNew  onNewImage={handleAutoResponseImageCreated} campaignId={props.campaignId} isOpen={isOpenNewResponsesImage} onClose={handleAutoResponseImageClose}   />
          <div>
            <Typography className={classes.title} variant="subtitle1"> Respuestas Predefinidas</Typography>
            <Divider />
            
              <Grid  container>
                <Grid xs={12} item>
                  <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Texto"  />
                    <Tab label="Imagenes"  />
                  </Tabs>
                </Grid>
                
                { tabValue == 0 ? PredefinidasTexto()  : null }
                { tabValue == 1 ? PredefinidasImagenes() : null }


              
              </Grid>
           
          </div>
          { tabValue == 0 ? <List className={classes.listaScrolleable} dense >
                    {filtered.map((value,index) => {
                        const labelId = `checkbox-list-secondary-label-${value}`;
                        return (
                        <ListItem key={index} button>
                            <ListItemIcon>
                              <IconButton  onClick={(event) => handleEditar(value)} edge="end" aria-label="comments">
                                <EditIcon />
                              </IconButton>
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.name} secondary={value.message} />
                            <ListItemIcon>
                             {value.shortcut ? '/' +  value.shortcut : null}
                            </ListItemIcon>
                            <ListItemSecondaryAction style={{ overflowX: 'hidden' }}>
                              <IconButton onClick={(event) => handleEmitirRespuesta(value.message)} edge="end" aria-label="comments">
                                <SendIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        );
                    })}
          </List> : null }

          { tabValue == 1 ? <Grid container className={classes.listaScrolleable}>
            <Menu
              keepMounted
              open={state.mouseY !== null}
              onClose={handleCloseMenu}
              anchorReference="anchorPosition"
              anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                  ? { top: state.mouseY, left: state.mouseX }
                  : undefined
              }
            >
          <MenuItem onClick={handleDeleteItem}><DeleteIcon/>Borrar Imágen</MenuItem>

            </Menu>
            {images}
          </Grid> : null }


    </div>
  );

    

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={props.isOpen}
        onClose={ () => props.onClose() }
        onOpen={  () => setOpen(false)}
      > {sideList('right')}
      </SwipeableDrawer>
    </div>
  );
}
