import  { createMuiTheme } from "@material-ui/core/styles";
import { blue, lightGreen, green, indigo, pink, red } from '@material-ui/core/colors';

const darkTheme = createMuiTheme({
    palette: {
        type: "dark",
        primary: blue, // '#1184dd',
        secondary: pink, //'#e92a61',
        error: red,
        borders: 'solid 1px #222',
        contrastText: '#000',
        contrastThreshold: 3,
        tonalOffset: 0.2,
        scrollBarColor: 'rgba(255,255,255)'
    }
});

export default darkTheme;