import React from 'react';
import { useSelector } from 'react-redux';
import * as contactsSelector from '../../../state/contacts/selectors';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

  const columns = [
    { id: 'name', label: 'Agente', minWidth: 170 },
    { id: 'code', label: 'Duracion', minWidth: 150 },
  ];
  
  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
    tableContainer: {
      height : '100%',
      maxHeight : '300px'
    },
    chip : {
        marginBottom : '10px'
    },
    paperStyle : {
        width : '100%',
        textAlign :'center',
        marginBottom : '10px'
    },
    destacadoNumber : {
       color : '#42a5f5'
    }
  });

const Stats = ( props ) => {

    const classes = useStyles();

    const allMessages = useSelector(contactsSelector.getMessages(props.contact.id));
    const allMessagesSent = useSelector(contactsSelector.getMessagesSent(props.contact.id));
    const averageTime = allMessages.length > 0 ? Math.round(allMessages[0].averageAttentionTime):null;
    const duracion = moment.duration(averageTime, "seconds").format("hh:mm:ss", { trim: false });

    const visualizeDuration = (time) => {
        return moment.duration(time, "seconds").format("hh:mm:ss", { trim: false });
    }

    return (
        <div className={classes.container}>
            <Paper className={classes.paperStyle} elevation={3} >
                <Typography className={classes.destacadoNumber} variant="h2">{duracion}</Typography>
                <Typography >Tiempo Promedio de Respuesta</Typography>
            </Paper>
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { allMessagesSent.map((row,index) => {
                        return row.elapsed > 0 && (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                <TableCell >
                                    { row.from ? row.from : 'AGENTE' }
                                </TableCell>
                                <TableCell>
                                    { visualizeDuration(row.elapsed)  }
                                </TableCell>
                            </TableRow>
                       )
                    }) }
                </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};

export default React.memo(Stats);