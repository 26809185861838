import React from "react";
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, TableContainer, TableBody , Paper, Table, TableHead, TableRow, TableCell, DialogActions } from "@material-ui/core";
import moment from "moment";

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));



const VerDetallesModal = (props) => {
    const [modalStyle] = React.useState(getModalStyle);
    const { onClose, selectedValue, handleClose, open, language } = props;
    const classes = useStyles();
    const [scroll, setScroll] = React.useState('paper');
    const descriptionElementRef = React.useRef(null);
    const rows = props.resultados;

    const visualizeDuration = (time) => {
        return moment.duration(time, "seconds").format("hh:mm:ss", { trim: false });
    }

    return (

        <Dialog
            open={props.open}
            onClose={onClose}
            scroll={scroll}
            maxWidth="lg"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">Ver Detalles </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Agent Id</TableCell>
                                <TableCell>Average Attention Time</TableCell>
                                <TableCell>Campaign</TableCell>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Id Prospecto</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                rows.map(
                                    (result) => 
                                        (
                                        <TableRow >
                                            <TableCell>{result.campaign}</TableCell>
                                            <TableCell>{result.agentid}</TableCell>
                                            <TableCell>{visualizeDuration(result.averageAttentionTime) }</TableCell>
                                            <TableCell>{result.campaign}</TableCell>
                                            <TableCell>{result.fecha}</TableCell>
                                            <TableCell>{result.idprospecto}</TableCell>
                                        </TableRow>
                                        )
                                    
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>     
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cerrar</Button>
            </DialogActions>
        </Dialog>


    )
}

VerDetallesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};


export default VerDetallesModal;