import { combineReducers } from 'redux';

import auth from './auth/reducer';
import app from './app/reducer';
import contacts from './contacts/reducer';
import channels from './channels/reducer';
// import conversation from './conversation/reducer';
import users from './users/reducer';
import admin from './admin/reducer';

export default combineReducers({
  auth,
  app,
  contacts,
  channels,
  admin,
  users
});

