import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Typography,Switch } from "@material-ui/core";
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import StopIcon from '@material-ui/icons/Stop';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditIcon from '@material-ui/icons/Edit';
import * as adminActions from '../../../state/admin/actions';

const CampaignList = ( props ) => {
    const dispatch = useDispatch();

    const handleActivaClick = (e,campaign) => {
        console.log(e)
        e.stopPropagation(); 
        dispatch(adminActions.updateCampaign(campaign.id,!campaign.Activa))
    }

    const campaignList = props.campaigns ? props.campaigns.map((campaign, index) => {
            return(
                <ListItem style={{'cursor':'pointer'}}  selected={props.selectedCampaign == campaign} onClick={() => props.onItemClick(campaign)} key={index}>
               
                    <ListItemText 
                            primary={campaign.Nombre}
                            secondary={ campaign.Descripcion } 
                        />
                    <ListItemIcon>
                        {/* {campaign.Activa ? 
                        <StopIcon onClick={(e) =>handleActivaClick(e,campaign)}/> 
                        : <PlayCircleFilledWhiteIcon onClick={(e) =>handleActivaClick(e,campaign)}/>} */}
                        <Switch size="small" color="secondary" onClick={(e) => handleActivaClick(e,campaign)} checked={campaign.Activa}/>
                    </ListItemIcon>
                </ListItem>
            ) 
    }) : null;


    return(
        <>
            <List >
                {campaignList}
            </List>
        </>
    )
}

export default CampaignList;