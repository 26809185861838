import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Table, TableBody, Paper, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Row from './CampanaRow';

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    counter: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
        },
    },
}));



const CampanasTable = (props) => {
    const classes = useStyles();
    const resultados = props.campanas;
    

    return (
        <>
            <div style={{ margin: 10, marginTop: 5 }}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Desplegar </TableCell>                       
                                <TableCell>Nombre </TableCell>                               
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resultados.map((row, index) => (
                                <Row key={index} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>
    );
}

export default CampanasTable