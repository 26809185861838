import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import * as appActions from '../../../state/app/actions';
import Api from '../../../state/Api';
import * as channelActions from '../../../state/channels/actions';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

function getModalStyle() {
  const top = 50 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const FacebookPagesModal = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { onClose, selectedValue, open } = props;
    const [ selectedPage, setSelectedPage ] = React.useState(false);
    const [modalStyle] = React.useState(getModalStyle);

    const handleClose = (motivo) => {
      props.onClose()
    };

    const getSelectedInstagramAccount = () => {
      if(selectedPage){
          return <List>
              <ListItem disabled={true}>
                <ListItemIcon>
                  <InstagramIcon />
                </ListItemIcon>
                <ListItemText primary={selectedPage.name} />
              </ListItem>
          </List>
      }
      
    }

    const aceptar = (pagina) => {
      let pageId  = pagina.instagram_business_account.id;
        Api.req.post(`campaigns/external/configure`, {
          nombre: pagina.name,
          appid: pagina.id,
          pageid: pageId,
          accessToken: pagina.access_token
        }).then(
          () => {
            dispatch(channelActions.fetchChannels(props.me.username))
            dispatch(appActions.setAccesToken(pagina.access_token))
            props.onClose()
          }
        )
    }

    const handlePageClick = (pagina) => {
      setSelectedPage(pagina)        
    }
  
    return (
        
       <Modal  
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div style={modalStyle} className={classes.paper} >
          <Typography style={{ fontSize : '18px' }}>Seleccione la página de Facebook que quiere utilizar</Typography>
          <List>
            {props.paginas.map((pagina,index) => (
              
              <ListItem button onClick={() => handlePageClick(pagina)} key={index}>
                <ListItemIcon>
                  <FacebookIcon />
                </ListItemIcon>
                <ListItemText primary={pagina.name} />
              </ListItem>
            ))}
          </List>
          <Typography  style={{ fontSize : '16px' }}>Cuenta de Instagram</Typography>
    
           { getSelectedInstagramAccount() }
          <Button disabled={!selectedPage} style={{ marginTop : '10px' }} onClick={() => aceptar(selectedPage)} variant="contained" color="primary">
            Aceptar
          </Button>
        </div>
      </Modal>
    );
  }
  
FacebookPagesModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default FacebookPagesModal;