import React, {useState, useEffect, useCallback} from 'react';

import { Button , Input, IconButton, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

const ModalSendImage = ( props ) => {
    const [commentText,setCommentText] = useState('')
    const handleClose = () => {
        setCommentText('')
        props.onClose()
    }

    const sendMessage = () => {
        props.onSend(commentText)
        setCommentText('')
    }
    return (
        <>
            <Dialog maxWidth={'xl'} style={{zIndex: 1500}} open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">AGREGAR IMAGEN</DialogTitle>
                <DialogContent  style={{width: '500px'}}>
                    <DialogContentText style={{ textAlign : 'center' }}>
                    <img src={props.selectedImageIn} style={{ maxWidth : '100%'  }} />
                    </DialogContentText>
                    <TextField
                    autoFocus
                    margin="dense"
                    id="Nota"
                    // label="OBSERVACIONES"
                    value={commentText}
                    onChange={({ target: { value } }) => setCommentText(value)}
                    onKeyPress={(event, value) => event.key === 'Enter' && commentText ? sendMessage() : null}
                    fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                    Cancelar
                    </Button>
                    <Button onClick={() => sendMessage()} color="primary" disabled={!commentText}>
                    Enviar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModalSendImage;