import  { createMuiTheme } from "@material-ui/core/styles";
import { blue, lightGreen, green, indigo, pink, red } from '@material-ui/core/colors';  

const lightTheme = createMuiTheme({
    palette: {
        type: "light",
        primary: blue, // '#1184dd',
        secondary: pink, //'#e92a61',
        error: red,
        borders: 'solid 1px #e5e5e5',
        contrastText: '#000',
        contrastThreshold: 3,
        tonalOffset: 0.5,
        scrollBarColor: 'rgba(55,55,55,.5)'
    }
});

export default lightTheme;