import React,{ useState ,useEffect} from "react";
import { Select, MenuItem, Box, FormControl, InputLabel, Input, DialogContent, Button, TextField, Switch, FormControlLabel } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import 'moment/locale/es';
import {
    LocalizationProvider,
    DatePicker,
  } from '@material-ui/pickers';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import MomentUtils from '@date-io/moment';
import Api from '../../../state/Api';
import * as adminActions from '../../../state/admin/actions';
import * as appActions from '../../../state/app/actions';

const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginRight: theme.spacing(1),
      width: 200,
    },
    cien : { 
        width : '100%'
    },
    mBottom : { 
        marginBottom : '20px'
    }
}));

const Configuracion = (props) => {
    const classes = useStyles();
    
    const selectedCampaign = props.selectedCampaign;
    const [campaignId, setCampaignId] = useState( '');
    const [tipo, setTipo] = useState('');
    const [algoritmo, setAlgoritmo] = useState( '');
    const [realtime, setRealTime] = useState(false);
    const [priority, setPriority] = useState(selectedCampaign.priority || '');
    const [textoInteraccion, setTextoInteraccion] = useState(selectedCampaign.textoInteraccion || '');
    const [messageDue, setMessageDue] = useState(selectedCampaign.messageDue || '');
    const [textLength, setTextLength] = useState(selectedCampaign.textLength || '');
    const [acceptanceThreshold, setAcceptanceThreshold] = useState(selectedCampaign.acceptanceThreshold || '');
    const [offlineMessage, setOfflineMessage] = useState(selectedCampaign.offlineMessage || '');
    const [thankyouMessage, setThankYouMessage] = useState(selectedCampaign.thankyouMessage || '');
    const [automaticGreeting, setAutomaticGreeting] = useState(false);
    const [greetingMessage, setGreetingMessage] = useState(selectedCampaign.greetingMessage || '');
    const [welcomeMsg, setWelcomeMsg] = useState(selectedCampaign.welcomeMsg || '');
    const [triggerTimer, setTriggerTimer] = useState(selectedCampaign.triggerTimer || '');
    const [triggerMsg, setTriggerMsg] = useState(selectedCampaign.triggerMsg || '');
    const [enableMTM, setEnableMTM] = useState(false);
    const [maxTimeShift, setMaxTimeShift] = useState(selectedCampaign.maxTimeShift || '');
    const [sendAudio, setSendAudio] = useState(false);
    const [sendImage, setSendImage] = useState(false);
    const [sendFile, setSendFile] = useState(false);
    const [sendVideo, setSendVideo] = useState(false);
    const [activeGeo, setActiveGeo] = useState(false);
    const [tipos, setTipos] = useState([]);
    const [algoritmos, setAlgoritmos] = useState([]);


    const [DDE, setDDE] = useState(selectedCampaign.DDE || '');
   
    const [pageId, setPageId] = useState(selectedCampaign.pageId || '');

    const dispatch = useDispatch();

    const guardarCambios = () => {
    
        let params = {
            tipo : tipo,
            algoritmo : algoritmo,
            priority : priority,
            realtime : realtime,
            textoInteraccion : textoInteraccion,
            messageDue : messageDue,
            textLength : textLength,
            acceptanceThreshold : acceptanceThreshold,
            offlineMessage : offlineMessage,
            thankyouMessage : thankyouMessage,
            automaticGreeting : automaticGreeting,
            greetingMessage : greetingMessage,
            welcomeMsg : welcomeMsg,
            triggerTimer : triggerTimer,
            triggerMsg : triggerMsg,
            enableMTM : enableMTM,
            maxTimeShift : maxTimeShift,
            sendImage : sendImage,
            sendFile : sendFile,
            sendVideo : sendVideo,
            sendAudio : sendAudio,
            activeGeo : activeGeo
        }

        Api.req.put(`admin/campaign/${props.selectedCampaign.id}/config`, params).then(
            (response)=> {
                dispatch(appActions.addToast('Se han guardado los cambios'))
            }
        ).catch((error) => {
            console.log('catch')
            dispatch(appActions.addToast('Ha habido un error al guardar los datos', 'error'))
        })
    }

    const loadTipos = () => {
        Api.req.get(`admin/types`).then(
            (response)=> {
                setTipos(response.data.data)
            }
        )
    }

    const loadAlgoritmos = () => {
        Api.req.get(`admin/routing`).then(
            (response)=> {
                setAlgoritmos(response.data.data)
            }
        )
    }

    useEffect(() => {
        loadTipos();
        loadAlgoritmos();
    }, []);

    useEffect(() => { 

        if(props.selectedCampaign){
            Api.req.get(`admin/campaign/${props.selectedCampaign.id}/config`).then(
                (response)=> {
                   
                    let campaignData =  response.data.data[0];


                    setCampaignId(campaignData.CampaignId )
                    setTipo(campaignData.Tipo )
                    setAlgoritmo(campaignData.Algoritmo || '')
                    setPriority(campaignData.Priority || '')
                    setRealTime(campaignData.Realtime || '')
                    setTextoInteraccion(campaignData.textoInteraccion || '')
                    setMessageDue(campaignData.MessageDue || '')
                    setTextLength(campaignData.textLength)
                    setAcceptanceThreshold(campaignData.acceptanceThreshold || '')
                    setOfflineMessage(campaignData.offlineMsg || '')
                    setThankYouMessage(campaignData.thankyouMsg || '')
                    setAutomaticGreeting(campaignData.automaticGreeting)
                    setGreetingMessage(campaignData.greetingMsg || '')
                    setWelcomeMsg(campaignData.welcomeMsg || '')
                    setTriggerTimer(campaignData.triggerTimer || '')
                    setTriggerMsg(campaignData.triggerMsg || '')
                    setEnableMTM(campaignData.enableMTM )
                    setMaxTimeShift(campaignData.maxTimeShift || '')
                    setSendImage(campaignData.sendImage)
                    setSendFile(campaignData.sendFile )
                    setSendVideo(campaignData.sendVideo )
                    setSendAudio(campaignData.sendAudio)
                    setActiveGeo(campaignData.activeGeo)
                }
            )
        }
    }, [props.selectedCampaign]);

    return(
        <div style={{'overflowY':'scroll','height':'85%','padding':'10px'}}>
            <Box width={3/4} className={classes.mBottom} >
                <FormControl className={classes.cien} >
                    <InputLabel htmlFor="tipo">CampaignId</InputLabel>
                    <Input
                    onChange={(event)=> setCampaignId(event.target.value)}
                    value={campaignId}
                    readOnly={true}
                    id="campaignId"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien} >
                    <InputLabel id="tipo">Tipo</InputLabel>
                    <Select
                        id="tipo"
                        value={tipo}
                        onChange={(event)=> setTipo(event.target.value)}
                        >
                            {tipos.map((option,index) => (
                                <MenuItem key={index} value={option.id}>
                                {option.descripcion}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                <InputLabel id="tipo">Algoritmo</InputLabel>
                    <Select
                        id="algoritmo"
                        value={algoritmo}
                        onChange={(event)=> setAlgoritmo(event.target.value)}
                        >
                            {algoritmos.map((option,index) => (
                                <MenuItem key={index} value={option.id}>
                                {option.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl>
                    <FormControlLabel
                        value="Enable MTM"
                        control={<Switch size="small" color="secondary" onClick={(e) => setRealTime(!realtime)} checked={realtime}/>}
                        label="Realtime"
                        labelPlacement="top"
              
                     />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="descripcion">Priority</InputLabel>
                    <Input
                    onChange={(event)=> setPriority(event.target.value)}
                    value={priority}
                    
                    id="descripcion"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="minimo">Texto Interacción</InputLabel>
                    <Input
                    value={textoInteraccion}

                    onChange={(event)=> setTextoInteraccion(event.target.value)}
                    id="minimo"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="maximo">Message Due</InputLabel>
                    <Input
                    value={messageDue}

                    onChange={(event)=> setMessageDue(event.target.value)}
                    id="messageDue"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="maximo">Text Length</InputLabel>
                    <Input
                    value={textLength}

                    onChange={(event)=> setTextLength(event.target.value)}
                    id="textLength"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="activa">Acceptance Threshold</InputLabel>
                    <Input
                    value={acceptanceThreshold}
                    onChange={(event)=> setAcceptanceThreshold(event.target.value)}
                    id="acceptanceThreshold"
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="visible">Offline Message</InputLabel>
                    <Input
                     multiline
                     rows={2}
                    value={offlineMessage}
                    onChange={(event)=> setOfflineMessage(event.target.value)}
                    id="offlineMessage"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="saltearPor">Thankyou Message</InputLabel>
                    <Input
                    value={thankyouMessage}
                    multiline
                    rows={2}
                    onChange={(event)=> setThankYouMessage(event.target.value)}
                    id="thankyouMessage"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>

            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="saltearPor">Welcome Message</InputLabel>
                    <Input
                    value={welcomeMsg}
                    multiline
                    rows={2}
                    onChange={(event)=> setWelcomeMsg(event.target.value)}
                    id="thankyouMessage"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
           
            <Box width={3/4} className={classes.mBottom}>
                <FormControl >
                    <FormControlLabel
                        value="Send Image"
                        control={<Switch size="small" color="secondary" onClick={(e) => setAutomaticGreeting(!automaticGreeting)} checked={automaticGreeting}/>}
                        label="Automatic Greeting"
                        labelPlacement="top"
                     />

                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="realtime">Greeting Message</InputLabel>
                    <Input
                    value={greetingMessage}
                    multiline
                    rows={2}
                    onChange={(event)=> setGreetingMessage(event.target.value)}
                    id="realtime"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="priority">Trigger Timer</InputLabel>
                    <Input
                    value={triggerTimer}
                    onChange={(event)=> setTriggerTimer(event.target.value)}
                    id="priority"
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="faIcon">Trigger Msg</InputLabel>
                    <Input
                     multiline
                     rows={2}
                    value={triggerMsg}
                    onChange={(event)=> setTriggerMsg(event.target.value)}
                    id="faIcon"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl >
                    <FormControlLabel
                        value="Enable MTM"
                        control={<Switch size="small" color="secondary" onClick={(e) => setEnableMTM(!enableMTM)} checked={enableMTM}/>}
                        label="Enable MTM"
                        labelPlacement="top"
                     />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl className={classes.cien}>
                    <InputLabel htmlFor="bgFontColor">Max Time Shift</InputLabel>
                    <Input
                    value={maxTimeShift}
                    onChange={(event)=> setMaxTimeShift(event.target.value)}
                    id="bgFontColor"
                    fullWidth={true}
                    />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl>
                    <FormControlLabel
                        value="Send Image"
                        control={<Switch size="small" color="secondary" onClick={(e) => setSendImage(!sendImage)} checked={sendImage}/>}
                        label="Realtime"
                        labelPlacement="top"
                     />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl>
                    <FormControlLabel
                        value="Send File"
                        control={<Switch size="small" color="secondary" onClick={(e) => setSendFile(!sendFile)} checked={sendFile}/>}
                        label="SendFile"
                        labelPlacement="top"
                     />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl >
                    <FormControlLabel
                        value="Send Video"
                        control={<Switch size="small" color="secondary" onClick={(e) => setSendVideo(!sendVideo)} checked={sendVideo}/>}
                        label="SendFile"
                        labelPlacement="top"
                     />
                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl >
                    <FormControlLabel
                        value="Send Audio"
                        control={<Switch size="small" color="secondary" onClick={(e) => setSendAudio(!sendAudio)} checked={sendAudio}/>}
                        label="SendFile"
                        labelPlacement="top"
                     />

                </FormControl>
            </Box>
            <Box width={3/4} className={classes.mBottom}>
                <FormControl >
                    <FormControlLabel
                        value="Active Geo"
                        control={<Switch size="small" color="secondary" onClick={(e) => setActiveGeo(!activeGeo)} checked={activeGeo}/>}
                        label="Active Geo"
                        labelPlacement="top"
                     /> 
                </FormControl>
            </Box>
            <Box style={{'marginTop':'10px'}}>
                <Button onClick={ () => guardarCambios()} variant="contained" color="primary">
                    Guardar Cambios
                </Button>
            </Box>
        </div>
    )
}

export default Configuracion;