import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import * as appActions from '../../state/app/actions';
import { useOnMount } from '../../hooks';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const SECONDS_TO_DISMISS = 6;

const ToastWrapper = styled.div`
  background: none;
  color: white;
  width: auto;
  pointer-events: visible;
  align-self: center;
  cursor: pointer;
  margin-bottom: 10px;
  z-index : 99999;
`;

const Toast = ({ index, children , id, type = 'success' }) => {
  const dispatch = useDispatch();
  const clear = () => dispatch(appActions.removeToast(id));

  useOnMount(() => {
    const timer = setTimeout(clear, SECONDS_TO_DISMISS * 1000);
    return () => clearTimeout(timer);
  });

  return (
    <ToastWrapper onClick={clear}>
      <Alert severity={type}>{children}</Alert>
    </ToastWrapper>
  );
};

Toast.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

export default Toast;
