import { ADD_CHANNEL_SUCCESS, FETCH_CHANNELS_SUCCESS, FETCH_CHANNELS_FAILURE,
  FETCH_CHANNELS_TEXT_RESPONSES_FAILURE,FETCH_CHANNELS_TEXT_RESPONSES_SUCCESS, FETCH_CHANNELS_TEXT_RESPONSES_REQUEST,
  FETCH_CHANNELS_MEDIA_RESPONSES_FAILURE,FETCH_CHANNELS_MEDIA_RESPONSES_SUCCESS, FETCH_CHANNELS_MEDIA_RESPONSES_REQUEST,
  EDIT_TEXT_RESPONSE, ADD_TEXT_RESPONSE, ADD_IMAGE_RESPONSE, FETCH_CHANNEL_MEDIA_RESPONSES_FAILURE, FETCH_CHANNEL_MEDIA_RESPONSES_SUCCESS } from './constants';
import { produce } from 'immer';

const INITIAL_STATE = {
  loading: false,
  channels: []
};

export default (state = INITIAL_STATE, action) => {
  // console.log(state);
  switch(action.type) {

    case EDIT_TEXT_RESPONSE:
      return produce(state, (draftState) => {
        const index = draftState.channels.findIndex(campaign => (
          action.payload.campaignId === campaign.id
        ));

        const indexTextResponse = draftState.channels[index].textResponses.findIndex(campaign => (
          action.payload.response.id === campaign.id
        ));

        draftState.channels[index].textResponses[indexTextResponse].name = action.payload.response.title;
        draftState.channels[index].textResponses[indexTextResponse].message = action.payload.response.message;
        draftState.channels[index].textResponses[indexTextResponse].tags = action.payload.response.tags;
        draftState.channels[index].textResponses[indexTextResponse].shortcut = action.payload.response.shortcut;

      });   

    case ADD_TEXT_RESPONSE:
      return produce(state, (draftState) => {
        const index = draftState.channels.findIndex(campaign => (
          action.payload.campaignId === campaign.id
        ));
        draftState.channels[index].textResponses.push(action.payload.response)
      });

    case ADD_IMAGE_RESPONSE:
        return produce(state, (draftState) => {
          const index = draftState.channels.findIndex(campaign => (
            action.payload.campaignId === campaign.id
          ));
          draftState.channels[index].mediaResponses.push(action.payload.response)
        });

    case FETCH_CHANNELS_SUCCESS:
      // console.dir('entro en el reducer');
      // console.dir(action.payload)
      return produce(state, (draftState) => {
        draftState.channels = action.payload
      });

    case ADD_CHANNEL_SUCCESS:
      // console.dir('entro en el reducer');
        return produce(state, (draftState) => {
          const index = draftState.contacts.findIndex(contact => (
            action.payload.contactId === contact.id
          ));
          // console.log(draftState.contacts);
          draftState.contacts[index].messages.push(action.payload.message)
        });

    case FETCH_CHANNELS_TEXT_RESPONSES_SUCCESS:
      // console.dir('entro en el reducer');
        return produce(state, (draftState) => {
          let responses = action.payload.responses
          action.payload.campaigns.forEach(
            (campaign,indexCampaign) => {
              const index = draftState.channels.findIndex(currentCampaign => ( currentCampaign.id === campaign.id));
              if(responses[indexCampaign].data.data){
                draftState.channels[index].textResponses = responses[indexCampaign].data.data;
              }else{
                draftState.channels[index].textResponses = [];
              }
            }
          )
        });

    case FETCH_CHANNELS_MEDIA_RESPONSES_SUCCESS:
          // console.dir('entro en el reducer');
            return produce(state, (draftState) => {
              let responses = action.payload.responses;
              action.payload.campaigns.forEach(
                (campaign,indexCampaign) => {
                  const index = draftState.channels.findIndex(currentCampaign => ( currentCampaign.id === campaign.id));
                  if(responses[indexCampaign].data.data){
                    draftState.channels[index].mediaResponses = responses[indexCampaign].data.data;
                  }else{
                    draftState.channels[index].mediaResponses = [];
                  }
                }
              )
            }); 
    
      case FETCH_CHANNEL_MEDIA_RESPONSES_SUCCESS:
        // console.dir('entro en el reducer');
          return produce(state, (draftState) => {
            let response = action.payload.response;
            const index = draftState.channels.findIndex(currentCampaign => ( currentCampaign.id === action.payload.campaignId));
            draftState.channels[index].mediaResponses = response.data.data;
        }); 

    case FETCH_CHANNELS_FAILURE:
      console.dir(action.payload);
        return produce(state, (draftState) => {
          console.log('channels failure')
          //draftState.contacts[action.i].messages.push(action.payload)
        });

    default: return state;
  }
}
