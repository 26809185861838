import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Typography, Paper, Chip, Avatar } from '@material-ui/core';
import moment from 'moment';  

import SupervisorAgentMenu from './SupervisorAgentMenu';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  counter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
  },  
}));

function createData(name, status, calories, fat, carbs, protein) {
  return { name, status, calories, fat, carbs, protein };
}

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const renderOnlineBadge = (ispaused) =>{
  if(ispaused){
    return (
      <>
        <Chip label="En Pausa" style={{ backgroundColor: 'red'}} />
      </>
    )
  }
  if(ispaused == null){
    return (
      <>
        <Chip label="Offline" style={{ backgroundColor: '#ccc', color: '#000'}} />
      </>
    )
  }
  if(!ispaused){
    return (
      <>
        <Chip label="Online" style={{ backgroundColor: 'rgb(76, 175, 80)'}} />
      </>
    )
  }
}

const Agents = (props) => {
  const classes = useStyles();



  return (
    <>
    <div style={{margin: 10, marginTop: 5}}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Agente</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Asignados</TableCell>
              <TableCell align="center">Mensajes Totales</TableCell>
              <TableCell align="center">Finalizados</TableCell>
              <TableCell align="center">Ultima Interacción</TableCell>
              <TableCell align="center">-</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.agentStats.map( (row,index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row" style={{display: 'flex', flexDirection: 'row', alighContent: 'center', alignItems: 'center'}}>
                <Avatar style={{ marginRight: 10}}>{row.displayname[0]}</Avatar> <Typography variant="h6">{row.displayname}</Typography>
                </TableCell>
                <TableCell align="center">{renderOnlineBadge(row.ispaused)}</TableCell>
                <TableCell align="center">{row.Asignados}</TableCell>
                <TableCell align="center">{row.totalMensajes}</TableCell>
                <TableCell align="center">{row.Finalizados}</TableCell>
                <TableCell align="center">{ moment(row.ultimaInteraccion).calendar() }</TableCell>
                <TableCell align="center">< SupervisorAgentMenu buscarPorFecha={props.buscarPorFecha} username={row.usuario} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
    </>
  );
}

export default Agents