import React, { useState } from "react";
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {Tabs, Tab,Box,Paper, } from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';


import AgentsActivity from './AgentsActivity';
import AgentsActivityPeriodo from './AgentsActivityPeriodo';
import EventosAgentes from './EventosAgentes';

import Api from '../../../state/Api';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// import {
//   curveCatmullRom,
//   area,
// } from 'd3-shape';
// import { scalePoint } from 'd3-scale';

import MomentUtils from '@date-io/moment';

import * as contactsActions from '../../../state/contacts/actions';
import * as conversationActions from '../../../state/conversation/actions';
import * as contactsSelector from '../../../state/contacts/selectors';
import * as campaignsSelector from '../../../state/channels/selectors';

import * as appActions from '../../../state/app/actions'; 
import * as authSelectors from '../../../state/auth/selectors';
import moment from 'moment';  

import { makeStyles } from '@material-ui/core/styles';
import ReportesCampanasAgentes from "./ReportesCampanasAgentes/ReporteCampanasAgentes";
import TiempoDeAtencion from "./TiempoDeAtencion/TiempoDeAtencion";
import TiempoEnPausa from "./TiempoEnPausa/TiempoEnPausa";

const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginRight: -drawerWidth
  },
  formControl: {
    margin: theme.spacing(1.5),
    minWidth: '90%',
  },
  cien: {
    margin: theme.spacing(1.5),
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  counter: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center'
    },
  },  
}));



const AgentsDashboard = ( props ) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const campaigns = useSelector(campaignsSelector.getChannels());
  //const chatsPublish = props.todas ? allChatsFiltered : allChats;
  const [fechaDesde, setFechaDesde] = useState(moment().utcOffset(0, true).format("YYYY-MM-DD"));
  const [fechaHasta, setFechaHasta] = useState(moment().utcOffset(0, true).format("YYYY-MM-DD"));
  const [isExpanded, setExpanded] =  useState(false); 
  const [value, setValue] = useState(0);

  const handleExpandClick = () => {
    props.handleChangeMenuState(); 
    setExpanded(!isExpanded);
  }

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
        <Tab label="Ingresos/Egresos"  />
        <Tab label="Reporte Ingresos"/>
        <Tab label="Actividad Agentes"/>
        <Tab label="Reporte Campañas Agentes" /> 
        <Tab label="Tiempo de Atención" /> 
        <Tab label="Tiempo en Pausa" /> 
      </Tabs>
      <Box  style={{'height' : '100%',padding : '10px'}} >
          { value == 0 ? <AgentsActivity contact={props.contact} ></AgentsActivity>  : null }
          { value == 1 ? <AgentsActivityPeriodo></AgentsActivityPeriodo> : null  }
          { value == 2 ? <EventosAgentes></EventosAgentes> : null }
          { value == 3 ? <ReportesCampanasAgentes></ReportesCampanasAgentes> : null }
          { value == 4 ? <TiempoDeAtencion></TiempoDeAtencion> : null }
          { value == 5 ? <TiempoEnPausa></TiempoEnPausa> : null }
      </Box>
    </>
  )
}

export default AgentsDashboard