import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';

const LogoutDialog = (props) => {

    const handleClose = () => {
      props.onClose();
    };
  
    const handleLogOut = () => {
      props.onCloseLogout();
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
        <DialogTitle id="simple-dialog-title">Confirma desloguearse de Facebook</DialogTitle>
        <List>
            <ListItem button onClick={() => handleLogOut()}>
                <ListItemText primary="SI" />
            </ListItem>
            <ListItem button onClick={() => handleClose()} >
                <ListItemText primary="NO" />
            </ListItem>
        </List>
      </Dialog>
    );
  }
  
  LogoutDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCloseLogout: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  

  export default LogoutDialog;