export const ADD = 'users/ADD';

export const USER_QUEUE_ADD = 'users/USER_QUEUE_ADD';
export const USER_QUEUE_CLEAR = 'users/USER_QUEUE_CLEAR';
export const FETCHING_USERS = 'users/FETCHING_USERS';

export const ONLINE_LIST = 'users/ONLINE_LIST';
export const ONLINE = 'users/ONLINE';
export const REQUEST_ALL_USERS = 'users/REQUEST_ALL_USERS';
export const RECEIVED_ALL_USERS = 'users/RECEIVED_ALL_USERS';
export const INVALIDATE_ALL_USERS = 'users/INVALIDATE_ALL_USERS';

export const OFFLINE = 'users/OFFLINE';
export const PAUSE_SUCCESS = 'users/PAUSE_SUCCESS';
export const PAUSE_FAILED = 'users/PAUSE_FAIL';

export const INIT_PAUSA = 'users/INIT_PAUSA';
export const INIT_ESTADO_PAUSA = 'users/INIT_ESTADO_PAUSA';
export const SET_PAUSA_LOGIN = 'users/SET_PAUSA_LOGIN';