import produce from 'immer';

import {
  ADD,
  USER_QUEUE_ADD,
  USER_QUEUE_CLEAR,
  FETCHING_USERS,
  RECEIVED_ALL_USERS,
  REQUEST_ALL_USERS,
  INVALIDATE_ALL_USERS,
  ONLINE_LIST,
  ONLINE,
  OFFLINE,
  ALL_USERS,
  PAUSE_SUCCESS,
  INIT_PAUSA,
  INIT_ESTADO_PAUSA,
  SET_PAUSA_LOGIN
} from './constants';

export const initialState = {
  data: {},
  config : { pausa : true, estadoPausa : false },
  online: [],
  users : {
    isFetching : false,
    didInvalidate : false,
    items : []
  },
  userFetchQueue: new Set(),
  userFetching: false,
};

const reducer = (state = initialState, action) => produce(state, (draft) => {
  switch (action.type) {
    case ADD:
      Object.values(action.data || {}).forEach((user) => {
        draft.data[user.userId] = {
          ...draft.data[user.userId],
          ...user,
        };
      });
      break;

    case INIT_PAUSA:
      if(localStorage.getItem('pausa')){
        draft.config.pausa = JSON.parse(localStorage.getItem('pausa'));
        draft.config.estadoPausa = JSON.parse(localStorage.getItem('estadoPausa'));
      }else{
        localStorage.setItem('pausa', draft.config.pausa.toString());
      }
      break;

    case USER_QUEUE_ADD:
      draft.userFetchQueue.add(action.id);
      break;

    case FETCHING_USERS:
      draft.userFetching = action.fetching;
      break;

    case USER_QUEUE_CLEAR:
      draft.userFetchQueue.clear();
      break;

    case ONLINE_LIST:
      draft.online = action.data;
      break;

    case REQUEST_ALL_USERS:
      draft.users.isFetching = true;
      break;    

    case RECEIVED_ALL_USERS:
      draft.users.isFetching = false;
      draft.users.items = [...action.payload];
      break;  

    case INVALIDATE_ALL_USERS:
      console.log('pasa x invalidate')
      draft.users.didInvalidate = true;
      break;    

    case ONLINE:
      draft.online.push(action.payload);
      break;

    case OFFLINE:
      draft.online.splice(draft.online.findIndex(id => id === action.userId), 1);
      break;

    case PAUSE_SUCCESS:
      draft.config.pausa = action.data.pause.pause;
      draft.config.estadoPausa = action.data.pause.pause ? action.data.pause.state : false ;
      localStorage.setItem('pausa', draft.config.pausa.toString())
      localStorage.setItem('estadoPausa', draft.config.estadoPausa.toString())
      break;

    case SET_PAUSA_LOGIN:
        draft.config.pausa = true;
        localStorage.setItem('pausa', draft.config.pausa.toString())
        break;         

    default:
  }
});

export default reducer;
