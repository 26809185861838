export const getById = id => ({ users: state }) => {
  const user = state.data[id];
  if (!user) {
    return {
      id,
      username: 'loading...',
      displayname: 'loading...',
      loading: true,
      avatar: '',
    };
  }

  return user;
};

export const usersToFetch = ({ users: state }) => (
  [...state.userFetchQueue].filter(id => !Object.keys(state.data).includes(id))
);

export const isFetching = ({ users: state }) => state.userFetching;

export const isOnline = id => ({ users: state }) => state.online.includes(id);

export const onlineCount = ids => ({ users: state }) => ids
  .filter(id => state.online.includes(id))
  .length;

export const getUsers = () => ({ users: state }) => state.online;

export const getAllUsers = () => ({ users: state }) => state.users

export const getAllOnlineUsers = () => ({ users: state }) => state.online

export const getEstadoPausa = () => ({ users: state }) => state.config.pausa;
