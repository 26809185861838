import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Paper, Input, InputLabel, Select, MenuItem, Fab, Grid, Button, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Badge, CircularProgress, LinearProgress, Tooltip, CardContent, Typography } from "@material-ui/core";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    AreaSeries,
    BarSeries,
    Title,
    Legend
} from '@devexpress/dx-react-chart-material-ui';
import { Stack, Animation, EventTracker } from '@devexpress/dx-react-chart';


import Api from '../../../../state/Api';
import {
    LocalizationProvider,
    DatePicker
} from '@material-ui/pickers';

import {
    curveCatmullRom,
    area,
} from 'd3-shape';
import { scalePoint } from 'd3-scale';

import MomentUtils from '@date-io/moment';
import MomentAdapter from "@material-ui/pickers/adapter/moment";

import * as contactsActions from '../../../../state/contacts/actions';
import * as conversationActions from '../../../../state/conversation/actions';
import * as contactsSelector from '../../../../state/contacts/selectors';
import * as campaignsSelector from '../../../../state/channels/selectors';

import * as appActions from '../../../../state/app/actions';
import * as authSelectors from '../../../../state/auth/selectors';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import CampanasTable from "./CampanasTable";


const drawerWidth = 500;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginRight: -drawerWidth
    },
    formControl: {
        margin: theme.spacing(1.5),
        minWidth: '90%',
    },
    cien: {
        margin: theme.spacing(1.5),
        width: '90%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    counter: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
        },
    },
}));

const legendStyles = () => ({
    root: {
        display: 'flex',
        margin: 'auto',
        flexDirection: 'row',
    },
});
const legendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
);
const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
const legendLabelStyles = () => ({
    label: {
        whiteSpace: 'nowrap',
    },
});
const legendLabelBase = ({ classes, ...restProps }) => (
    <Legend.Label className={classes.label} {...restProps} />
);
const Label = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);



const ReportesCampanasAgentes = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const campaigns = useSelector(campaignsSelector.getChannels());
    //const chatsPublish = props.todas ? allChatsFiltered : allChats;
    const [fechaDesde, setFechaDesde] = useState(moment().startOf('month').utcOffset(0, true).format("YYYY-MM-DD"));
    const [fechaHasta, setFechaHasta] = useState(moment().endOf('month').utcOffset(0, true).format("YYYY-MM-DD"));
    const [selectedCampaign, setSelectedCampaign] = useState(2);
    const [selectedResult, setSelectedResult] = useState('');
    const [resultados, setResultados] = useState([]);
    const [ agentes, setAgentes] = useState([]);
    const [selectedAgente, setSelectedAgente] = useState([]);
    const [resultadosDashboard, setResultadosDashboard] = useState([]);
    const [resultadosHora, setResultadosHora] = useState([]);
    const [resultadosQueue, setResultadosQueue] = useState([]);

    // const { data: chartData } = resultadosHora;
    useEffect(() => {
        Api.req.get(`/supervisor/activeAgents`)
                .then((response) => {
                    setAgentes(response.data.agents)
                    setSelectedAgente('')
        });

    }, [])

    const buscarPorFecha = async () => {
        try {
            await Api.req.get(`/supervisor/stats/agentActivity/byDate?agentid=${selectedAgente}&startDate=${moment(fechaDesde).utcOffset(0, true).format("YYYY-MM-DD")}&endDate=${moment(fechaHasta).utcOffset(0, true).format("YYYY-MM-DD")}`)
                .then((response) => { 

                    if(response.data.total != 0 ){
                        setResultados(response.data.data[0].campañas) 
                    }else{
                        setResultados([])
                    }
                     
                });
        }
        catch (err) { }
    }


    return (
        <>
            <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter}>
                <Grid container spacing={2} style={{ marginRight: 10 }}>

                    <Grid item xs={3} style={{ marginTop: '9px' }}>
                        <InputLabel id="demo-customized-select-label">Agente</InputLabel>
                        <Select
                            labelId="diposition-label"
                            id="diposition"
                            fullWidth={true}
                            value={selectedAgente}
                            onChange={(event) => setSelectedAgente(event.target.value)}
                        >
                            {
                                agentes.length > 0 ? agentes.map((option, index) =>
                                    <MenuItem key={index} value={option.usuario}>{option.usuario}</MenuItem>
                                ) : null}
                            <MenuItem value=''>Seleccione</MenuItem>
                        </Select>
                    </Grid>                    

                    <Grid item xs={2} style={{ marginTop: '9px' }}>
                            <DatePicker
                                renderInput={(props) => <TextField {...props} helperText="" />}
                                disableToolbar
                                variant="inline"
                                fullWidth={true}
                                size="small"
                                inputFormat="DD/MM/YYYY"
                                margin="normal"
                                id="date-picker-inline"
                                className={classes.cien}
                                label="Fecha Desde"
                                autoOk={true}
                                value={fechaDesde}
                                onChange={date => setFechaDesde(date)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                    </Grid>
                    <Grid item xs={2} style={{ marginTop: '9px' }}>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} helperText="" />}
                            disableToolbar
                            variant="inline"
                            fullWidth={true}
                            size="small"
                            inputFormat="DD/MM/YYYY"
                            margin="normal"
                            id="date-picker-inline"
                            className={classes.cien}
                            label="Fecha Hasta"
                            autoOk={true}
                            value={fechaHasta}
                            onChange={date => setFechaHasta(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button onClick={() => { buscarPorFecha(); }}
                            fullWidth={true}
                            style={{ 'marginTop': '25px', marginLeft : '15px' }}
                            size="small"
                            disabled={!selectedAgente}
                            variant="contained" color="primary">
                            Buscar
                        </Button>
                    </Grid>
                </Grid>
                <Grid container >
                    <Grid item xs={12}>
                        <CampanasTable classes={props.classes} buscarPorFecha={buscarPorFecha} campanas={resultados} />
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </>
    )
}

export default ReportesCampanasAgentes