import { Chip,  TextField , Dialog, Grid, InputLabel, Input, DialogTitle, DialogContent, Button, Typography, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import Api from "../../../state/Api";
import { addToast } from "../../../state/app/actions";
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import {  makeStyles } from '@material-ui/core/styles';
import { LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker } from "@material-ui/pickers";


const useStyles = makeStyles((theme) => ({
    counter: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
        }
    }
    }) 
)


function VerCampanaModal(props) {
    const classes = useStyles();
    const campana = props.campana ? props.campana : {};
    const [fechaDesde, setFechaDesde] = React.useState(moment().startOf('month'));
    const [fechaHasta, setFechaHasta] = React.useState(moment().endOf('month'));
    const [resultados, setResultados] = React.useState({});


    const handleClose = () => {
        props.onClose()
    }
    
    const handleBuscar = () => {
        try{
            Api.req.get(`/marketing/36/subcampaigns/${campana.name}/stats?desde=${moment(fechaDesde).format('YYYY-MM-DD')}&hasta=${moment(fechaHasta).format('YYYY-MM-DD') }`)
                .then((response) => {
                    setResultados(response.data.data[0])
                })
        }catch(error){
            console.log(error)
        }
        
    }

    const modifyComma = (value) => {
        var tt = String(value);
        tt = tt.replace(".", ",");
        return tt;
    }

    useEffect ( () => {
        if(props.isOpen){
            setResultados({})
            setFechaDesde(moment().startOf('month'))
            setFechaHasta(moment().endOf('month'))
            handleBuscar()
        }
       
    }, [props.isOpen] )


    return (
        <Dialog fullWidth={true}
            maxWidth={'lg'} onClose={handleClose} open={props.isOpen}>
            <DialogTitle>Detalles Campaña</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={4}>
                        <InputLabel htmlFor="descripcion">Nombre de Campaña</InputLabel>
                        <Input
                            value={campana.name}

                            id="descripcion"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel htmlFor="descripcion">Descripción</InputLabel>
                        <Input
                            value={campana.description}
                            id="descripcion"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel htmlFor="descripcion">Template</InputLabel>
                        <Input
                            value={campana.templateid}
                            id="descripcion"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel htmlFor="descripcion">Fecha Inicio:</InputLabel>
                        <Input
                            value={moment(campana.startdate).calendar()}
                            id="descripcion"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel htmlFor="descripcion">Fecha finalización:</InputLabel>
                        <Input
                            value={moment(campana.enddate).calendar()}
                            id="descripcion"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel htmlFor="descripcion">Estado</InputLabel>
                        <Chip
                            label={campana.enabled ? 'ACTIVA ' : 'PAUSA'}
                            clickable
                            size="small"
                            style={{ marginTop : '5px' }}
                            color={campana.enabled ? 'primary ' : 'secondary'}
                        />
    
                    </Grid>
                    <hr />
                    <Grid item xs={12}>
                        <LocalizationProvider className={classes.cien} dateAdapter={MomentAdapter} >
                            <Grid container>
                                <Grid item xs={3}>
                                    <DatePicker
                                        disableToolbar
                                        renderInput={(props) => <TextField {...props} helperText="" />}
                                        variant="inline"
                                        fullWidth={true}
                                        size="small"
                                        inputFormat="DD/MM/YYYY"
                                        margin="normal"
                                        id="date-picker-inline"
                                        className={classes.cien}
                                        style={{ marginRight: '10px' }}
                                        label="Fecha Desde"
                                        autoOk={true}
                                        value={fechaDesde}
                                        onChange={date => setFechaDesde(date)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <DatePicker
                                        disableToolbar
                                        renderInput={(props) => <TextField {...props} helperText="" />}
                                        variant="inline"
                                        fullWidth={true}
                                        size="small"
                                        inputFormat="DD/MM/YYYY"
                                        margin="normal"
                                        id="date-picker-inline"
                                        className={classes.cien}
                                        label="Fecha Hasta"
                                        autoOk={true}
                                        style={{ marginRight: '10px' }}
                                        value={fechaHasta}
                                        onChange={date => setFechaHasta(date)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button onClick={() => handleBuscar()} variant="contained" color="primary">
                                        Buscar
                                    </Button>
                                </Grid>
                            </Grid>
                            
                        </LocalizationProvider>
                        
                    </Grid>
                    <Grid item xs={12}>
                        <div class={classes.counter}>
                            <Paper elevation={3} >
                                <Typography variant="h4">{resultados.totalEnviados} / {resultados.totalContactos }</Typography>
                                <Typography variant="p">totalEnviados / totalContactos</Typography>
                            </Paper>
                            <Paper elevation={3} >
                                <Typography variant="h2">{resultados.totalPendientes }</Typography>
                                <Typography variant="p">totalPendientes</Typography>
                            </Paper>
                            <Paper elevation={3} >
                                <Typography variant="h2">{resultados.totalErrores}</Typography>
                                <Typography variant="p">totalErrores</Typography>
                            </Paper>
                            <Paper elevation={3} >
                                <Typography variant="h2">{resultados.totalOptedOut}</Typography>
                                <Typography variant="p">totalOptOut</Typography>
                            </Paper>
                            <Paper elevation={3} >
                                <Typography style={{ fontSize: 32 }} variant="h2">$ {modifyComma(resultados.totalCost) } USD</Typography>
                                <Typography variant="p">totalCost</Typography>
                            </Paper>
                        </div>   
                    </Grid>
                    <Grid item xs={4}>
                        <Button style={{ float: 'left' }} variant="contained" color="primary" onClick={handleClose}>Cerrar</Button>
                    </Grid>          
                </Grid>
            </DialogContent>

        </Dialog>
    )
}

export default VerCampanaModal;