import React from 'react';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import * as authSelectors from '../../state/auth/selectors';

const UnauthedRoute = ({ component: Component, ...rest }) => {
  const me = useSelector(authSelectors.getMe(), shallowEqual);

  return (
    <Route
      {...rest}
      render={props => (
        !me
          ? <Component {...props} />
          : <Redirect to="/" />
      )}
    />
  );
};

export default UnauthedRoute;
