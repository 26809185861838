import React, { useState,useCallback, useEffect, forwardRef ,useImperativeHandle, useRef } from "react";
import moment from 'moment';
import { debounce } from "lodash";
import 'moment/locale/es';
import { makeStyles ,withStyles } from "@material-ui/core/styles";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as ListWindow } from 'react-window';
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import DialogActions from '@material-ui/core/DialogActions';

// import UserIcon from "@material-ui/icons/AccountCircle";

import AcceptIcon from "@material-ui/icons/Check";
import RejectIcon from '@material-ui/icons/Delete';

import Chip from '@material-ui/core/Chip';
import MoreVertIcon from '@material-ui/icons/MoreVert';


import { green } from '@material-ui/core/colors';
import Api from '../../../state/Api';
import {
  LocalizationProvider,
  DateTimePicker,
  DatePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SearchIcon from '@material-ui/icons/Search';
import { Input, InputAdornment , FormControl, TextField , Box,InputLabel,Select, Menu, MenuItem, Fab, Grid,Button, Avatar, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Badge, CircularProgress, LinearProgress , Tooltip, CardContent, Typography } from "@material-ui/core";

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import ContactSkeleton from './ContactSkeleton';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

import { useDispatch, useSelector } from 'react-redux';
import * as contactsActions from '../../../state/contacts/actions';
import * as contactsSelector from '../../../state/contacts/selectors';
import * as campaignsSelector from '../../../state/channels/selectors';
import * as contactActions from '../../../state/contacts/actions';
import * as appSelector from '../../../state/app/selectors';

import * as appActions from '../../../state/app/actions';
import * as authSelectors from '../../../state/auth/selectors';
import IconShow from './../../shared/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

moment.updateLocale('es', {
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s:  '1s',
    ss: '%ss',
    m:  '1m',
    mm: '%dm',
    h:  '1h',
    hh: '%dh',
    d:  '1d',
    dd: '%dd',
    M:  '1Mes',
    MM: '%dM',
    y:  '1año',
    yy: '%dY'
  }
});

const useStyles = makeStyles(theme => ({
  iconwhatsapp: {
    backgroundColor: '#25D366',
  },
  iconfacebook: {
    backgroundColor: '#3f51b5',
  },
  iconinsta: {
    backgroundColor: '#eb6d31',
  },  
  iconsms: {
    backgroundColor: '#2196f3',
  },
  iconchat: {
    backgroundColor: '#f50057',
  },
  iconchatoffline: {
    backgroundColor: '#757575',
  },
  fabProgress: {
    color: '#ffcc00',
    position: 'absolute',
    top: -5,
    left: -5,
    zIndex: 1,
  },
  IconSize: {
    fontSize: '2.3rem'
  },
  contactname: {
    marginLeft: 10,
  },
  contactnameSecondaryText:{
    fontSize:'0.9em',
    fontWeight: 'lighter',
    paddingLeft: 10
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  heightAdjust: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 65px)",
    border: theme.palette.borders
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.5)', 
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.type == 'light ' ? 'red' : 'rgba(255,255,255,.5)',
      outline: '1px solid slategrey'
    }
  },
} ));

const StyledBadge3 = withStyles(theme => ({
  badge: {
    backgroundColor: '#f50057',
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    height: '30px',
    borderRadius: '15px',
    padding: '0 4px'
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.9)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.8)',
      opacity: 0.5,
    },
  },
}))(Badge);


const StyledBadge2 = withStyles(theme => ({

  badge: {
    backgroundColor: '#f50057',
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid #f60158',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.9)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.8)',
      opacity: 0.5,
    },
  },
}))(Badge);

const ContactList = forwardRef ( ( props, ref ) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const allChats = useSelector(contactsSelector.getContacts());
  const allChatsFiltered = useSelector(contactsSelector.getContactsFiltered());
  const campaigns = useSelector(campaignsSelector.getChannels());
  const mpCampaign = useSelector(campaignsSelector.getMpCampaignData());

  //const chatsPublish = props.todas ? allChatsFiltered : allChats;
  const [fechaDesde, setFechaDesde] = useState();
  const [fechaHasta, setFechaHasta] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState(0);
  const [selectedResult, setSelectedResult] = useState('');  
  const [resultados,setResultados] = useState([]);
  const [searchField,setSearchField] = useState('');
  const estadoRefreshContactos = useSelector(appSelector.getEstadoContactosRefresh())
  const isSuper = useSelector(authSelectors.isSuper());
  const isAdmin = useSelector(authSelectors.isAdmin());

  const setActiveContact = props.setActiveContact
  const removeActiveContact = props.removeActiveContact
  const me = useSelector(authSelectors.getMe());

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedContactIndex, setSelectedContactIndex] = useState(null);

  const [completed, setCompleted] = useState(0);
  const [openDialog,setOpenDialog] = useState(false);

  const todasBoolean = useRef(props.todas);

  let filtered = allChats.filter( contact => {

    if(contact.surname && contact.name){
      return contact.name.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 || contact.surname.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 || contact.callerid.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 
    }else if(contact.name){
      return contact.name.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 || contact.callerid.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 
    }else{
      return contact.callerid.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 
    }
  })

  useEffect( ()=>{
    setSearchField('')
  } ,[props.todas])

  useEffect(() => {
    if(props.mercadolibre){
      Api.req.get(`/meli/questions/status/ANSWERED`)
    }
  }, [props.mercadolibre])

  function refreshContacts (){

    if (!props.todas && !props.mercadolibre && estadoRefreshContactos){

      dispatch(contactsActions.fetchContacts(me.username));
    }

  }

  useEffect(() => {
   
    const interval = setInterval(() => {
        refreshContacts();
    }, 600000);
    return () => clearInterval(interval);
  },[props.todas, estadoRefreshContactos])

  const cambioCampaign = (campaignId) =>{
    Api.req.get(`/prospectos/campaigns/${campaignId}/resultcodes`)
    .then((response) => {
      setResultados(response.data.data)
    });
  }
 

  const renderIcon = (source) => {
    return  <IconShow source={source} classes={classes}></IconShow>
  };  

  const renderStyle = (source) => {
    switch(source) {
      case 'whatsapp':
      case 'whatsapp-sandbox':
        return classes.iconwhatsapp;
      case 'facebook':
        return classes.iconfacebook;
      case 'instagram': 
        return classes.iconinsta;       
      case 'chat':
        return classes.iconchat;
      case 'chat-offline':
        return classes.iconchatoff;
      case 'sms':
        return classes.iconsms;
      default:
        return '';
    }
  };  

  const buscarPorFecha = () => {
    props.removeActiveContact();
    dispatch(contactsActions.fetchContactsFiltered(fechaDesde,fechaHasta,selectedCampaign,selectedResult))
  }

  const handleSelectedContact = (e, contact,index) => {
    dispatch(contactsActions.fetchMessages(contact, me.username));
    dispatch(contactsActions.fetchClientData(contact));
    setSelectedIndex(contact.id);
    setActiveContact(e, contact);
    setSelectedContactIndex(index)
  }

  useImperativeHandle(  ref , () => ({
    refreshData
  }))

  
  const refreshData = (id) => {
    console.log('paso x refresh data')
    let activeConversation = allChats.find((conversation) => conversation.id == selectedIndex)
    setActiveContact(false, activeConversation);
  }

  const handleRemoveSelectedContact = (e, contact) => {
    removeActiveContact();
  }  

  const handleSelectedResult = (param) => {
    setSelectedResult(param)
  }
  
  const checkDisabled = () => {
    if(!!selectedCampaign && selectedResult !== '') {
      return false
    }else {
      return true
    }
  }

  const request = debounce((value) => {
    setSearchField(value)
  }, 500);

  const requestReadAll = debounce((params) => {
    dispatch(contactsActions.markAllAsRead({ contactId: params.id, agentId: params.agentId, campaignId: params.campaignId }))

    if(props.mercadolibre){

      dispatch(contactsActions.reduceMercadoLibreUnread(params.unread))
    }
    
  }, 3000);

  const debounceRequest = useCallback((value) => request(value), []);

  const debounceReadAllRequest = useCallback((params) => requestReadAll(params), []);

  const updateSearch = (e) => {
    debounceRequest(e.target.value);
  }

  const SimpleDialog = (props) => {
    const classes = useStyles();
    const { onClose, selectedValue, open } = props;
  
    const handleAccept = () => {
      setOpenDialog(false)
      onClose();
    };
  
    const handleCancel = (value) => {
      setOpenDialog(false)
      handleCloseMenu()
    };
  
    return (
      
      <Dialog aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">¿ Está seguro que desea finalizar todas las conversaciones ?</DialogTitle>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };

  const handleFinalizarConversaciones = event => {
    console.log('pasa x handleFinalizarConversaciones')
    //setEnviandoDatos(true)
    let update = {
      status : 10,
      agentRating : 0,
      mood : 0,
      willBuy : 0,
      currentStatus: 0,
      transferAgent : null,
      message : ""
    }

    for(let i = 0 ; i < allChats.length ; i ++) {
      if(allChats[i].status != 0 ){
        dispatch(contactActions.updateContact(allChats[i],props.me.username,update, () => { 
          //setEnviandoDatos(false);
          if(selectedIndex == allChats[i].id){
            props.removeActiveContact();
          }
         
          dispatch(appActions.addToast('Ha finalizado la interacción'))
          dispatch(contactActions.removeContact(allChats[i]))
        },() => {
          //setEnviandoDatos(false);
          dispatch(appActions.addToast('Ha habido un error al actualizar el contacto','error'))
        }))
      }
    }
    setSelectedIndex(null)
    handleCloseMenu()

    
  }

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true)
  }

  const header = () => {
    if(props.todas){
    return (
      <Grid container spacing={2} style={{'padding':'15px'}} >
            <Grid item xs={6}>
                  <InputLabel id="demo-customized-select-label">Campaña</InputLabel>
                  <Select
                    labelId="diposition-label"
                    id="diposition"
                    value={selectedCampaign}
                    onChange={(event) => { setSelectedCampaign(event.target.value); cambioCampaign(event.target.value); } }
                    fullWidth={true}
                  >
                  {
                    campaigns.length > 0 ? campaigns.map((option, index) => 
                    <MenuItem key={index} value={option.id}>{option.Nombre}</MenuItem>
                  ) : null }
                  <MenuItem value={0}>Seleccione</MenuItem>    
                  </Select>
            </Grid>
            <Grid item xs={6}>
            <InputLabel id="demo-customized-select-label">Resultado</InputLabel>
                  <Select
                    labelId="diposition-label"
                    id="diposition"
                    fullWidth={true}
                    value={selectedResult}
                    onChange={(event) => setSelectedResult(event.target.value) }
                  >
                  {
                  resultados.length > 0 ? resultados.map((option, index) => 
                    <MenuItem key={index} value={option.codigo}>{option.descripcion}</MenuItem>
                  ) : null }
                  <MenuItem value=''>Seleccione</MenuItem>    
                  </Select>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider className={classes.cien}  dateAdapter={MomentAdapter} >
                              <DatePicker
                                  disableToolbar
                                  renderInput={(props) => <TextField {...props} helperText=""/>}
                                  variant="inline"
                                  fullWidth={true}
                                  size="small"
                                  inputFormat="DD/MM/YYYY"
                                  margin="normal"
                                  id="date-picker-inline"
                                  className={classes.cien}
                                  label="Fecha Desde"
                                  autoOk={true}
                                  value={fechaDesde}
                                  onChange={ date => setFechaDesde(date) }
                                  KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                  }}
                              />
              </LocalizationProvider>
            </Grid>                      
            <Grid item xs={4}>
              <LocalizationProvider className={classes.cien}  dateAdapter={MomentAdapter}>
                              <DatePicker
                                  disableToolbar
                                  renderInput={(props) => <TextField {...props} helperText=""/>}
                                  variant="inline"
                                  inputFormat="DD/MM/YYYY"
                                  margin="normal"
                                  size="small"
                                  id="date-picker-inline2"
                                  className={classes.cien}
                                  label="Fecha Hasta"
                                  fullWidth={true}
                                  autoOk={true}
                                  value={fechaHasta}
                                  onChange={ date => setFechaHasta(date) }
                                  KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                  }}
                              />
              </LocalizationProvider>
          </Grid>                      
          <Grid item xs={4}>
            <Button onClick={() => buscarPorFecha()} 
              fullWidth={true}
              style={{ 'marginTop':'25px' }} 
              size="small"
              disabled={checkDisabled()}
              variant="contained" color="primary">
              Buscar
            </Button>
          </Grid>
        </Grid>  )
      }else{
        return (
            <Grid container  style={{'paddingTop':'5px', marginBottom: '0px', marginLeft: '5px' }} >
                <Grid xs={9} item>
                    <FormControl className={classes.buscarForm}  >  
                      {/* <InputLabel htmlFor="input-with-icon-adornment">Filtrar Mensajes</InputLabel> */}
                      <Input
                        onChange={updateSearch}
                        id="input-with-icon-adornment"
                        placeholder="Filtrar Contactos"
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                      
                      />
                    </FormControl>
                </Grid>
                {!props.verificados && !props.mercadolibre  ? 
                <Grid xs={3} item>
                  <SimpleDialog  open={openDialog} onClose={handleFinalizarConversaciones} />
                    <IconButton
                      color="secondary"
                      size="small"
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={handleClickMenu}
                      style={{ float : 'right', marginRight : '15px' }}
                    >
                      <MoreVertIcon />
                    </IconButton>     
                    
                    
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                    <MenuItem onClick={handleOpenDialog}>Finalizar Todo</MenuItem>
                    </Menu>
                </Grid> : ''}
            {props.mercadolibre && (isAdmin || isSuper) ? <Grid xs={3} item> <a target="_blank" href={mpCampaign.authURL}><Button variant="contained" color="primary" >Login</Button></a> </Grid> : ''}
            </Grid>
        )
      }
  }

  const getSecondaryText = (data,index) => {
    if(props.todas){
      return (<><Chip component="span" label={data[index].agentid} /> <Chip component="span" color="primary" label={data[index].statusDescription} /></> )
    } else if (props.mercadolibre){
      return (
        <>
          <Chip component="span" size="small" color="primary" label={data[index].statusDescription} />
        </>
      )
    }else{
      return data[index].lastMsg.substring(0,40) + '...';
    }
    
  }

  const getPrimaryText = (name,surname,profile_id, isStaff) => {
    if(name && surname){
      return (<span>{name + ' ' + surname } {getCheckIcon(isStaff) } </span>) 
    }else if(name){
      return (<span>{name } {getCheckIcon(isStaff)} </span>);
    }{
      return (<span>{profile_id} {getCheckIcon(isStaff)} </span>);
    }
    
  }

  const getCheckIcon = (boolean) => {
    if (boolean) {
      return <CheckCircleIcon color="primary" style={{ verticalAlign : 'bottom'}} />
    }else{
      return ''
    }

  }

  const Row = ({ data, index, style }) => {


      if(selectedIndex === data[index].id &&  data[index].unread > 0 ){
        debounceReadAllRequest({ id: data[index].id, agentId: me.username, campaignId: data[index].campaignid, unread: data[index].unread  })
        //dispatch(contactsActions.markAllAsRead({ contactId: data[index].id, agentId: me.username, campaignId: data[index].campaignid }))
      }

      return(    
          <ListItem onClick={e =>  { data[index].status !== 0 ? handleSelectedContact(e, data[index],index) : e.preventDefault() } } selected={selectedIndex === data[index].id} ContainerProps={{ style: style }}   button={data[index].status === 0 ? false : true }   key={data[index].i}  style={{paddingLeft: 5, paddingRight: 0, height: 73, backgroundColor: data[index].transfered ? 'red':'' }} ContainerComponent="div" >
            
                   {/* <Badge badgeContent={contact.unread} color="secondary"> */}
                   <Tooltip title={data[index].source.toUpperCase()}>
                      <Avatar style={{position: 'relative' ,borderRadius: 0, width: 60, height: 60, color: 'white', backgroundColor : '#' + data[index]['mui-bgcolor'] }} className={renderStyle(data[index].source)}>
                        {renderIcon(data[index]['mui-icon'])}
              {data[index].source == 'whatsapp' ? <div style={{ color: 'black', width: '100%', background: '#FFF', position: 'absolute', bottom: 0, fontSize: '12px', textAlign: 'center', padding: '3px' }}>{ data[index].campaignDDE } </div> : ''}   
                      </Avatar>
                    </Tooltip>
                  { data[index].status === 0 && <CircularProgress size={50} className={classes.fabProgress} variant="static" value={completed}  /> }
                {/* </Badge> */}
                <ListItemText 
                  primary={getPrimaryText(data[index].name, data[index].surname, data[index].profile_id, data[index].isStaff ) }
                  secondary={data[index].lastMsgTime && (<span><span>{moment(data[index].lastMsgTime).fromNow()}</span><span style={{color:'#aaa', fontSize: '18px'}}> | </span> <span>{getSecondaryText(data,index)}</span></span>) } 
                  classes={{ primary: classes.contactname, secondary: classes.contactnameSecondaryText }}/>
                  <Divider absolute />
                {/* </Badge> */}
                { data[index].status === 0 && !!data[index].transfered == false ?
                  (
                  <ListItemSecondaryAction>
                    <Fab size="medium" className={classes.buttonSuccess} onClick={(e) => {
                        dispatch(contactsActions.acceptContact(data[index], props.me.username),handleSelectedContact(e, data[index]));
                      }
                      }>
                      <AcceptIcon />
                    </Fab>
                    {loading && <CircularProgress size={50} className={classes.fabProgress} />}
                    <Fab size="medium" color="secondary" style={{marginLeft:3}} onClick={(e) => {
                        dispatch(contactsActions.dismissContact(data[index], props.me.username));
                        e.stopPropagation();
                        // setActiveContact(e, contact)
                      }
                      }>
                      <RejectIcon/>
                    </Fab> 
                  </ListItemSecondaryAction>
                  ) : (
                    <ListItemSecondaryAction style={{ marginRight: 10}}>
                        { data[index].transfered ?  <StyledBadge3 badgeContent={<CancelIcon></CancelIcon>} color="secondary"></StyledBadge3> : <StyledBadge2 badgeContent={data[index].unread} color="secondary"></StyledBadge2>  }
                        
                    </ListItemSecondaryAction>
                  )
                }

          </ListItem>
      )
  };

  const getContacts = () => {
    if(props.todas){
      return  <div style={{'flex' : '1'}}>
              { allChats.length > 0 ? (
                  <AutoSizer>
                    {({ height, width }) => (
                          <ListWindow
                              className="List"
                              height={height}
                              width={width}
                              itemCount={allChats.length}
                              itemSize={73}
                              itemData={allChats}
                              style={{ 'border': 'none', 'overflowX': 'hidden', scrollbarColor: '#007 #bada55'}}
                          >
                        {Row}
                    </ListWindow>
                    )}
                  </AutoSizer>
              ) : (
                <ContactSkeleton classes={classes} />
              )}
         </div>
    }else{
      return <div style={{'flex' : '1'}}>
                  { filtered.length > 0 ? (
                      <AutoSizer>
                        {({ height, width }) => (
                              <ListWindow
                                  className="List"
                                  height={height}
                                  width={width}
                                  itemCount={filtered.length}
                                  itemSize={73}
                                  itemData={filtered}
                                  style={{'border' : 'none', 'overflowX' : 'hidden'}}
                              >
                            {Row}
                        </ListWindow>
                        )}
                      </AutoSizer>
                  ) : (
                    <ContactSkeleton classes={classes} />
                  )}
            </div>
    }
    
  }

  return (
    <div style={{'display' : 'flex','flexDirection':'column','height':'100%'}}>
      {header()}
      {getContacts()}
  </div>
  );

})

export default ContactList;