import { Dialog, Grid, InputLabel, Input, DialogTitle, DialogContent, Button } from "@material-ui/core";
import React from "react";
import Api from "../../../state/Api";
import { addToast } from "../../../state/app/actions";
import { useSelector, useDispatch } from 'react-redux';


function AgregarGrupoModal(props) {
    const dispatch = useDispatch();
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');


    const handleClose = () => {
        props.onClose()
    }

    const getDisabled = () => {
        if (name && description) {
            return false
        } else {
            return true
        }
    }



    const guardar = async () => {
        let datos = {
            name: name,
            description: description
        }
        try {
            const guardar = await Api.req.post('marketing/37/groups', datos);
            dispatch(addToast('Se ha guardado el grupo'))
            props.onUpdate()
        } catch (error) {
            dispatch(addToast('Se han guardado los cambios', 'error'))
        }


    }

    return (
        <Dialog onClose={handleClose} open={props.isOpen}>
            <DialogTitle>Agregar Grupo</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <InputLabel htmlFor="descripcion">Nombres</InputLabel>
                        <Input
                            onChange={(event) => setName(event.target.value)}
                            value={name}

                            id="descripcion"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="descripcion">Descripcion</InputLabel>
                        <Input
                            onChange={(event) => setDescription(event.target.value)}
                            value={description}

                            id="descripcion"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={getDisabled()} style={{ float: 'right' }} variant="contained" color="primary" onClick={guardar}>Guardar</Button>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}

export default AgregarGrupoModal;