import React, { useEffect } from "react";
import { Chip, Avatar, Switch, IconButton  ,  Grid, List, ListItemText, ListItem, Button, ListItemIcon, ListItemAvatar, TextField, FormControl, InputLabel, MenuItem, Select, Box, CardActionArea, CardMedia, CardContent, Typography, CardActions, Card, Divider } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import Api from "../../state/Api";
import LaunchIcon from '@material-ui/icons/Launch';
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from '@material-ui/icons/Search';
import { DataGrid } from '@mui/x-data-grid';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AddIcon from '@material-ui/icons/Add';
import FolderIcon from '@material-ui/icons/Folder';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AgregarGrupoModal from './AgregarGrupoModal/AgregarGrupoModal';
import { Refresh } from "@material-ui/icons";

const useStyles = makeStyles({
    root: {
        width: '100%',
        '& .super-app-theme--header': {
            color: 'black'
        },
        '& .super-app-theme--row': {
            color: 'black'
        }
    },
    tableContainer: {
        height: '100%',
        maxHeight: '500px'
    },
    chip: {
        marginBottom: '10px'
    },
    paperStyle: {
        width: '50%',
        textAlign: 'center',
        marginBottom: '10px'
    },
    destacadoNumber: {
        color: '#42a5f5'
    }
});

function Grupos() {

    const [grupos, setGrupos] = React.useState([]);
    const [selectedGrupo, setSelectedGrupo] = React.useState()
    const [contactos, setContactos] = React.useState([])
    const [pageSize, setPageSize] = React.useState()
    const [rowCount, setRowCount] = React.useState();
    const [loading, setLoading] = React.useState();
    const [page, setPage] = React.useState(0)
    const [grupoNewModalOpen, setGrupoNewModalOpen] = React.useState(false)

    const getContactos  = (group,page = 0,rows = 50) => {
        var param = 37;
        setLoading(true)
        Api.req.get(`marketing/${param}/group/${group}/contacts?page=${page}&rows=${rows}`)
            .then((response) => {
                setLoading(false)
                setContactos(response.data.data)
                setRowCount(response.data.total)
            })
    }

    useEffect(() => {
        if (selectedGrupo){
            getContactos(selectedGrupo.id, page )
        }
        

    }, [page]);

    const divideTags = (tags) => {
        if (tags) {
            const myArray = tags.split(",");

            return myArray;
        } else {
            return [];
        }

    }

    const getChipColor = (val) => {
        if (val == null) {
            return 'default'
        }
        if (val == true) {
            return 'primary'
        }
        if (val == false) {
            return 'secondary'
        }
    }

    const getLabelName = (val) => {
        if (val == null) {
            return 'pending'
        }
        if (val == true) {
            return 'opted in'
        }
        if (val == false) {
            return 'opted out'
        }
    }

    const getData = async () => {
        var param = 37;
        Api.req.get(`marketing/${param}/groups`)
            .then((response) => {
                setGrupos(response.data.data)
                setSelectedGrupo(response.data.data[0])
            })
    }

    useEffect(() => {
        var param = 37;

        getData()

    }, [])

    const columns = [{
        headerName: 'Nombre',
        headerClassName: 'super-app-theme--header',
        field: 'name',
        headerAlign: 'center',
        flex: 1,
        renderCell: (params) => (<><Avatar style={{ marginRight: 10, }} >{params.value[0]}</Avatar> <Typography variant="body1">{params.value}</Typography></>)
    }, {
        field: 'callerid',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        width: 240,
    }, {
        field: 'source',
        headerClassName: 'super-app-theme--header',
        headerName: 'Origen',
        headerAlign: 'center',
        width: 140,
    }, {
        field: 'marketingOptIn',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (params) => (<Chip color={getChipColor(params.value)} label={getLabelName(params.value)} />),
        width: 140,
    }, {
        field: 'tags',
        headerClassName: 'super-app-theme--header',
        headerAlign: 'center',
        renderCell: (params) => {
            return divideTags(params.value).map((value, index) => (<Chip key={index} color="primary" style={{ marginRight: '5px' }} label={value} />))
        },
        width: 140,
    }, {
        field: 'createdAt',
        headerClassName: 'super-app-theme--header',
        headerName: 'Fecha',
        headerAlign: 'center',
        width: 140,
        valueFormatter: (params) => {
            return `${moment(params.value).format('DD/MM/YYYY')}`;
        },
    }];

    const onUpdate = () => {
        setGrupoNewModalOpen(false)
        getData()
    }

    const classes = useStyles();

    return (
        <>
            <AgregarGrupoModal isOpen={grupoNewModalOpen} onUpdate={onUpdate} onClose={() => setGrupoNewModalOpen(false)} />
            <Grid container style={{ flexDirection: 'row', justifyContent: 'space-between', color: '#888' }}>
                <Grid item xs={2} className={classes.leftContainer} style={{ overflow: 'auto', height: 'calc(100vh - 122px)', backgroundColor: '#fff' }}>
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItem >
                            <ListItemText
                                primary="Refrescar"
                            />
                            <ListItemSecondaryAction onClick={() => getData()} >
                                <IconButton style={{ color: 'red' }} edge="end" aria-label="delete">
                                    <Refresh />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>´
                        <ListItem >
                            <ListItemText
                                primary="Agregar Grupo"
                            />
                            <ListItemSecondaryAction onClick={() => setGrupoNewModalOpen(true)} >
                                <IconButton style={{ color : 'grey'}} edge="end" aria-label="delete">
                                    <AddIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        {grupos.map((grupo, index) => (
                            <div key = { index }><ListItem style={{ backgroundColor: '#fff' }}
                                selected={grupo === selectedGrupo}
                                key={index}
                                onClick={() => {
                                    setSelectedGrupo(grupo)
                                    getContactos(grupo.id)
                                }}
                                button >
                                <ListItemAvatar> 
                                    <Avatar>
                                        <RecentActorsIcon /> 
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={grupo.description} />
                                </ListItem>
                                <Divider component="li" />
                            </div>
                        ))}
                    </List>

                </Grid>
                <Grid item xs={10} style={{ padding: '10px', border: 3, backgroundColor: '#fff' }} >

                    <DataGrid className={classes.root} style={{ backgroundColor: '#fff' }}
                        rows={contactos}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[5, 10, 20]}
                        rowCount={rowCount}
                        loading={loading}
                        pagination
                        getRowClassName={(params) =>
                            `super-app-theme--row`
                        }
                        paginationMode="server"
                        onPageChange={(newPage) => setPage(newPage)}
                        {...contactos}
                    />
                    
                </Grid>
            </Grid>

        </>
    )
}

export default Grupos;