import { Dialog, Grid, InputLabel, Input, DialogTitle, DialogContent, Button } from "@material-ui/core";
import React, { useEffect } from "react";
import Api from "../../../state/Api";
import { addToast } from "../../../state/app/actions";
import { useSelector, useDispatch } from 'react-redux';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import NoSsr from '@material-ui/core/NoSsr';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styled from 'styled-components';
import CustomizedHook from "./CustomizedHook";

function AgregarContactoModal (props) {
    const dispatch = useDispatch();
    const [ nombres, setNombres ] = React.useState('');
    const [ grupos, setGrupos ] = React.useState([]);
    const [ selectedGrupos, setSelectedGrupos ] = React.useState([]);
    const [ apellido, setApellido ] = React.useState('');
    const [ callerId, setCallerId ] = React.useState('');


    const handleClose = () => {
        props.onClose()
    }

    const handleUpdateSelectedGrupo = (value) => {
        setSelectedGrupos(value)
    }

    const getDisabled = () => {
        if (nombres && apellido && callerId && callerId.startsWith('549')){
            return false
        }else{
            return true
        }
    }

    const getGrupos = async () => {
        var param = 37;
        Api.req.get(`marketing/${param}/groups`)
            .then((response) => {
                setGrupos(response.data.data)
            })

    }


    const guardarGrupos = async (guardado) => {

        let contactId = guardado.data.data[0].id;

        for(var i = 0 ; i < selectedGrupos.length ; i ++ ){
            Api.req.post(`marketing/5000/contact/${contactId}/group/${selectedGrupos[i].id}`);
        }
    }

    const guardar = async () => {
        let datos = {
            nombres : nombres,
            apellido : apellido, 
            callerid : callerId
        }
        try{
            const guardar = await Api.req.post('marketing/37/contacts', datos);
            guardarGrupos(guardar)
            dispatch(addToast('Se ha guardado el cliente'))
            props.onUpdate()
        }catch(error){
            dispatch(addToast('Se han guardado los cambios', 'error'))
        }
        
        
    }

    useEffect( () => {
        if (props.isOpen){
            getGrupos()
        }
        
    } , [props.isOpen] )

    return (
        <Dialog style={{height : '500px'}} onClose={handleClose} open={props.isOpen}>
          
            <DialogTitle>Generar Cliente</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <InputLabel htmlFor="descripcion">Nombres</InputLabel>
                        <Input
                            onChange={(event) => setNombres(event.target.value)}
                            value={nombres}

                            id="descripcion"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="descripcion">Apellido</InputLabel>
                        <Input
                            onChange={(event) => setApellido(event.target.value)}
                            value={apellido}

                            id="descripcion"
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="descripcion">Caller Id</InputLabel>
                        <Input
                            onChange={(event) => setCallerId(event.target.value)}
                            value={callerId}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            id="descripcion"
                            fullWidth={true}
                            placeholder="5491150357692"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomizedHook grupos={grupos} onUpdateSelectedGrupos={handleUpdateSelectedGrupo} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={getDisabled()} style={{ float : 'right' }} variant="contained" color="primary" onClick={guardar}>Guardar</Button>
                        <Button style={{ float : 'left' }} variant="contained" color="secondary" onClick={() => props.onClose()}>Cancelar</Button>
                    </Grid>
                </Grid>
            </DialogContent>
            
        </Dialog>
    )
}

export default  AgregarContactoModal;