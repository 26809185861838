import React, { useEffect, useState} from "react";
import { Box, Grid, List, ListItem, ListItemText, ListItemAvatar, Button, TableContainer, Typography ,  Table, TableHead, TableRow, TableCell, TableBody, Paper, Avatar, Chip, Switch, IconButton } from "@material-ui/core";
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { blue, lightGreen, green, indigo, pink, red } from '@material-ui/core/colors';

import Api from "../../state/Api";
import { styled } from 'styled-components';
import moment from "moment";
import AgregarCampañaModal from "./AgregarCampañaModal/AgregarCampañaModal";
import WhatsappIcon from "@material-ui/icons/WhatsApp";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector, useDispatch } from 'react-redux';
import * as adminActions from '../../state/admin/actions';
import EditIcon from '@material-ui/icons/Edit';
import VerCampanaModal from './VerCampanaModal/VerCampanaModal';
import AddGrupoModal from './AddGrupoModal/AddGrupoModal';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { Refresh } from "@material-ui/icons";

const useStyles = makeStyles({
    palette: {
        primary: green,
      },
    root: {
        width: '100%',
    },
    tableContainer: {
        height: '100%',
        maxHeight: '500px'
    },
    chip: {
        marginBottom: '10px'
    },
    paperStyle: {
        width: '50%',
        textAlign: 'center',
        marginBottom: '10px'
    },
    destacadoNumber: {
        color: '#42a5f5'
    },
    root: {
        width: '100%',
        '& .super-app-theme--header': {
            color: 'black'
        },
        '& .super-app-theme--row': {
            color: 'black'
        }
    },
});

// const theme = createTheme({
//     palette: {
//       primary: green,
//     },
//   });

function Campanas () {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [ campanas, setCampanas] = React.useState([]);
    const [ selectedCampana, setSelectedCampana] = React.useState([]);
    const [ contactos, setContactos] = React.useState([]);
    const [ campañaModalOpen, setCampañaModal] = React.useState(false);
    const [ pageSize, setPageSize] = React.useState(50);
    const [ verCampanaOpen, setVerCampanaOpen ] = React.useState(false);
    const [ verGruposModal, setVerGruposModal ] = React.useState(false);
    const [ campanaActual, setCampanaActual ] = React.useState();

    const handleActivaClick = (e,params) => {

        e.stopPropagation(); 

        let data = {
            campaignid: params.row.campaignid,
            name: params.row.name,
            status: !params.row.enabled
        }

        Api.req.put(`/marketing/${params.row.campaignid}/subcampaigns/${params.row.id}/status`,data)
            .then((response) => {
                 var indexCampana = campanas.findIndex((campana) => campana.id == params.row.id);
                campanas[indexCampana].enabled = !campanas[indexCampana].enabled;
                setCampanas(campanas)
                document.getElementById('fakeClick').click();
                console.log(campanas)
        }) 
    }

    const getContactos = (campaign) => {

        Api.req.get(`/marketing/${campaign.campaignid}/subcampaigns/${campaign.name}/contacts`)
            .then((response) => {
                setContactos(response.data.data)
            })
    }

    const refreshClientes = () => {
        var param = 37;
        Api.req.get(`marketing/${param}/subcampaigns`)
            .then((response) => {
                setCampanas(response.data.data)
                setCampañaModal(false)
            })
    }

    const handleClose =  () => {
        setCampañaModal(false)
    }

    const getSubcampaigns = () => {
        var param = 37;
        Api.req.get(`marketing/${param}/subcampaigns`)
            .then((response) => {
                setCampanas(response.data.data)
                setSelectedCampana(response.data.data[0])
                getContactos(response.data.data[0])
            })
    }

    useEffect(() => {
        getSubcampaigns()

    }, [])

    const verCampana = (campana) => {
        setVerCampanaOpen(true);
        setCampanaActual(campana)
    }

    const addGroupModal = (campana) => {
        setCampanaActual(campana)
        setVerGruposModal(true)
    }

    const handleCloseAddGroup = () => {
        setVerGruposModal(false)
    }

    const handleOnSaveGroup = () => {
        setVerGruposModal(false)
        getSubcampaigns()
    }

    const [rowHeight, setRowHeight] = useState(28);

    useEffect(() => {
   
            setRowHeight(rowHeight + 1);

    }, [campanas]);

    const columns = [{
        headerName: 'Nombre',
        headerClassName: 'super-app-theme--header',
        field: 'name',
        headerAlign: 'center',
        flex: 1,
        renderCell: (params) => (<><Avatar style={{ marginRight: 10, }} >{params.value[0]}</Avatar> <Typography variant="body1">{params.value}</Typography></>)
    },{
        headerName: 'template',
        headerClassName: 'super-app-theme--header',
        field: 'templateName',
        headerAlign: 'center',
        width: 240,
    },{
        field: 'startdate',
        headerClassName: 'super-app-theme--header',
        headerName: 'start',
        headerAlign: 'center',
        type: 'dateTime',
        width: 140,
        valueFormatter: (params) => {
            return `${moment(params.value).format('DD/MM/YYYY')}`;
        },
    },{
        field: 'enddate',
        headerClassName: 'super-app-theme--header',
        headerName: 'end',
        headerAlign: 'center',
        type: 'dateTime',
        width: 140,
        valueFormatter: (params) => {
            return `${moment(params.value).format('DD/MM/YYYY')}`;
        },        
    },{
        headerName: 'Contactos',
        headerClassName: 'super-app-theme--header',
        field: 'totalContactos',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        width: 150,
    },{
        headerName: 'Pend',
        headerClassName: 'super-app-theme--header',
        field: 'totalPendientes',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        width: 100,
    },{
        headerName: 'Sent',
        headerClassName: 'super-app-theme--header',
        field: 'totalEnviados',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        width: 100,        
    },{
        headerName: 'Err',
        headerClassName: 'super-app-theme--header',
        field: 'totalErrores',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        width: 100,                
    },{
        field: 'enabled',
        headerClassName: 'super-app-theme--header',
        headerName: 'Activa',
        headerAlign: 'center',
        align: 'center',
        width: 140,
        editable : false,

        renderCell: (params) => (<><Switch size="small" color="primary" onClick={(e) =>  handleActivaClick(e, params)} checked={params.row.enabled}/></>)
    },{
        field: 'actions',
        headerClassName: 'super-app-theme--header',
        headerName: 'Actions',
        headerAlign: 'center',
        align: 'center',
        width: 140,
        renderCell: (params) => (<div> <IconButton onClick={() => { addGroupModal(params.row); }} size="small" variant="contained" color="secondary" >  <GroupAddIcon /></IconButton>   <IconButton onClick={() => { verCampana(params.row) ; console.log('hizo click') } } size="small" variant="contained" color="secondary" ><SearchIcon fontSize="small"/></IconButton> <IconButton size="small" variant="contained" color="secondary" ><EditIcon fontSize="small"/></IconButton></div>)
    },];

    return (
        <>
            <AddGrupoModal campana={campanaActual} onSave={handleOnSaveGroup} onClose={handleCloseAddGroup} isOpen={verGruposModal} />
            <AgregarCampañaModal onUpdate={refreshClientes} onClose={handleClose} isOpen={campañaModalOpen} />
            <VerCampanaModal onClose={()=>setVerCampanaOpen(false)} isOpen={verCampanaOpen} campana={campanaActual} ></VerCampanaModal>
            <div style={{ marginBottom : '20px', textAlign: 'right'}}>
                <Box style={{ display : 'flex', flexDirection : 'row'}} ></Box>
                 <Box style={{ display : 'flex', flexDirection : 'row', justifyContent:'right'}} >
                    <Button color="secondary" startIcon={<Refresh />} onClick={() => getSubcampaigns()} variant="contained" size="small" style={{ textAlign: 'right', float: 'right', marginRight : '10px' }}>Refrescar</Button>
                    <Button color="primary" onClick={() => setCampañaModal(true) } variant="contained" size="small" style={{textAlign: 'right', float : 'left'}}>Nueva Campaña</Button>
                </Box>
            </div>
            <div id="fakeClick"></div>
            <DataGrid className={classes.root} style={{backgroundColor: '#fff'}}
                    rows={campanas} 
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20]}
                    disableColumnMenu={true}
                    disableSelectionOnClick={true}
                    getRowClassName={(params) =>
                        `super-app-theme--row`
                    }
                    pagination
                    {...campanas}
                />
            {/* <Grid container style={{ }}>
                <Grid item xs={2} style={{ padding: '3px', overflowY: 'auto', height: 'calc(100vh - 122px)', border: '1px #444 solid' }}>
                    <Button color="primary" onClick={() => setCampañaModal(true) } variant="contained" size="small" style={{textAlign: 'right'}}>Nueva Campaña</Button>
                    <List component="nav" aria-label="main mailbox folders">
                        {campanas.map((campana, index) => (
                            <ListItem
                                selected={campana === selectedCampana}
                                key={index}
                                onClick={() => {
                                    setSelectedCampana(campana);
                                    getContactos(campana)
                                }}
                                button >
                                <ListItemAvatar> <WhatsappIcon /> </ListItemAvatar>
                                <ListItemText primary={campana.name} />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
                <Grid item xs={10} style={{ paddingLeft: '10px', paddingTop: '3px' }}>
                    <Grid container spacing={1} style={{border: '1px #444 solid'}} >
                        <Grid item xs={8} style={{ textAlign: 'left', padding : '5px', lineHeight: '10px' }}>
                            <h2 style={{fontWeight:"normal"}}>{ selectedCampana.name }</h2>
                            <p style={{fontWeight:"normal"}}>{ selectedCampana.description }</p>
                            <p style={{fontWeight:"normal"}}><span style={{fontWeight:"bold"}}>Plantilla: </span>{ selectedCampana.templateid }</p>
                        </Grid>
                    
                        <Grid item xs={4} style={{ padding: '0px' }}>
                            <Grid container spacing={1} >
                            <Grid item xs={6}>
                                <Paper style={{ textAlign: 'center', padding : '5px' }} >
                                    <span style={{ fontSize: '40px' }}>{ selectedCampana.totalEnviados }</span> <br/>   
                                    <Typography>Consultas Finalizados</Typography>
                                    
                                </Paper>
                            </Grid>
                            <Grid item xs={6} >
                                <Paper style={{ textAlign: 'center', padding: '5px' }} >
                                    <span style={{ fontSize: '40px' }}>{ selectedCampana.totalContactos }</span>  <br />   
                                    <Typography>Contactos Totales</Typography>
                                </Paper>
                            </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop : '10px' }} >
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell align="center">Caller Id</TableCell>
                                        <TableCell align="center">Creado</TableCell>
                                        <TableCell align="center">Enviado</TableCell>
                                        <TableCell align="center">Error</TableCell>
                                        <TableCell align="center">Mensaje Error</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {contactos.map( (row, index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row" style={{ display: 'flex', flexDirection: 'row', alighContent: 'center', alignItems: 'center' }}>
                                                <Avatar style={{ marginRight: 10, width: 24, height: 24 }}>{row.name[0]}</Avatar> <Typography variant="p">{row.name}</Typography>
                                            </TableCell>
                                            <TableCell align="center">{row.callerid}</TableCell>
                                            <TableCell align="center">{moment(row.dateCreated).format('DD/MM/YYYY') }</TableCell>
                                            <TableCell align="center">
                                                <Chip color={row.Sent ? "primary" : "secondary" } label={row.Sent ? 'enviado' : 'pending' } />
                                            </TableCell>
                                            <TableCell align="center">{row.hasError}</TableCell>
                                            <TableCell align="center">{row.errorMessage}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    
                </Grid>
            </Grid> */}
        </>
    )
}

export default Campanas;