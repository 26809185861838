import { Select,  FormControl , Dialog, MenuItem, DialogActions , InputLabel, Input, DialogTitle, DialogContent, Button, Typography, Paper } from "@material-ui/core";
import React, { useEffect } from "react";
import Api from "../../../state/Api";
import { addToast } from "../../../state/app/actions";
import { useSelector, useDispatch } from 'react-redux';
import moment from "moment";
import {  makeStyles } from '@material-ui/core/styles';
import { LocalizationProvider } from "@material-ui/pickers";
import MomentAdapter from "@material-ui/pickers/adapter/moment";
import { DatePicker } from "@material-ui/pickers";
import * as appActions from '../../../state/app/actions';

const useStyles = makeStyles((theme) => ({
    counter: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
        }
    }
    }) 
)


function AddGrupoModal(props) {
    const classes = useStyles();
    const campana = props.campana ? props.campana : {};
    const [grupos, setGrupos] = React.useState([]);
    const [grupo, setGrupo] = React.useState(null);

    const dispatch = useDispatch();

    const handleModalClose = () => {
       
        props.onClose()
    }
    
    const handleGuardar = () => {

        let data = {
            "campaignid": campana.campaignid,
            "subcampaignid": campana.id ,
            "templateid": campana.templateid,
        }

        try{
            Api.req.post(`marketing/${campana.campaignid}/subcampaigns/${campana.name}/contacts/${grupo}`, data)
                .then((response) => {
                    dispatch(appActions.addToast('Se han guardado los cambios'))
                    props.onSave()
                })
        }catch(error){
            dispatch(appActions.addError('Ha habido un error al agregar el grupo'))
            console.log(error)
        }
        
    }

    const modifyComma = (value) => {
        var tt = String(value);
        tt = tt.replace(".", ",");
        return tt;
    }

    useEffect ( () => {
        if (props.isOpen){
            Api.req.get(`marketing/37/groups`)
                .then((response) => {
                    setGrupos(response.data.data)
            })
            setGrupo(null)
        }
       
    }, [props.isOpen] )

    return (
        <Dialog fullWidth={true}
            maxWidth={'xs'} onClose={handleModalClose} open={props.isOpen}>
            <DialogTitle>Agregar Grupo</DialogTitle>
            <DialogContent>
                <FormControl className={classes.cien} >
                    <InputLabel id="grupo">Grupo</InputLabel>
                    <Select
                        id="Grupo"
                        value={grupo}
                        onChange={(event) => setGrupo(event.target.value)}
                    >
                        {grupos.map((option, index) => (
                            <MenuItem key={index} value={option.id}>
                                {option.description}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleModalClose} color="primary">
                    Cancelar
                </Button>
                <Button disabled={grupo == null} onClick={handleGuardar} color="primary">
                    Agregar Grupo
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddGrupoModal;