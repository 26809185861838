export const FETCH_CAMPAIGNS_SUCCESS = 'admin/FETCH_CAMPAIGNS_SUCCESS';
export const FETCH_CAMPAIGNS_FAILURE = 'admin/FETCH_CAMPAIGNS_FAILURE';
export const FETCH_CAMPAIGNS_REQUEST = 'admin/FETCH_CAMPAIGNS_REQUEST';

export const FETCH_USUARIOS_SUCCESS = 'admin/FETCH_USUARIOS_SUCCESS';
export const FETCH_USUARIOS_FAILURE = 'admin/FETCH_USUARIOS_FAILURE';
export const FETCH_USUARIOS_REQUEST = 'admin/FETCH_USUARIOS_REQUEST';

export const UPDATE_CAMPAIGN_SUCCESS = 'admin/UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAILURE = 'admin/UPDATE_CAMPAIGN_FAILURE';
export const UPDATE_CAMPAIGN_REQUEST = 'admin/UPDATE_CAMPAIGN_REQUEST';

export const UPDATE_CAMPAIGN_CONFIG_SUCCESS = 'admin/UPDATE_CAMPAIGN_CONFIG_SUCCESS';
export const UPDATE_CAMPAIGN_CONFIG_FAILURE = 'admin/UPDATE_CAMPAIGN_CONFIG_FAILURE';
export const UPDATE_CAMPAIGN_CONFIG_REQUEST = 'admin/UPDATE_CAMPAIGN_CONFIG_REQUEST';

export const ADD_CAMPAIGNS_SUCCESS = 'admin/ADD_CHANNEL_SUCCESS';